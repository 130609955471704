import React from 'react';
import { Link } from 'react-router-dom';
import ButtonAdd from '../Components/ButtonAdd';
import HexagonSize from '../Components/HexagonSize';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';

const Events = () => {
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">ALL EVENTS</h3>
                  <div className="all-date-selected">
                    <div className="date-input tickets">
                      <input
                        type="date"
                        className="form-control mb-0"
                        placeholder="dd-mm-yyyy"
                        required
                      />
                    </div>
                    <div className="select-input tickets">
                      <select className="form-select mb-0">
                        <option selected>Select Status</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                    <Link to="/events-new" className="button-width">
                      <ButtonAdd
                        images="../assets/icon/plaus_icon.svg"
                        Heading="ADD"
                        active="orange-outline"
                        bgChange="white-bg"
                      />
                    </Link>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="table-responsive w-100">
                      <table className="table table-time-gun-create mobile-responsive">
                        <thead>
                          <tr>
                            {/* <th></th> */}
                            <th>EVENT NAME</th>
                            <th>START Time </th>
                            <th>END Time</th>
                            <th>LOCATION</th>
                            <th>TICKET QTY</th>
                            <th>ACTIVE</th>
                            <th>STATUS</th>
                            <th>ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody className="table-time-gun-create__old-even">
                          <tr>
                            {/* <td className="td-left">
                              <HexagonSize
                                imageUser="../assets/images/Headshot.jpg"
                                hexBg="m-lg-auto"
                              />
                            </td> */}
                            <td className="mob-tab-view td-left">
                              <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                            </td>
                            <td data-label="Event Name">
                              <span className="user-n-2 d-none d-lg-block">
                                <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                Sports for Aim
                              </span>
                              <span className="d-block d-md-none">
                                Sports for Aim
                              </span>
                            </td>
                            {/* <td data-label="Event Name">Sports for Aim</td> */}

                            <td data-label="Start">
                              <p>
                                <span>2023-09-28</span>
                                <br /> <span> 01:00</span>
                              </p>
                            </td>
                            <td data-label="End">
                              <p className="w-set-table-time">
                                <span>2023-09-30</span>
                                <br /> <span>13:00</span>
                              </p>
                            </td>
                            <td data-label="Location">
                              <p className="w-set-table">
                                VAULT ARMS INDOOR RANGE, Tyler, TX, United
                                States, 75702
                              </p>
                            </td>
                            <td data-label="Ticket QTY">2</td>
                            <td data-label="Active">
                              <div className="form-switch">
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                />
                              </div>
                            </td>
                            <td data-label="Status">
                              <input
                                className="form-check-input d-block m-md-auto"
                                type="checkbox"
                                value=""
                                id="active"
                              />
                              <label
                                className="form-check-label color-theme ps-1"
                                htmlFor="active"
                              >
                                Active
                              </label>
                            </td>
                            <td className="td-left">
                              <div className="edit-user-id">
                                <Link
                                  to="/events-new"
                                  className="or-col border-theme"
                                >
                                  <img src="../assets/icon/edit.svg" alt="" />
                                  {/* <span className="color-theme">Edit</span> */}
                                </Link>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  className="dan-col border-red"
                                >
                                  <img src="../assets/icon/delete.svg" alt="" />
                                  {/* <span className="color-red">Delete</span> */}
                                </Link>
                                <Link to="#" className="grn-col border-green">
                                  <img
                                    src="../assets/images/icons-signal.png"
                                    alt=""
                                  />
                                  {/* <span className="color-green">Stats</span> */}
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="mob-tab-view td-left">
                              <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                            </td>
                            <td data-label="Event Name">
                              <span className="user-n-2 d-none d-lg-block">
                                <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                Sports for Aim
                              </span>
                              <span className="d-block d-md-none">
                                Sports for Aim
                              </span>
                            </td>
                            <td data-label="Start">
                              <p className="w-set-table-time">
                                <span>2023-09-28</span> <br />{' '}
                                <span>01:00</span>
                              </p>
                            </td>
                            <td data-label="End">
                              <p className="w-set-table-time">
                                <span> 2023-09-30</span>
                                <br /> <span>13:00</span>
                              </p>
                            </td>
                            <td data-label="Location">
                              <p className="w-set-table">
                                VAULT ARMS INDOOR RANGE, Tyler, TX, United
                                States, 75702
                              </p>
                            </td>
                            <td data-label="Ticket QTY">2</td>
                            <td data-label="Active">
                              <input
                                className="form-check-input in-box"
                                type="checkbox"
                                value=""
                                id="unapproved"
                              />
                              <label
                                className="form-check-label color-red d-block m-md-auto"
                                htmlFor="unapproved"
                              >
                                Unapproved
                              </label>
                            </td>
                            <td data-label="Status">
                              <input
                                className="form-check-input in-box "
                                type="checkbox"
                                value=""
                                id="close"
                              />
                              <label
                                className="form-check-label color-red d-block m-md-auto"
                                htmlFor="close"
                              >
                                Unapproved
                              </label>
                            </td>
                            <td className="td-left">
                              <div className="edit-user-id">
                                <Link
                                  to="/events-new"
                                  className="or-col border-theme"
                                >
                                  <img src="../assets/icon/edit.svg" alt="" />
                                  {/* <span className="color-theme">Edit</span> */}
                                </Link>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  className="dan-col border-red"
                                >
                                  <img src="../assets/icon/delete.svg" alt="" />
                                  {/* <span className="color-red">Delete</span> */}
                                </Link>
                                <Link to="#" className="grn-col border-green">
                                  <img
                                    src="../assets/images/icons-signal.png"
                                    alt=""
                                  />
                                  {/* <span className="color-green">Stats</span> */}
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content delete-fill-detail">
            <div className="modal-body id-file">
              <h2>Are you sure?</h2>
              <p>
                Do you really want to delete these records? This process cannot
                be undone.
              </p>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <Link to="#">
                <ButtonRemoveImage
                  Heading="DELETE"
                  bgChange="white-bg w-pd-add"
                />
              </Link>
              <Link to="" data-bs-dismiss="modal">
                <ButtonRemoveImage
                  Heading="CANCEL"
                  active="gary-light-outline"
                  bgChange="gary-bg w-pd-add"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
