import { toast, Bounce } from 'react-toastify';

const defaultOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
  transition: Bounce,
};

const getOptions = (options) => ({
  ...defaultOptions,
  ...options,
  autoClose:
    options.autoClose !== undefined
      ? options.autoClose
      : defaultOptions.autoClose,
});

const Toast = {
  success: (message, options = {}) => {
    toast.success(message, getOptions(options));
  },
  info: (message, options = {}) => {
    toast.info(message, getOptions(options));
  },
  warning: (message, options = {}) => {
    toast.warn(message, getOptions(options));
  },
  error: (message, options = {}) => {
    toast.error(message, getOptions(options));
  },
  default: (message, options = {}) => {
    toast(message, getOptions(options));
  },
};

export default Toast;
