import React from 'react';
import RangeForm from './RangeForm';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { fetchRange } from '../Redux/features/ranges/rangeSlice';

const CreateRangesModal = ({ selectedRange, setSelectedRange, updateList }) => {
  const dispatch = useDispatch();

  const handleUpdateList = async () => {
    await dispatch(fetchRange());
  };

  return (
    <>
      <div
        className="modal fade"
        id="createRanges"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                {selectedRange?.id ? 'UPDATE ' : 'CREATE '}RANGE
              </h5>
            </div>
            <RangeForm
              type="create_range"
              selectedRange={selectedRange}
              updateList={updateList ? updateList : handleUpdateList}
              setSelectedRange={setSelectedRange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

CreateRangesModal.propTypes = {
  selectedRange: PropTypes.object,
  setSelectedRange: PropTypes.func,
  updateList: PropTypes.func,
};
export default CreateRangesModal;
