import React from 'react';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import HexagonSize from '../Components/HexagonSize';
import HexLine from '../Components/HexLine';

const RangeDesk = () => {
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <h3 className="all-top-heading">COUNTER PROFICIENCY EXAMINATION</h3>
            <div className="tabs-button-user">
              <div className="nav nav-pills">
                <div
                  className="nav-link active"
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                >
                  <h4>Billy Squire</h4>
                  <p>January 1, 2023</p>
                  <p>10:21 AM CST</p>
                </div>
                <div
                  className="nav-link"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                >
                  <h4>Bobby Falon</h4>
                  <p>January 1, 2023</p>
                  <p>10:24 AM CST</p>
                </div>
                <div
                  className="nav-link"
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                >
                  <h4>Jenny Kala</h4>
                  <p>January 1, 2023</p>
                  <p>11:34 AM CST</p>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card-body">
                <h3 className="d-none d-md-block">
                  COUNTER PROFICIENCY EXAMINATION
                </h3>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="tab-content ">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="examination">
                              <div className="user-table-list">
                                <HexagonSize
                                  imageUser="../assets/images/user-1.jpeg"
                                  hexBg="bg-black bg-org"
                                />

                                <h4 className="sub-heading heading-color-changes">
                                  Billy Squire
                                </h4>
                              </div>
                              <div className="table-responsive w-100">
                                <table className="table table-user-gan mobile-responsive mobile">
                                  <thead>
                                    <tr>
                                      <th>CPE TYPE</th>
                                      <th></th>
                                      <th className="text-start">
                                        DATE REQUESTED
                                      </th>
                                      <th>GAP ASSESSMENT</th>
                                      <th>CERTIFICATION</th>
                                      <th>RANGE ADMIN</th>
                                    </tr>
                                  </thead>
                                  <tbody className="mobile__old-even">
                                    <tr>
                                      <td data-label="Cpe Type">
                                        <img
                                          src="../assets/icon/handgun.svg"
                                          alt=""
                                          className="img-set-gun-right"
                                        />
                                      </td>
                                      <td data-label="">
                                        <p className="color-black">HANDGUN</p>
                                      </td>
                                      <td data-label="Date Requested">
                                        <p className="text-left">
                                          January 1, 2023 <br />
                                          10:21 AM CST
                                        </p>
                                      </td>
                                      <td data-label="Gap Assessment">
                                        <p className="head-dote">
                                          HANDLING
                                          <span>4/5</span>
                                        </p>
                                        <p className="head-dote dote-color-change">
                                          SAFETY
                                          <span>4/5</span>
                                        </p>
                                      </td>
                                      <td
                                        data-label="Certification"
                                        className="table-button-width"
                                      >
                                        <Link
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                        >
                                          <ButtonRemoveImage
                                            Heading="PENDING"
                                            bgChange="bg-orange"
                                          />
                                        </Link>
                                      </td>
                                      <td data-label="Range Admin">1</td>
                                    </tr>
                                    <tr>
                                      <td data-label="Cpe Type">
                                        <img
                                          src="../assets/icon/rifle.svg"
                                          alt=""
                                          className="img-set-gun-right"
                                        />
                                      </td>
                                      <td data-label="">
                                        <p className="color-black">RIFLE</p>
                                      </td>
                                      <td data-label="Date Requested">
                                        <p className="text-left">
                                          January 1, 2023 <br /> 10:21 AM CST
                                        </p>
                                      </td>
                                      <td data-label="Gap Assessment">
                                        <p className="head-dote dote-color-change">
                                          HANDLING
                                          <span>2/7</span>
                                        </p>
                                        <p className="head-dote dote-color-change">
                                          SAFETY
                                          <span>3/4</span>
                                        </p>
                                      </td>
                                      <td
                                        data-label="Certification"
                                        className="table-button-width"
                                      >
                                        <Link
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                        >
                                          <ButtonRemoveImage
                                            Heading="CERTIFIED"
                                            bgChange="success-bg-color"
                                            active="success-outline"
                                          />
                                        </Link>
                                      </td>
                                      <td data-label="Range Admin">
                                        <p className="color-black text-right center-left">
                                          Jeff J.
                                        </p>
                                        <p className="text-right center-left">
                                          1 / 1 / 2023
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td data-label="Cpe Type">
                                        <img
                                          src="../assets/icon/shotgun.svg"
                                          alt=""
                                          className="img-set-gun-right"
                                        />
                                      </td>
                                      <td data-label="">
                                        <p className="color-black">SHOTGUN</p>
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="v-pills-profile">
                        2
                      </div>
                      <div className="tab-pane fade" id="v-pills-messages">
                        3
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content modal-table">
            <div className="modal-header table-header">
              <div aria-label="Close" data-bs-dismiss="modal">
                <HexLine
                  hexBg="bg-bo-li-th"
                  hexOuterLine="bg-dark-bc"
                  imageUserTwo="../assets/icon/cross_icon.svg"
                />
              </div>
              <h5>COUNTER PROFICIENCY EXAMINATION</h5>
            </div>
            <div className="modal-body identity-card">
              <div className="identity-card__body identity-card__border">
                <HexagonSize
                  hexOuterLine="bg-trans"
                  imageUser="../assets/images/user-1.jpeg"
                  hexBg="bg-black bg-org m-auto"
                />
                <h4>Billy Squire</h4>
              </div>
              <div className="identity-card__body">
                <HexLine
                  hexBg="bg-bo-li-th-w m-auto"
                  hexOuterLine="bg-dark-bc pd"
                  imageUserTwo="../assets/icon/handgun.svg"
                  imgSizeTwo="today-size"
                />

                <h4>HANDGUN id</h4>
              </div>
            </div>
            <div className="modal-footer identity-button pe-md-3 ">
              <ButtonRemoveImage
                Heading="INCOMPLETE"
                activeColor="white-color"
                bgChange="bg-dark-gray"
                active="orange-outline"
              />
              <ButtonRemoveImage
                Heading="APPROVED"
                active="success-outline"
                bgChange="bg-dark-gray-success"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RangeDesk;
