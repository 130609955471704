import React from 'react';
import PropTypes from 'prop-types';

const ButtonRemoveImage = (props) => {
  return (
    <>
      <div className={`custom-action-button  ${props.active}`}>
        <button
          type={props?.type ? props?.type : 'button'}
          className={` ${props.bgChange}`}
        >
          {props.Heading}
        </button>
      </div>
    </>
  );
};

ButtonRemoveImage.propTypes = {
  props: PropTypes.object,
  active: PropTypes.string,
  type: PropTypes.string,
  bgChange: PropTypes.string,
  Heading: PropTypes.string,
};

export default ButtonRemoveImage;
