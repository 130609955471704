import { useState } from 'react';
import ButtonLoader from '../ButtonLoader';
import ImageUploadInput from '../ImageUploadInput';
import { uploadFile } from '../../Utils/upload';
import Toast from '../../Utils/Toast';
import PropTypes from 'prop-types';
import { RANGE_CLASS } from '../../Utils/ModelClasses';
import { RANGE_LOGO_TYPE } from '../../Utils/UploadType';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../ButtonRemoveImage';
import { setBannerRangeData } from '../../Redux/features/ranges/rangeSlice';
import axiosInstance from '../../Utils/axiosInstance';
import { REMOVE_ENDPOINT } from '../../Utils/Endpoints';

const Banner = ({ handleNextStep, setImageID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [generalError, setGeneralError] = useState('');
  const rangeData = useSelector((state) => state.range.createRange);
  const bannerData = useSelector((state) => state.range.bannerRange);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setGeneralError('');
      if (rangeData?.id) {
        if (file) {
          try {
            const imageData = await uploadFile(
              RANGE_CLASS,
              rangeData?.id,
              RANGE_LOGO_TYPE,
              file
            );
            setImageID(imageData.data.data.id);
            dispatch(setBannerRangeData(imageData.data.data));
            Toast.success('Range banner uploaded.', {
              autoClose: 2000,
            });
            handleNextStep();
          } catch (error) {
            setGeneralError(error.response?.data?.message);
            return;
          }
        }
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteImage = async (id) => {
    try {
      const response = await axiosInstance.delete(REMOVE_ENDPOINT + id);

      if (response.status === 204) {
        dispatch(setBannerRangeData({}));
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    }
  };

  return (
    <>
      <div className="employees employ-set">
        <h3 className="card-top-2">Banner</h3>
      </div>
      <form className="triangle-top" onSubmit={handleSubmit}>
        <div className="body-text text-start">
          <div className="row">
            <div className="col-12">
              <ImageUploadInput
                setFile={setFile}
                handleDeleteImage={handleDeleteImage}
                imageData={bannerData}
              />
            </div>
            {generalError && (
              <p className="error text-center mt-3">{generalError}</p>
            )}
            <div className="d-flex mt-3 gap-3">
              {/* <Link to="#" className="button-width"> */}
              <ButtonLoader
                type="submit"
                bgChange={`white-bg delete-w `}
                active="orange-outline"
                isLoading={isLoading}
                disabled={bannerData?.id}
              >
                Save
              </ButtonLoader>

              <Link to="#" className="button-width" onClick={handleNextStep}>
                <ButtonRemoveImage
                  Heading="Skip"
                  active="orange-outline"
                  bgChange="white-bg w-pd-add"
                />
              </Link>

              {/* </Link> */}
              {/* <Link to="#" className="button-width">
              <ButtonRemoveImage
                Heading="Cancel"
                active="gary-light-outline"
                bgChange="gary-bg w-pd-add"
              />
            </Link> */}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

Banner.propTypes = {
  handleNextStep: PropTypes.func,
  setImageID: PropTypes.func,
};
export default Banner;
