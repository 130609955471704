import React from 'react';

const CardGlance = () => {
  return (
    <>
      <div className="card-body ">
        <h3>AT A GLANCE</h3>
        <div className="triangle-top">
          <div className="body-text body-font pt-4 body-p-b">
            <div className="row">
              <div className="col-6 col-md-6">
                <p>Classes This Month</p>
                <h4 className="mb-4">49</h4>
              </div>
              <div className="col-6 col-md-6">
                <p>Private Training This Month</p>
                <h4 className="mb-4">5</h4>
              </div>
              <div className="col-6 col-md-6">
                <p>Classes Year to Date</p>
                <h4>573</h4>
              </div>
              <div className="col-6 col-md-6">
                <p>Private Training Year to Date</p>
                <h4>38</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardGlance;
