import React from 'react';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';
import Label from './Label';

const CustomItem = () => {
  return (
    <>
      <div
        className="modal fade"
        id="customItemModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Add Custom item
              </h5>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <Label labelHeading="Item Name" />
                    <div className="tickets">
                      <input type="text" className="form-control input-theme" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <Label labelHeading="Price" />
                    <div className="tickets">
                      <input type="text" className="form-control input-theme" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <Label labelHeading="Quantity" />
                    <div className="tickets">
                      <input
                        type="number"
                        className="form-control input-theme"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create">
                  <ButtonRemoveImage
                    Heading="save"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add save-w"
                  />
                  <Link
                    to="#"
                    className="close-solcial-link-modal"
                    data-bs-dismiss="modal"
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomItem;
