import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonRemoveImage from './ButtonRemoveImage';
import { logoutUser } from '../Utils/LocalStorage';
import ButtonLoader from './ButtonLoader';
import Logger from '../Utils/Logger';
import { useDispatch } from 'react-redux';
import { setData } from '../Redux/features/ranges/rangeSlice';

const LogoutModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    try {
      setIsLoading(true);
      logoutUser();
      dispatch(setData([]));
      const modalClose = document.getElementsByClassName('close-logout');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
    } catch (error) {
      Logger.error('Sign Out Error : ', error);
    } finally {
      setIsLoading(false);
      navigate('/');
    }
  };

  return (
    <>
      <div
        className="modal fade"
        data-bs-backdrop="static"
        id="logOutModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content delete-fill-detail">
            <div className="modal-body id-file">
              <h2>Are you sure?</h2>
              <p>Do you really want to logout?</p>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <ButtonLoader
                onClick={handleLogout}
                active="orange-outline"
                bgChange="white-bg w-pd-add"
                isLoading={isLoading}
              >
                SING OUT
              </ButtonLoader>

              <Link to="" data-bs-dismiss="modal" className="close-logout">
                <ButtonRemoveImage
                  Heading="CANCEL"
                  active="gary-light-outline"
                  bgChange="gary-bg w-pd-add"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogoutModal;
