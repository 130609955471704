import React, { useEffect, useState } from 'react';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axiosInstance from '../Utils/axiosInstance';
import {
  RANGE_SOCIAL_LINK_ENDPOINT,
  RANGE_SOCIAL_LINK_UPDATE_ENDPOINT,
} from '../Utils/Endpoints';
import ButtonLoader from './ButtonLoader';
import Toast from '../Utils/Toast';
import { getItem } from '../Utils/LocalStorage';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Validation schema
const SocialLinkSchema = Yup.object().shape({
  linkType: Yup.string().required('Required'),
  url: Yup.string().required('Required'),
});

const SocialLinksModal = ({
  selectedSocialLink,
  handleUpdateList,
  setSelectedSocialLink,
}) => {
  const [linkType, setLinkType] = useState('');
  const [url, setUrl] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const rangeData = useSelector((state) => state.range.createRange);

  const rangeId =
    rangeData && Object.keys(rangeData)?.length > 0
      ? rangeData?.id
      : getItem('rangeId');
  const [socialLinkList, setSocialLinkList] = useState([
    {
      id: 'socialLink_1',
      linkType: '',
      url: '',
      disabled: false,
    },
  ]);

  useEffect(() => {
    if (selectedSocialLink?.id) {
      setLinkType(selectedSocialLink.type);
      setUrl(selectedSocialLink.url);
    }
  }, [selectedSocialLink]);

  const validate = () => {
    try {
      if (selectedSocialLink?.id) {
        SocialLinkSchema.validateSync(
          {
            linkType,
            url,
          },
          { abortEarly: false }
        );
      } else {
        const data = socialLinkList[socialLinkList.length - 1];
        const validateData = {
          linkType: data?.linkType,
          url: data?.url,
        };
        SocialLinkSchema.validateSync(validateData, { abortEarly: false });
      }
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');

      const response = await axiosInstance.post(
        RANGE_SOCIAL_LINK_ENDPOINT + rangeId,
        {
          type: socialLinkList[socialLinkList.length - 1].linkType,
          url: socialLinkList[socialLinkList.length - 1].url,
        }
      );
      if (response.data.code === 200) {
        setSocialLinkList((prevList) => {
          const lastIndex = prevList.length - 1;
          prevList[lastIndex].id = response?.data?.data?.id;
          return [...prevList];
        });
        addNewRow();
        // const modalClose = document.getElementsByClassName(
        //   'close-solcial-link-modal'
        // );
        // for (let i = 0; i < modalClose.length; i++) {
        //   modalClose[i].click();
        // }
        if (rangeData?.id) {
          handleUpdateList();
        } else {
          handleUpdateList(response?.data?.data, 'ADD');
        }
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.patch(
        RANGE_SOCIAL_LINK_UPDATE_ENDPOINT + selectedSocialLink?.id,
        {
          url: url,
        }
      );
      if (response.data.code === 200) {
        const modalClose = document.getElementsByClassName(
          'close-solcial-link-modal'
        );
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }

        if (rangeData?.id) {
          handleUpdateList();
        } else {
          handleUpdateList(response?.data?.data, 'UPDATE');
        }

        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(
        error.response?.data?.message || 'Updation of social link failed.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setLinkType('');
    setUrl('');
    setSelectedSocialLink({});
    setGeneralError('');
    setErrors({});
    setSocialLinkList([
      {
        id: 'socialLink_1',
        linkType: '',
        url: '',
        disabled: false,
      },
    ]);
  };

  const addNewRow = () => {
    if (socialLinkList.length < 6) {
      setSocialLinkList((prevList) => [
        ...prevList,
        {
          id: 'socialLink_' + (prevList.length + 1),
          linkType: '',
          url: '',
          disabled: false,
        },
      ]);
    }
  };

  const handleInputChange = (id, field, value) => {
    setSocialLinkList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };
  return (
    <>
      <div
        className="modal fade"
        id="socialLink"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Social Link
              </h5>
            </div>

            <form
              onSubmit={selectedSocialLink?.id ? handleUpdate : handleSubmit}
            >
              <div className="modal-body">
                <div className="row">
                  <div className="table-responsive w-100">
                    <table className="table table-time-gun-create mobile-responsive time-select">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>URL</th>
                        </tr>
                      </thead>
                      <tbody className="table-time-gun-create__old-even time-o-c">
                        {selectedSocialLink?.id && (
                          <tr>
                            <td data-label="links">
                              <div className="select-input tickets pb-0">
                                <select
                                  name="links"
                                  id="links"
                                  className="form-select "
                                  onChange={(e) => setLinkType(e.target.value)}
                                  value={linkType}
                                >
                                  <option value="">Select an option*</option>
                                  <option value="WEB">Web</option>
                                  <option value="FACEBOOK">Facebook</option>
                                  <option value="X">X</option>
                                  <option value="YOUTUBE">YouTube</option>
                                  <option value="TIK-TOK">Tik-Tok</option>
                                  <option value="INSTAGRAM">Instagram</option>
                                </select>
                                {errors.linkType && (
                                  <div className="error">{errors.linkType}</div>
                                )}
                              </div>
                            </td>
                            <td data-label="url">
                              <div className="tickets pb-0">
                                <input
                                  type="text"
                                  className="form-control input-theme"
                                  onChange={(e) => setUrl(e.target.value)}
                                  value={url}
                                />
                                {errors.url && (
                                  <div className="error">{errors.url}</div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                        {!selectedSocialLink?.id && (
                          <>
                            {socialLinkList.map((item, index) => (
                              <>
                                {typeof item.id === 'number' ? (
                                  <tr key={index}>
                                    <td data-label="linkType">
                                      <p>{item.linkType}</p>
                                    </td>
                                    <td data-label="url">
                                      <p>{item.url}</p>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr key={index}>
                                    <td data-label="links">
                                      <div className="select-input tickets pb-0">
                                        <select
                                          name="links"
                                          id="links"
                                          className="form-select mb-0"
                                          value={item.linkType}
                                          onChange={(e) =>
                                            handleInputChange(
                                              item.id,
                                              'linkType',
                                              e.target.value
                                            )
                                          }
                                          required
                                          disabled={typeof item.id === 'number'}
                                        >
                                          <option value="">
                                            Select an Option*
                                          </option>
                                          <option value="WEB">Web</option>
                                          <option value="FACEBOOK">
                                            Facebook
                                          </option>
                                          <option value="TIK-TOK">
                                            Tik-Tok
                                          </option>
                                          <option value="X">X</option>
                                          <option value="YOUTUBE">
                                            YouTube
                                          </option>
                                          <option value="INSTAGRAM">
                                            Instagram
                                          </option>
                                        </select>
                                        {errors.linkType && (
                                          <div className="error">
                                            {errors.linkType}
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                    <td data-label="url">
                                      <div className="tickets pb-0">
                                        <input
                                          type="text"
                                          className="form-control input-theme mb-0"
                                          value={item.url}
                                          onChange={(e) =>
                                            handleInputChange(
                                              item.id,
                                              'url',
                                              e.target.value
                                            )
                                          }
                                          required
                                          disabled={typeof item.id === 'number'}
                                        />
                                        {errors.url && (
                                          <div className="error">
                                            {errors.url}
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {generalError && (
                <p className="error text-center">{generalError}</p>
              )}
              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create mt-5">
                  {/* <Link to="#"> */}
                  <ButtonLoader
                    Heading="SAVE"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add save-w"
                    type="submit"
                    isLoading={isLoading}
                  >
                    {selectedSocialLink?.id ? 'Update' : 'Save'}
                  </ButtonLoader>
                  {/* </Link> */}
                  <Link
                    to="#"
                    className="close-solcial-link-modal"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

SocialLinksModal.propTypes = {
  selectedSocialLink: PropTypes.object,
  handleUpdateList: PropTypes.func,
  setSelectedSocialLink: PropTypes.func,
};
export default SocialLinksModal;
