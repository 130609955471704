import React from 'react';
import { Link } from 'react-router-dom';

const SelectRentalFirearm = () => {
  return (
    <>
      {/* <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#SelectRentalFirearm"
      >
        Launch demo modal
      </button> */}

      <div
        className="modal fade"
        id="SelectRentalFirearm"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Select Rental Firearm
              </h5>
              <Link
                to="#"
                className="close-firearm-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="../assets/icon/cross-svgrepo-com.svg" alt="" />
              </Link>
            </div>
            <div className="modal-body d-none">...</div>
            <div className="modal-footer border-0 justify-content-center">
              <div className="suman suman-lint">
                <div className="select_time_wrapper">
                  <label className="rounded-0 text-white">
                    <Link
                      to="/ranges/firearm-rental-options"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <input type="radio" name="toggle" className="d-none" />
                      <span className="text-center d-block py-3">HANDGUN</span>
                    </Link>
                  </label>
                  <label className="rounded-0 text-white">
                    <input type="radio" name="toggle" className="d-none" />
                    <span className="text-center d-block py-3">SHOTGUN</span>
                  </label>
                  <label className="rounded-0 text-white">
                    <input type="radio" name="toggle" className="d-none" />
                    <span className="text-center d-block py-3">RIFLE</span>
                  </label>
                  <label
                    className="rounded-0 text-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <input type="radio" name="toggle" className="d-none" />
                    <span className="text-center d-block py-3">SKIP</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectRentalFirearm;
