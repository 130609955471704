import React, { useEffect, useState } from 'react';
import { loadStripeTerminal } from '@stripe/terminal-js';
import Logger from '../../Utils/Logger';

const TerminalComponent = () => {
  const [terminal, setTerminal] = useState(null);
  const [connectedReader, setConnectedReader] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const initializeTerminal = async () => {
      const stripeTerminal = await loadStripeTerminal();
      setTerminal(stripeTerminal);

      // Fetch connection token from your backend
      const response = await fetch('/connection_token', { method: 'POST' });
      const { secret } = await response.json();

      // Initialize terminal
      const terminal = stripeTerminal.create({
        onFetchConnectionToken: async () => secret,
      });

      setTerminal(terminal);
    };

    initializeTerminal();
  }, []);

  const discoverReaders = async () => {
    const discoveryResult = await terminal.discoverReaders();

    if (discoveryResult.error) {
      console.error('Error discovering readers:', discoveryResult.error);
    } else if (discoveryResult.discoveredReaders.length === 0) {
      Logger.log('No available readers');
    } else {
      // Connect to the first discovered reader (for demo purposes)
      const reader = discoveryResult.discoveredReaders[0];
      const connectionResult = await terminal.connectReader(reader);

      if (connectionResult.error) {
        Logger.error('Failed to connect to reader:', connectionResult.error);
      } else {
        setConnectedReader(reader);
        Logger.log('Connected to reader:', reader.label);
      }
    }
  };

  const handlePayment = async () => {
    if (!connectedReader) {
      Logger.log('No reader connected');
      return;
    }

    setIsProcessing(true);

    // Create payment intent from your backend
    const paymentIntentResponse = await fetch('/create_payment_intent', {
      method: 'POST',
    });
    const { client_secret } = await paymentIntentResponse.json();

    // Collect payment
    const paymentResult = await terminal.collectPaymentMethod(client_secret);

    if (paymentResult.error) {
      Logger.error('Payment method collection failed:', paymentResult.error);
    } else {
      const confirmResult = await terminal.processPayment(
        paymentResult.paymentIntent
      );

      if (confirmResult.error) {
        Logger.error('Payment failed:', confirmResult.error);
      } else {
        Logger.log('Payment successful:', confirmResult.paymentIntent);
      }
    }

    setIsProcessing(false);
  };

  return (
    <div>
      <button
        onClick={discoverReaders}
        type="button"
        disabled={connectedReader}
      >
        {connectedReader
          ? `Connected to ${connectedReader.label}`
          : 'Discover Reader'}
      </button>

      <button
        onClick={handlePayment}
        type="button"
        disabled={!connectedReader || isProcessing}
      >
        {isProcessing ? 'Processing...' : 'Collect Payment'}
      </button>
    </div>
  );
};

export default TerminalComponent;
