import { useState } from 'react';
import ClassCheckInForm from '../Components/Classes/ClassCheckInForm';
import AddClassProduct from '../Components/Classes/AddClassProduct';
import ReviewClass from '../Components/Classes/ReviewClass';
import ClassInstructor from '../Components/Classes/ClassInstructor';

const CreateClassForm = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
      if (activeStep + 1 > 3) {
        const tabContainer = document.getElementById('pills-tab');
        const activeTab = tabContainer.querySelector(
          `li:nth-child(${activeStep + 2})`
        );
        activeTab.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
        setTimeout(() => {
          tabContainer.scrollTop = activeTab.offsetTop;
          tabContainer.scrollBy(activeTab.offsetTop + 100, 0);
        }, 100);
      }
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    const tabContainer = document.getElementById('pills-tab');
    const activeTab = tabContainer.querySelector(`li:nth-child(${step + 1})`);
    activeTab.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
    setTimeout(() => {
      tabContainer.scrollTop = activeTab.offsetTop;
      tabContainer.scrollBy(0, activeTab.offsetTop);
    }, 100);
  };

  const steps = [
    {
      id: 'general',
      label: 'General',
      Component: <ClassCheckInForm handleNextStep={handleNextStep} />,
    },
    {
      id: 'product',
      label: 'Product',
      Component: <AddClassProduct handleNextStep={handleNextStep} />,
    },
    {
      id: 'instructors',
      label: 'Instructors',
      Component: <ClassInstructor handleNextStep={handleNextStep} />,
    },
    {
      id: 'review',
      label: 'Review',
      Component: <ReviewClass handleNextStep={handleNextStep} />,
    },
  ];

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div>
                  <ul
                    className="nav nav-event-pro mt-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    {steps.map((step, index) => (
                      <li
                        className={
                          'nav-item first-user-2 ' +
                          (activeStep - 1 >= index ? 'nav-active-dash' : '')
                        }
                        role="presentation"
                        key={index}
                      >
                        <div
                          className={activeStep >= index ? 'active ' : ''}
                          id={`pills-${step.id}-tab`}
                          onClick={
                            activeStep > index
                              ? () => handleStepChange(index)
                              : null
                          }
                        >
                          <div className="pro-hex">
                            <div
                              className={`bg-gray bg-white-progress ${activeStep >= index ? 'bg-active' : ''}`}
                            >
                              {index + 1}
                            </div>
                          </div>
                          <p
                            className={
                              activeStep >= index ? 'pro-active-color' : ''
                            }
                          >
                            {step.label}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div className="tab-content tf-y" id="pills-tabContent">
                    {steps.map((step, index) => (
                      <div
                        className={`tab-pane fade ${activeStep === index ? 'show active' : ''}`}
                        id={`pills-${step.id}`}
                        key={index}
                      >
                        {activeStep === index && step.Component}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateClassForm;
