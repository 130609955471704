// src/Pages/NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div className="sign-in">
      <div className="text-center">
        <img src="../assets/images/webside.webp" alt="" className="sign-logo" />
      </div>
      <div>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default NotFound;
