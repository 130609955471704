import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import RangeReducer from '../features/ranges/rangeSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedRangeReducer = persistReducer(persistConfig, RangeReducer);

export const store = configureStore({
  reducer: {
    range: persistedRangeReducer,
  },
});

export const persistor = persistStore(store);
