import React from 'react';
import PropTypes from 'prop-types';

const TrainersCard = (props) => {
  return (
    <>
      <div className="card user-card mt-2 triangle-card line-card">
        <div className={` box-bg m-auto ${props.hexBg}`}>
          <div className={`bg-hox ${props.hexOuterLine}`}>
            <img
              src={props.UserImages}
              alt=""
              className={` img-size ${props.imgSize}`}
            />
          </div>
        </div>

        <div className="card-body-2">
          <div
            className="text-danger fw-bolder cursor-hover d-img-c"
            data-bs-toggle="modal"
            data-bs-target="#deleteModal"
            onClick={() =>
              props.handleDelete(props.trainerData.user.id, 'trainer')
            }
          >
            <img src="../assets/icon/delete.svg" alt="" className="ddd-card" />
          </div>
          <h4>{props.Name}</h4>
          <h5>{props.EmailUser}</h5>
          <p>{props.UserText}</p>
          <h6 className="text-uppercase">{props.UserExperience}</h6>
          <div className="gun-bg">
            <div className="practice-gun">
              <img src="../assets/icon/handgun_white.svg" alt="" />
            </div>
            <div className="practice-gun">
              <img src="../assets/icon/rifle_white.svg" alt="" />
            </div>
            <div className="practice-gun">
              <img src="../assets/icon/shotgun_white.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TrainersCard.propTypes = {
  props: PropTypes.object,
  hexBg: PropTypes.string,
  hexOuterLine: PropTypes.string,
  UserImages: PropTypes.string,
  imgSize: PropTypes.string,
  Name: PropTypes.string,
  EmailUser: PropTypes.string,
  UserText: PropTypes.string,
  UserExperience: PropTypes.string,
  handleDelete: PropTypes.func,
  trainerData: PropTypes.object,
};

export default TrainersCard;
