import React, { useEffect, useState } from 'react';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import { Link, useNavigate } from 'react-router-dom';
import Label from '../Components/Label';
import ImageUploadInput from '../Components/ImageUploadInput';
import axiosInstance from '../Utils/axiosInstance';
import * as Yup from 'yup';
import { REMOVE_ENDPOINT, UPDATE_PROFILE_ENDPOINT } from '../Utils/Endpoints';
import { emailRegExp, phoneRegExp } from '../Utils/common';
import { uploadFile } from '../Utils/upload';
import Toast from '../Utils/Toast';
import { USER_CLASS } from '../Utils/ModelClasses';
import { USER_PROFILE_PICTURE_TYPE } from '../Utils/UploadType';
import ButtonLoader from '../Components/ButtonLoader';
import { getLoginUser, logoutUser, setLoginUser } from '../Utils/LocalStorage';
import { useDispatch } from 'react-redux';
import { setData } from '../Redux/features/ranges/rangeSlice';
import PhoneInputView from '../Components/PhoneInput';

// Validation schema
const ProfileSchema = Yup.object().shape({
  userName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  password: Yup.string().test('isChangePassword', 'Required', function (value) {
    if (this.parent.isChangePassword) {
      if (value === '') {
        return this.createError({ message: 'Required' });
      }
      return true;
    }
    return true;
  }),
  confirmPassword: Yup.string().test(
    'isChangePassword',
    'Required',
    function (value) {
      if (this.parent.isChangePassword) {
        if (value === '') {
          return this.createError({ message: 'Required' });
        }
        if (value !== this.parent.password) {
          return this.createError({ message: 'Passwords must match' });
        }
        return true;
      }
      return true;
    }
  ),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  dob: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
  bio: Yup.string().required('Required'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
});

const UserProfile = () => {
  const [userName, setUserName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [bio, setBio] = useState('');
  const [currentUser, setCurrentUser] = useState(getLoginUser());
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [imageData, setImageData] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentUser(getLoginUser());
  }, []);

  useEffect(() => {
    if (currentUser?.id) {
      setUserName(currentUser?.username);
      setLastName(currentUser?.last_name);
      setFirstName(currentUser?.first_name);
      setEmail(currentUser?.email);
      setDob(currentUser?.dob);
      setGender(currentUser?.gender);
      setBio(currentUser?.bio);
      setPhoneNumber(currentUser?.phone_number);
      setImageData(currentUser?.profile_picture);
    }
  }, [currentUser]);

  const validate = () => {
    try {
      ProfileSchema.validateSync(
        {
          userName,
          firstName,
          lastName,
          email,
          dob,
          gender,
          password,
          confirmPassword,
          bio,
          phoneNumber,
          isChangePassword,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      if (err.inner) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      } else {
        validationErrors['generalError'] = err.message;
      }
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');

      let requestObj = {};
      if (isChangePassword) {
        requestObj = {
          username: userName,
          email: email,
          phone_number: phoneNumber,
          first_name: firstName,
          last_name: lastName,
          dob: dob,
          gender: gender,
          bio: bio,
          password: password,
          password_confirmation: confirmPassword,
        };
      } else {
        requestObj = {
          username: userName,
          email: email,
          phone_number: phoneNumber,
          first_name: firstName,
          last_name: lastName,
          dob: dob,
          gender: gender,
          bio: bio,
        };
      }

      const response = await axiosInstance.patch(
        UPDATE_PROFILE_ENDPOINT,
        requestObj
      );
      if (response.data.code === 200) {
        let imageData;
        if (file) {
          imageData = await uploadFile(
            USER_CLASS,
            response.data.data.id,
            USER_PROFILE_PICTURE_TYPE,
            file
          );
        }
        const modalClose =
          document.getElementsByClassName('close-modal-update');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        let updateUser = response.data.data;
        if (imageData?.data?.data) {
          updateUser.profile_picture = imageData?.data?.data;
        }
        setLoginUser(updateUser);
        if (isChangePassword) {
          await logoutUser();
          await dispatch(setData([]));
        }
        Toast.success(response.data.message, {
          onClose: () => {
            navigate('/');
            handleReset();
          },
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setUserName('');
    setLastName('');
    setFirstName('');
    setEmail('');
    setPassword('');
    setDob('');
    setGender('');
    setBio('');
    setPhoneNumber('');
    setGeneralError('');
    setErrors({});
  };

  const handleDeleteImage = async (id) => {
    try {
      const response = await axiosInstance.delete(REMOVE_ENDPOINT + id);

      if (response.status === 204) {
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleClosePassword = () => {
    setIsChangePassword(!isChangePassword);
    setConfirmPassword('');
    setPassword('');
    setShowConfirmPassword(false);
    setShowPassword(false);
    setErrors({});
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2 set-hh">Update Profile</h3>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6">
                            <Label labelHeading="User Name" />
                            <div className="tickets">
                              <input
                                type="text"
                                className="form-control input-theme"
                                onChange={(e) => setUserName(e.target.value)}
                                value={userName}
                              />
                              {errors.userName && (
                                <div className="error">{errors.userName}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Label labelHeading="First Name" />
                            <div className="tickets">
                              <input
                                type="text"
                                className="form-control input-theme"
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                              />
                              {errors.firstName && (
                                <div className="error">{errors.firstName}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Label labelHeading="Last Name" />
                            <div className="tickets">
                              <input
                                type="text"
                                className="form-control input-theme"
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                              />
                              {errors.lastName && (
                                <div className="error">{errors.lastName}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Label labelHeading="Email" />
                            <div className="tickets">
                              <input
                                type="email"
                                className="form-control input-theme"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                              />
                              {errors.email && (
                                <div className="error">{errors.email}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Label labelHeading="Phone" />
                            <div className="tickets">
                              <PhoneInputView
                                phoneNumber={phoneNumber}
                                setPhoneNumber={setPhoneNumber}
                              />
                              {errors.phoneNumber && (
                                <div className="error">
                                  {errors.phoneNumber}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Label labelHeading="DOB" />
                            <div className="tickets">
                              <input
                                type="date"
                                className="form-control input-theme"
                                onChange={(e) => setDob(e.target.value)}
                                value={dob}
                              />
                              {errors.dob && (
                                <div className="error">{errors.dob}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Label labelHeading="Gender*" />
                            <div className="tickets">
                              <select
                                id="inputState"
                                className="form-select"
                                onChange={(e) => setGender(e.target.value)}
                                value={gender}
                              >
                                <option value="">Select an option</option>
                                <option value="MALE">Male</option>
                                <option value="FEMALE">Female</option>
                                <option value="OTHER">Non Binary</option>
                              </select>
                              {errors.gender && (
                                <div className="error">{errors.gender}</div>
                              )}
                            </div>
                          </div>

                          {!isChangePassword && (
                            <div className="col-lg-3">
                              <div className="tickets">
                                <span
                                  onClick={() =>
                                    setIsChangePassword(!isChangePassword)
                                  }
                                  className="c-pass-word text-start pb-3"
                                >
                                  Change password
                                </span>
                              </div>
                            </div>
                          )}
                          {isChangePassword && (
                            <>
                              <div className="col-lg-3">
                                <Label labelHeading="Password" />
                                <div className="password input-search-items tickets">
                                  <input
                                    type={showPassword ? 'text' : 'password'}
                                    className="form-control input-theme mb-0"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                  <span onClick={togglePasswordVisibility}>
                                    {showPassword ? (
                                      <img
                                        src="../assets/icon/eye-password-hide.svg"
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="../assets/icon/eye-password-show.svg"
                                        alt=""
                                      />
                                    )}
                                  </span>
                                  {errors.password && (
                                    <div className="error">
                                      {errors.password}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <Label labelHeading="Confirm Password" />
                                <div className="password input-search-items tickets">
                                  <input
                                    type={
                                      showConfirmPassword ? 'text' : 'password'
                                    }
                                    className="form-control input-theme mb-0"
                                    onChange={(e) =>
                                      setConfirmPassword(e.target.value)
                                    }
                                    value={confirmPassword}
                                  />
                                  <span
                                    onClick={toggleConfirmPasswordVisibility}
                                  >
                                    {showConfirmPassword ? (
                                      <img
                                        src="../assets/icon/eye-password-hide.svg"
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="../assets/icon/eye-password-show.svg"
                                        alt=""
                                      />
                                    )}
                                  </span>
                                  {errors.confirmPassword && (
                                    <div className="error">
                                      {errors.confirmPassword}
                                    </div>
                                  )}
                                  <span
                                    onClick={handleClosePassword}
                                    className="c-pass-word"
                                  >
                                    Close
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="col-lg-12 ">
                            <Label labelHeading="Bio" />
                            <div className="tickets-2">
                              <textarea
                                className="form-control input-theme"
                                rows="3"
                                onChange={(e) => setBio(e.target.value)}
                                value={bio}
                              ></textarea>
                              {errors.bio && (
                                <div className="error">{errors.bio}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-12">
                            <Label labelHeading="Avatar" />
                            <ImageUploadInput
                              setFile={setFile}
                              handleDeleteImage={handleDeleteImage}
                              imageData={imageData}
                            />
                          </div>
                        </div>
                        {generalError && (
                          <p className="error text-center mt-3">
                            {generalError}
                          </p>
                        )}
                        <div className="set-button mt-4">
                          <ButtonLoader
                            active="orange-outline"
                            bgChange="white-bg w-pd-add save-w"
                            type="submit"
                            isLoading={isLoading}
                          >
                            Update
                          </ButtonLoader>
                          <Link
                            to="/"
                            className="close-modal-update"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleReset}
                          >
                            <ButtonRemoveImage
                              Heading="CANCEL"
                              active="gary-light-outline"
                              bgChange="gary-bg w-pd-add"
                            />
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
