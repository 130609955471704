import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Essentials,
  Paragraph,
  Undo,
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Link,
  Heading,
  BlockQuote,
  CodeBlock,
  List,
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
// import "ckeditor5-premium-features/ckeditor5-premium-features.css";
import PropTypes from 'prop-types';

const RichTextEditor = ({ value, setEditorData, index, isDisabled }) => {
  const plugins = [
    Essentials,
    Paragraph,
    Undo,
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Link,
    Heading,
    BlockQuote,
    CodeBlock,
    List,
  ];

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  return (
    <>
      <CKEditor
        key={index}
        className="form-control input-theme"
        editor={ClassicEditor}
        data={value}
        onChange={handleEditorChange}
        disabled={isDisabled ? true : false}
        config={{
          toolbar: {
            items: [
              'undo',
              'redo',
              '|',
              'heading',
              '|',
              'bold',
              'italic',
              'underline',
              'strikethrough',
              '|',
              'link',
              '|',
              'blockQuote',
              'codeBlock',
              '|',
              'bulletedList',
              'numberedList',
            ],
          },
          licenseKey: process.env.REACT_APP_CK_EDITOR_LICENSEKEY,
          plugins,
          removePlugins: ['CKEditorFooter'],
        }}
      />
    </>
  );
};

RichTextEditor.propTypes = {
  value: PropTypes.string,
  setEditorData: PropTypes.func,
  index: PropTypes.string,
  isDisabled: PropTypes.bool,
};
export default RichTextEditor;
