import React, { useEffect, useState } from 'react';
import Label from './Label';
import { Link } from 'react-router-dom';
import ButtonLoader from './ButtonLoader';
import axiosInstance from '../Utils/axiosInstance';
import {
  ADD_QUESTION_PROFICIENCY_ENDPOINT,
  ANSWER_PROFICIENCY_ENDPOINT,
} from '../Utils/Endpoints';
import Toast from '../Utils/Toast';
import { getItem } from '../Utils/LocalStorage';
import PropTypes from 'prop-types';
import ButtonRemoveImage from './ButtonRemoveImage';

const FPEQuestions = ({
  fetchQuestionAnswerList,
  setSelectedQuestionAnswer,
  selectedQuestionAnswer,
}) => {
  const [question, setQuestion] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);
  const [options, setOptions] = useState([
    {
      id: 'option_' + 1,
      label: 'Option',
      value: '',
      valid: 0,
      isDisable: false,
    },
  ]);
  const proficiencyId = getItem('proficiencyId');
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (selectedQuestionAnswer?.id) {
      setQuestion(selectedQuestionAnswer.question);
      setQuestionId(selectedQuestionAnswer.id);
      if (selectedQuestionAnswer?.answers?.length > 0) {
        const answerList = selectedQuestionAnswer.answers.map((answer) => {
          const answerObject = {
            id: 'answer_' + answer.id,
            label: 'Option',
            value: answer.answer,
            isDisable: true,
          };

          if (answer.is_valid_answer === true || answer.is_valid_answer === 1) {
            setSelectedOption('answer_' + answer.id);
          }

          return answerObject;
        });
        const newOption = {
          id: 'option_' + (selectedQuestionAnswer.answers.length + 1),
          label: `Option`,
          valid: 0,
          value: '',
          isDisable: false,
        };
        setOptions([...answerList, newOption]);
      }
    }
  }, [selectedQuestionAnswer]);

  const handleQuestion = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingQuestion(true);
      setGeneralError('');
      const response = await axiosInstance.post(
        ADD_QUESTION_PROFICIENCY_ENDPOINT + proficiencyId + '/question',
        {
          question: question,
        }
      );
      if (response.data.code === 200) {
        setQuestionId(response.data.data.id);
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoadingQuestion(false);
    }
  };

  const handleUpdateQuestion = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingQuestion(true);
      setGeneralError('');
      const response = await axiosInstance.patch(
        ADD_QUESTION_PROFICIENCY_ENDPOINT +
          proficiencyId +
          '/question/' +
          selectedQuestionAnswer.id,
        {
          question: question,
        }
      );
      if (response.data.code === 200) {
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoadingQuestion(false);
    }
  };

  const handleDeleteQuestion = async () => {
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.delete(
        ADD_QUESTION_PROFICIENCY_ENDPOINT +
          proficiencyId +
          '/question/' +
          questionId
      );
      if (response.status === 204) {
        const modalClose = document.getElementsByClassName(
          'close-question-modal'
        );
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (id, value) => {
    setOptions(
      options.map((option) => {
        if (option.id === id) {
          return { ...option, value: value };
        }

        return option;
      })
    );
  };

  const handleChangeEdit = (id) => {
    setOptions(
      options.map((option) => {
        if (option.id === id) {
          return { ...option, isDisable: false };
        }

        return option;
      })
    );
  };

  const handleValidChange = (id, value) => {
    if (value) {
      setSelectedOption(id);
    }
    setOptions(
      options.map((option) => {
        if (option.id === id) {
          return { ...option, valid: 1 };
        }
        return { ...option, valid: 0 }; // set valid to false for other options
      })
    );
  };

  const handleSave = async (e, data) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.post(
        ANSWER_PROFICIENCY_ENDPOINT + questionId + '/answer',
        {
          answer: data.value,
          is_valid_answer: data.valid ? data.valid : 0,
        }
      );
      if (response.data.code === 200) {
        let updatedId = response.data.data.id;
        const optionData = options.map((option) => {
          if (option.id === data.id) {
            if (response.data.data.is_valid_answer === 1) {
              setSelectedOption(
                selectedQuestionAnswer?.id ? 'answer_' + updatedId : updatedId
              );
            }
            return {
              ...option,
              id: selectedQuestionAnswer?.id
                ? 'answer_' + updatedId
                : updatedId,
              isDisable: true,
              valid: response.data.data.is_valid_answer,
            };
          }
          return option;
        });
        const newOption = {
          id: 'option_' + (options.length + 1),
          label: `Option`,
          valid: 0,
          value: '',
          isDisable: false,
        };
        setOptions([...optionData, newOption]);
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      setIsUpdateLoading(true);
      setGeneralError('');

      await options.map(async (data, index) => {
        if (data.value !== '' && data?.id.includes('answer_')) {
          const str = data?.id;
          const regex = /_(\d+)/;
          const match = str.match(regex);
          const answerId = match && match[1];

          const response = await axiosInstance.patch(
            ANSWER_PROFICIENCY_ENDPOINT + questionId + '/answer/' + answerId,
            {
              answer: data.value,
              is_valid_answer: data.valid ? data.valid : false,
            }
          );
          if (response.data.code === 200) {
            let updatedId = response.data.data.id;

            setOptions(
              options.map((option) => {
                if (option.id === data.id) {
                  return { ...option, id: updatedId };
                }
                return option;
              })
            );
            if (index === options.length - 2) {
              const modalClose = document.getElementsByClassName(
                'close-question-modal'
              );
              for (let i = 0; i < modalClose.length; i++) {
                modalClose[i].click();
              }
              Toast.success(response.data.message, {
                autoClose: 2000,
              });
            }
          } else {
            setGeneralError(response.data.message);
          }
        }
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsUpdateLoading(false);
    }
  };

  const handleClose = async () => {
    setQuestionId('');
    setQuestion('');
    setOptions([
      {
        id: 'option_' + 1,
        label: 'Option',
        value: '',
        valid: 0,
        isDisable: false,
      },
    ]);
    setSelectedOption(null);
    setGeneralError('');
    setSelectedQuestionAnswer({});
    await fetchQuestionAnswerList();
  };

  const handleDeleteAnswer = async (id) => {
    try {
      setIsLoading(true);
      setGeneralError('');
      let answerId = id;
      if (typeof id !== 'number' && id?.includes('answer_')) {
        const str = id;
        const regex = /_(\d+)/;
        const match = str.match(regex);
        answerId = match && match[1];
      }

      const response = await axiosInstance.delete(
        ANSWER_PROFICIENCY_ENDPOINT + questionId + '/answer/' + answerId
      );
      if (response.status === 204) {
        setOptions(options.filter((d) => d.id !== id));
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="modal fade "
        id="FPEQuestions"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                {selectedQuestionAnswer?.id ? 'Update' : 'Create'} FPE Questions
              </h5>
              <Link
                to="#"
                className="close-firearm-2"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                <img src="../assets/icon/cross-svgrepo-com.svg" alt="" />
              </Link>
            </div>
            <div className="modal-body modal-height">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <form
                    onSubmit={
                      selectedQuestionAnswer?.id
                        ? handleUpdateQuestion
                        : handleQuestion
                    }
                  >
                    <div className="questions">
                      <div className="q-n">
                        <Label labelHeading="Enter your Question*" />
                        <div className="tickets">
                          <input
                            type="text"
                            className="form-control input-theme"
                            onChange={(e) => setQuestion(e.target.value)}
                            value={question}
                            required
                            disabled={
                              questionId && !selectedQuestionAnswer?.id
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      <div className="">
                        {(questionId === '' || selectedQuestionAnswer?.id) && (
                          <ButtonLoader
                            Heading="SAVE"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add save-w"
                            type="submit"
                            isLoading={isLoadingQuestion}
                          >
                            {selectedQuestionAnswer?.id ? 'Update' : 'Save'}
                          </ButtonLoader>
                        )}
                        {questionId && !selectedQuestionAnswer?.id && (
                          <ButtonLoader
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add delete-w"
                            isLoading={isLoadingQuestion}
                            onClick={handleDeleteQuestion}
                          >
                            Delete
                          </ButtonLoader>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                {questionId && (
                  <div>
                    <div className="enter-options">
                      <h2>Enter Options</h2>
                    </div>
                    {options.map((option, index) => (
                      <div key={option.id} className="col-md-12">
                        <form onSubmit={(e) => handleSave(e, option)}>
                          <div className="options-input">
                            <div className="ww">
                              <Label
                                labelHeading={option.label + ' ' + (index + 1)}
                              />
                              <div className="tickets">
                                <input
                                  type="text"
                                  required
                                  className="form-control input-theme"
                                  value={option.value}
                                  onChange={(e) =>
                                    handleInputChange(option.id, e.target.value)
                                  }
                                  disabled={option.isDisable}
                                />
                              </div>
                            </div>
                            <div className="valid">
                              {index === 0 && (
                                <Label
                                  labelHeading="Valid"
                                  addCss="d-flex justify-content-center"
                                />
                              )}

                              <div className="form-check q-r-n">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio_valid"
                                  id={'inlineCheckbox' + option.id}
                                  checked={selectedOption === option.id}
                                  onChange={(e) =>
                                    handleValidChange(
                                      option.id,
                                      e.target.checked
                                    )
                                  }
                                  disabled={option.isDisable}
                                />
                              </div>
                            </div>
                            <div className="options-save d-flex align-items-center gap-2">
                              {!selectedQuestionAnswer?.id && (
                                <>
                                  {typeof option.id !== 'number' && (
                                    <ButtonLoader
                                      Heading="SAVE"
                                      active="orange-outline"
                                      bgChange="white-bg w-pd-add save-w"
                                      type="submit"
                                      isLoading={isLoading}
                                    >
                                      Save
                                    </ButtonLoader>
                                  )}
                                  {typeof option.id === 'number' && (
                                    <div className="d-flex gap-3">
                                      <img
                                        src="../assets/icon/delete.svg"
                                        alt=""
                                        style={{ width: '25px' }}
                                        onClick={() =>
                                          handleDeleteAnswer(option.id)
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                              {selectedQuestionAnswer?.id &&
                                typeof option?.id === 'string' &&
                                option.id.includes('answer_') && (
                                  <div className="d-flex gap-3">
                                    <img
                                      src="../assets/icon/edit.svg"
                                      alt=""
                                      style={{ width: '25px' }}
                                      onClick={() =>
                                        handleChangeEdit(option.id)
                                      }
                                    />
                                    <img
                                      src="../assets/icon/delete.svg"
                                      alt=""
                                      style={{ width: '25px' }}
                                      onClick={() =>
                                        handleDeleteAnswer(option.id)
                                      }
                                    />
                                  </div>
                                )}
                              {selectedQuestionAnswer?.id &&
                                typeof option.id !== 'number' &&
                                !option.id.includes('answer_') && (
                                  <ButtonLoader
                                    Heading="SAVE"
                                    active="orange-outline"
                                    bgChange="white-bg w-pd-add save-w"
                                    type="submit"
                                    isLoading={isLoading}
                                  >
                                    Save
                                  </ButtonLoader>
                                )}
                            </div>
                          </div>
                        </form>
                      </div>
                    ))}
                  </div>
                )}

                {generalError && (
                  <p className="error text-center">{generalError}</p>
                )}
              </div>
            </div>
            {selectedQuestionAnswer?.id && (
              <div className="modal-footer border-0 justify-content-center">
                <Link onClick={handleUpdate}>
                  <ButtonLoader
                    Heading="SAVE"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add save-w"
                    isLoading={isUpdateLoading}
                  >
                    Save
                  </ButtonLoader>
                </Link>
                <Link
                  to=""
                  data-bs-dismiss="modal"
                  className="close-question-modal"
                  onClick={handleClose}
                >
                  <ButtonRemoveImage
                    Heading="CANCEL"
                    active="gary-light-outline"
                    bgChange="gary-bg w-pd-add"
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

FPEQuestions.propTypes = {
  fetchQuestionAnswerList: PropTypes.func,
  setSelectedQuestionAnswer: PropTypes.func,
  selectedQuestionAnswer: PropTypes.object,
};

export default FPEQuestions;
