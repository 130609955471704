/**
 * Handles file upload for different cases
 * @param {string} ownerClass - The owner class (App\\Models\\EntityHasProficiencyType,
             App\\Models\\Range, App\\Models\\User )
 * @param {number} ownerId - The owner ID
 * @param {number} type - The file type
 * (GENERAL = 1,USER_PROFILE_PICTURE = 2,USER_GOVT_ID_FRONT = 3,USER_GOVT_ID_BACK = 4,
 * RANGE_LOGO = 5,RANGE_BANNER = 6,RANGE_GALLERY = 7,PROFICIENCY_LOGO = 8)
 * @param {File} file - The file to upload
 * @returns {Promise} - The response from the server
 */

import axiosInstance from './axiosInstance';
import { ADDRESS_ENDPOINT, UPLOAD_ENDPOINT } from './Endpoints';

export const uploadFile = async (ownerClass, ownerId, type, file) => {
  const formdata = new FormData();
  formdata.append('owner_class', ownerClass);
  formdata.append('owner_id', ownerId.toString());
  formdata.append('type', type.toString());
  formdata.append('file_name', file);

  const response = await axiosInstance.post(UPLOAD_ENDPOINT, formdata);
  if (response.data.code === 200) {
    return response;
  } else {
    return response.data.message;
  }
};

export const addAddress = async (ownerClass, ownerId, address) => {
  const requestObj = {
    owner_class: ownerClass, // required | string | App\Models\Range, App\Models\User
    owner_id: ownerId, // required | integer | based on owner class
    title: '', // nullable | string
    address_1: address.address_1, // required | string
    address_2: address.address_2, // nullable | string
    city: address.city, // required | string
    state: address.state, // required | string
    country_id: address.country_id, // required | integer | Country ID
    zip_code: address.zip_code, // required | string
    lat: address.lat, // nullable | dobule
    lng: address.lng, // nullable | dobule
    contact_number: address.contact_number, // nullable | string
    contact_person_name: address.contact_person_name, // nullable | string
  };

  const response = await axiosInstance.post(ADDRESS_ENDPOINT, requestObj);
  if (response.data.code === 200) {
    return response;
  } else {
    return response.data.message;
  }
};

export const updateAddress = async (addressId, address) => {
  const requestObj = {
    title: '', // nullable | string
    address_1: address.address_1, // required | string
    address_2: address.address_2, // nullable | string
    city: address.city, // required | string
    state: address.state, // required | string
    country_id: address.country_id, // required | integer | Country ID
    zip_code: address.zip_code, // required | string
    lat: address.lat, // nullable | dobule
    lng: address.lng, // nullable | dobule
    contact_number: address.contact_number, // nullable | string
    contact_person_name: address.contact_person_name, // nullable | string
  };

  const response = await axiosInstance.patch(
    ADDRESS_ENDPOINT + '/' + addressId,
    requestObj
  );
  if (response.data.code === 200) {
    return response;
  } else {
    return response.data.message;
  }
};
