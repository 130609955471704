import React from 'react';
import { Link } from 'react-router-dom';

const Plans = () => {
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">Memberships</h3>
                  <div className="all-date-selected">
                    <div className="select-input tickets">
                      <select className="form-select mb-0 w-100 pe-5">
                        <option selected>Golden Gub Club</option>
                        <option value="2">
                          Stoddard&apos;s Range and Guns - Atlanta Midtown
                        </option>
                        <option value="3">
                          Stoddard&apos;s Range and Guns - Douglasville
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="table-responsive w-100">
                      <table className="table table-time-gun-create mobile-responsive">
                        <thead className="head-left">
                          <tr>
                            <th className="text-start">PRODUCT NAME</th>
                            <th className="text-center">MEMBERS</th>
                            <th>ACTIVE</th>
                            <th className="text-center">ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody className="table-time-gun-create__old-even">
                          <tr>
                            <td
                              data-label="Product Name"
                              className="text-start"
                            >
                              Stoddard&apos;s Midtown Individual
                            </td>
                            <td data-label="Members" className="color-black">
                              0
                            </td>
                            <td data-label="Active">
                              <label className="label">
                                <progress max="100" value="0">
                                  10%
                                </progress>
                                0%
                              </label>
                            </td>
                            <td data-label="Actions">
                              <Link
                                to="/plan-individual"
                                className="text-center color-theme text-left-mob"
                              >
                                <img src="../assets/icon/eyes.png" alt="" />
                                View Plan
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td
                              data-label="Product Name"
                              className="text-start"
                            >
                              Stoddard&apos;s Midtown Individual
                            </td>
                            <td data-label="Members" className="color-black">
                              0
                            </td>
                            <td data-label="Active">
                              <label className="label">
                                <progress max="100" value="100">
                                  10%
                                </progress>
                                100%
                              </label>
                            </td>
                            <td data-label="Actions">
                              <Link
                                to="/plan-individual"
                                className="text-center color-theme text-left-mob"
                              >
                                <img src="../assets/icon/eyes.png" alt="" />
                                View Plan
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plans;
