import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  GUEST_LIST_ENDPOINT,
  USER_PROFICIENCY_EXAM_LIST_ENDPOINT,
} from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import { getItem } from '../Utils/LocalStorage';
import Logger from '../Utils/Logger';
import RangeNotFound from '../Components/RangeNotFound';
import SkeletonLoader from '../Components/SkeletonLoader';
import LoadingOverlayMain from '../Components/LoadingOverlayMain';
import { RANGE_CLASS } from '../Utils/ModelClasses';
import moment from 'moment/moment';
import Pagination from '../Components/Pagination';
import HexagonSize from '../Components/HexagonSize';

const Guests = () => {
  const [guestList, setGuestList] = useState([]);
  const [userExamList, setUserExamList] = useState([]);
  const [examQuestionAns, setExamQuestionAns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [date, setDate] = useState('');
  const [search, setSearch] = useState('');

  const rangeId = getItem('rangeId');

  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchGuestList();
  }, [search, currentPage, itemsPerPage]);

  const fetchGuestList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        GUEST_LIST_ENDPOINT +
          rangeId +
          `?search=${search}&per_page=${itemsPerPage}&page=${currentPage}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setGuestList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewClick = (guest, index) => {
    setExpandedRowIndex(index);
    fetchExamList(guest);
  };

  const fetchExamList = async (guest) => {
    try {
      setIsLoadingUser(true);
      const response = await axiosInstance.get(
        USER_PROFICIENCY_EXAM_LIST_ENDPOINT +
          `?owner_class=${RANGE_CLASS}&owner_id=${rangeId}&user_id=${guest.user_id}`
      );
      if (response.data.code === 200) {
        setUserExamList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoadingUser(false);
    }
  };

  return (
    <>
      {isLoadingUser && <LoadingOverlayMain />}
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">Guests</h3>
                  <div className="all-date-selected">
                    <div className="date-input tickets d-none">
                      <input
                        type="date"
                        className="form-control input-theme mb-0"
                        placeholder="date"
                        onChange={(e) => {
                          setDate(e.target.value);
                          setCurrentPage(1);
                          setExpandedRowIndex(null);
                        }}
                        value={date}
                      />
                    </div>
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                        id="search"
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setCurrentPage(1);
                          setExpandedRowIndex(null);
                        }}
                        value={search}
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      {guestList?.length === 0 && !isLoading && (
                        <RangeNotFound title="Guest" isInfo={true} />
                      )}
                      {isLoading && <SkeletonLoader />}
                      {guestList?.length > 0 && !isLoading && (
                        <div className="table-responsive">
                          <table className="table table-time-gun-create mobile-responsive mobile">
                            <thead className="head-left">
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>FPE Details</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className=" table-time-gun-create__old-even">
                              {guestList.map((guest, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td className="mob-tab-view td-left">
                                      <Link to="/membership-two">
                                        <HexagonSize
                                          imageUser={
                                            guest.user.profile_picture
                                              ? guest.user.profile_picture
                                              : '../assets/images/profile.png'
                                          }
                                        />
                                      </Link>
                                    </td>
                                    <td data-label="Name">
                                      <Link to="/membership-two">
                                        <span className="user-n-2 mob-tab-view-2">
                                          <HexagonSize
                                            imageUser={
                                              guest.user.profile_picture
                                                ? guest.user.profile_picture
                                                : '../assets/images/profile.png'
                                            }
                                          />
                                          {guest.user.name}
                                        </span>
                                        <span className="mob-tab-view">
                                          {guest.user.name}
                                        </span>
                                      </Link>
                                    </td>
                                    {/* <td data-label="Name">{guest.user.name}</td> */}
                                    <td data-label="Email">
                                      <Link
                                        to={`mailto:${guest.user.email}`}
                                        className="w-a"
                                      >
                                        {guest.user.email}
                                      </Link>
                                    </td>
                                    <td data-label="Phone">
                                      {guest.user.phone_number}
                                    </td>
                                    <td data-label="FPE Details">
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          handleViewClick(guest, index)
                                        }
                                        className="color-theme"
                                      >
                                        View
                                      </Link>
                                    </td>
                                    <td data-label="Action">
                                      <Link
                                        to="/ranges/book-slot-user-add"
                                        state={{
                                          user: guest,
                                        }}
                                      >
                                        <p className="start-b">
                                          Start a Lane Booking
                                        </p>
                                      </Link>
                                      {/* <Link
                                        to="/accounts/purchase-product"
                                        state={{
                                          user: guest,
                                        }}
                                      > */}
                                      <p className="start-b">
                                        Start a Purchase
                                      </p>
                                      {/* </Link> */}
                                    </td>
                                  </tr>
                                  {expandedRowIndex === index &&
                                    userExamList?.length > 0 && (
                                      <tr>
                                        <td colSpan="5" className="p-0">
                                          <table className="table create-card text-center">
                                            <thead>
                                              <tr>
                                                <th>FPE Type</th>
                                                <th>Date requested</th>
                                                {/* <th>Gap Assessment</th> */}
                                                <th>Certification</th>
                                                <th>Action</th>
                                                <th>Admin Log</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {userExamList?.map(
                                                (item, index) => (
                                                  <tr key={index}>
                                                    <td data-label="Cpe Type">
                                                      <ul className="gun-customer mb-0">
                                                        <li className="d-none">
                                                          <img
                                                            src={
                                                              item?.entity_has_proficiency_type
                                                                ? item
                                                                    ?.entity_has_proficiency_type
                                                                    ?.logo
                                                                    ?.fullUrl
                                                                : '../assets/icon/handgun.svg'
                                                            }
                                                            alt=""
                                                            className="image-gg d-none d-md-block"
                                                          />
                                                        </li>
                                                        <li className="color-black">
                                                          {
                                                            item
                                                              ?.entity_has_proficiency_type
                                                              .proficiency_type
                                                              ?.name
                                                          }
                                                        </li>
                                                      </ul>
                                                    </td>
                                                    <td data-label="Date-Request">
                                                      <p className="table-width-few-td">
                                                        {moment(
                                                          item.created_at
                                                        ).format('YYYY-MM-DD')}
                                                        {/* September 6, 2023 1:59 am */}
                                                      </p>
                                                    </td>
                                                    {/* <td data-label="Gap Assessment">Review RQD</td> */}
                                                    <td data-label="Certification">
                                                      <p>
                                                        {item.examDetails.valid}
                                                        /
                                                        {item.examDetails.total}
                                                      </p>
                                                      <span
                                                        className={
                                                          item.examDetails
                                                            .valid ===
                                                          item.examDetails.total
                                                            ? 'color-gray'
                                                            : 'color-red'
                                                        }
                                                      >
                                                        {item.examDetails
                                                          .valid ===
                                                        item.examDetails.total
                                                          ? 'Cleared'
                                                          : 'Incomplete'}
                                                      </span>
                                                    </td>
                                                    <td data-label="Action">
                                                      <span
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        onClick={() =>
                                                          setExamQuestionAns(
                                                            item.proficiency_exam_has_answer
                                                          )
                                                        }
                                                        className="color-black cursor-hover text-decoration-underline"
                                                      >
                                                        View Submission
                                                      </span>
                                                    </td>
                                                    <td data-label="Admin Log">
                                                      234
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>

                    {guestList?.length > 0 && !isLoading && (
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        setItemsPerPage={setItemsPerPage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content ">
              <div className="modal-body question">
                <div className="text-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setExamQuestionAns([])}
                  ></button>
                </div>
                <ul className="modal-question">
                  {examQuestionAns?.map((item, index) => (
                    <li key={index}>
                      <p className="pb-2">
                        <span className="color-black">Question: </span>
                        {item.question}
                      </p>
                      <p className="pb-3">
                        <span className="color-black">Answer: </span>
                        {item.answer}
                        <small className="mx-3 badge bg-success d-flex align-items-center">
                          Correct
                        </small>
                        <small className="mx-3 badge red-bg-color d-flex align-items-center d-none">
                          InCorrect
                        </small>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guests;
