import React from 'react';
import PropTypes from 'prop-types';

const ButtonAdd = (props) => {
  return (
    <>
      <div className={`custom-action-button   ${props.active}`}>
        <button
          type="button"
          className={` bgColor ${props.bgChange}`}
          disabled={props.disabled}
        >
          <img src={props.images} alt="" />
          {props.Heading}
        </button>
      </div>
    </>
  );
};

ButtonAdd.propTypes = {
  active: PropTypes.string,
  bgChange: PropTypes.string,
  images: PropTypes.string,
  Heading: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ButtonAdd;
