import React from 'react';
import ButtonAdd from '../Components/ButtonAdd';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import HexagonSize from '../Components/HexagonSize';
const ClassCheckIn = () => {
  return (
    <>
      <div className="outer-border ">
        <div className="container-fluid">
          <div className="row">
            <h3 className="all-top-heading">CLASS CHECK-IN</h3>
            <div className="tabs-button-user">
              <div className="nav nav-pills">
                <div
                  className="nav-link active"
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                >
                  <h4>Handgun 101</h4>
                  <p>January 1, 2023 </p>
                  <p>10:21 AM CST</p>
                </div>
                <div
                  className="nav-link"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                >
                  <h4>Handgun 102</h4>
                  <p>January 1, 2023</p>
                  <p>10:24 AM CST</p>
                </div>
                <div
                  className="nav-link"
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                >
                  <h4>Rifle - Beginner</h4>
                  <p>January 1, 2023</p>
                  <p>11:34 AM CST</p>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card-body">
                <h3 className="d-none d-md-block">CLass CHECK-IN</h3>
                <div className="triangle-top">
                  <div className="body-text pt-0 ">
                    <ul className="lane-booking">
                      <li>
                        LANE BOOKING - <span>12029874</span>
                      </li>
                      <li>
                        <img src="../assets/icon/user.svg" alt="iconfirst" />
                        <span>
                          <strong>2</strong>
                        </span>
                      </li>
                      <li>
                        <img src="../assets/icon/bay.svg" alt="" />
                        <span>
                          <strong>BAY 1 | LANE 3</strong>
                        </span>
                      </li>
                      <li>
                        <img src="../assets/icon/time.svg" alt="" />
                        <span>
                          <strong>4 PM CST</strong>
                        </span>
                      </li>
                      <li>
                        <ButtonAdd
                          Heading="ADD PARTICIPANT"
                          images="../assets/icon/add_user.svg"
                          active="orange-outline"
                          bgChange="white-bg "
                        />
                      </li>
                      <li>
                        <ButtonAdd
                          Heading="EDIT"
                          images="../assets/icon/edit.svg"
                          active="orange-outline"
                          bgChange="white-bg "
                        />
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                      >
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="table-responsive">
                              <table className="table table-user-gan table-changes-border mobile-responsive mobile">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Booking Details</th>
                                    <th>Membership Type</th>
                                    <th>Account Status</th>
                                    <th>Check-In</th>
                                    <th>Competence Rating</th>
                                    <th>Admin Log</th>
                                  </tr>
                                </thead>
                                <tbody className="mobile__old-even">
                                  <tr>
                                    <td data-label="Name">
                                      <span className="d-flex align-items-center gap-2 color-black fs-5">
                                        <HexagonSize
                                          imageUser="../assets/images/man-user.jpg"
                                          hexBg="set-images d-none d-md-block"
                                        />
                                        Billy Squire
                                      </span>
                                    </td>
                                    <td data-label="Booking Details">
                                      <span className="color-black">
                                        RECEIPT
                                      </span>
                                      <img
                                        src="../assets/icon/download_icon.svg"
                                        alt="download_icon"
                                        style={{
                                          width: '9px',
                                          marginLeft: '5px',
                                        }}
                                      />
                                      <p>January 1, 2023 4 PM CST</p>
                                    </td>
                                    <td data-label="Membership Type">
                                      <img
                                        src="../assets/icon/membership_badge.svg"
                                        alt=""
                                        style={{ width: '43px' }}
                                      />
                                    </td>
                                    <td
                                      data-label="Account Status"
                                      className="table-button-width"
                                    >
                                      <ButtonRemoveImage
                                        Heading="APPROVED"
                                        bgChange="success-bg-color"
                                        active="success-outline"
                                      />
                                    </td>
                                    <td
                                      data-label="Check In"
                                      className="table-button-width"
                                    >
                                      <ButtonRemoveImage
                                        Heading="PENDING"
                                        bgChange="bg-orange"
                                      />
                                    </td>
                                    <td
                                      data-label="Competence Rating"
                                      className="table-button-width"
                                    >
                                      <ButtonRemoveImage
                                        Heading="UPDATE"
                                        active="gary-light-outline wt"
                                        bgChange="gary-bg "
                                      />
                                    </td>
                                    <td data-label="Admin Log">12</td>
                                  </tr>
                                  <tr>
                                    <td data-label="Name">
                                      <span className="d-flex align-items-center gap-2 color-black fs-5">
                                        <HexagonSize
                                          imageUser="../assets/images/man-user.jpg"
                                          hexBg="set-images d-none d-md-block"
                                        />
                                        Jhonny Bravo
                                      </span>
                                    </td>
                                    <td data-label="Booking Details">
                                      <span className="color-black">
                                        RECEIPT
                                      </span>
                                      <img
                                        src="../assets/icon/download_icon.svg"
                                        alt="download_icon"
                                        style={{
                                          width: '9px',
                                          marginLeft: '5px',
                                        }}
                                      />
                                      <p>January 1, 2023 4 PM CST</p>
                                    </td>
                                    <td data-label="Membership Type">
                                      <img
                                        src="../assets/images/g.png"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      data-label="Account Status"
                                      className="table-button-width"
                                    >
                                      <ButtonRemoveImage
                                        Heading="PENDING"
                                        bgChange="bg-orange"
                                      />
                                    </td>
                                    <td
                                      data-label="Check In"
                                      className="table-button-width"
                                    >
                                      <ButtonRemoveImage
                                        Heading="PENDING"
                                        bgChange="bg-orange"
                                      />
                                    </td>
                                    <td
                                      data-label="Competence Rating"
                                      className="table-button-width"
                                    >
                                      <ButtonRemoveImage
                                        Heading="UPDATE"
                                        active="gary-light-outline wt"
                                        bgChange="gary-bg "
                                      />
                                    </td>
                                    <td data-label="Admin Log">123</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="v-pills-profile">
                        2
                      </div>
                      <div className="tab-pane fade" id="v-pills-messages">
                        3
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassCheckIn;
