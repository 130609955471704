import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { saveItem } from '../Utils/LocalStorage';

const FPECard = (props) => {
  const handleViewFPE = (proficiencyId) => {
    saveItem('proficiencyId', proficiencyId);
  };
  return (
    <>
      <Link
        to="/ranges/create-qu"
        className="card user-card mt-2 triangle-card line-card"
        onClick={() => handleViewFPE(props.firearmData.id)}
      >
        <div className={` box-bg m-auto ${props.hexBg}`}>
          <div className={`bg-hox ${props.hexOuterLine}`}>
            <img
              src={props.gunImage}
              alt=""
              className={` img-size ${props.imgSize}`}
            />
          </div>
        </div>

        <div className="card-body-2">
          <div
            className="d-img-c"
            data-bs-toggle="modal"
            data-bs-target="#deleteModal"
          >
            <Link
              to="#"
              onClick={() =>
                props?.firearmData?.logo
                  ? props.handleDelete(
                      props.firearmData.id,
                      'firearm',
                      props.firearmData.logo.id
                    )
                  : props.handleDelete(props.firearmData.id, 'firearm')
              }
            >
              <img
                src="../assets/icon/delete.svg"
                alt=""
                className="ddd-card"
              />
            </Link>
          </div>
          <h4 className="pb-3">{props.FPEHeading}</h4>
        </div>
      </Link>
    </>
  );
};

FPECard.propTypes = {
  props: PropTypes.object,
  hexBg: PropTypes.string,
  imgSize: PropTypes.string,
  FPEHeading: PropTypes.string,
  gunImage: PropTypes.string,
  hexOuterLine: PropTypes.string,
  handleDelete: PropTypes.func,
  firearmData: PropTypes.object,
};
export default FPECard;
