export const API_BASE_URL = process.env.REACT_APP_BE_URL + '/api/v1/';

export const LOGIN_ENDPOINT = `${API_BASE_URL}login`;

// Password
export const FORGOT_PASSWORD_ENDPOINT = `${API_BASE_URL}password/forgot`;
export const RESET_PASSWORD_ENDPOINT = `${API_BASE_URL}password/reset`;

export const RANGE_ENDPOINT = `${API_BASE_URL}ranges`;
export const RANGE_SOCIAL_LINK_ENDPOINT = `${API_BASE_URL}range/associate/social-link/`;
export const RANGE_SOCIAL_LINK_UPDATE_ENDPOINT = `${API_BASE_URL}range/associate/update/social-link/`;
export const RANGE_SOCIAL_LINK_DELETE_ENDPOINT = `${API_BASE_URL}range/de-associate/social-link/`;

//Operation Hours
export const RANGE_OPERATION_HOUR_ENDPOINT = `${API_BASE_URL}operation-hour/associate`;
export const RANGE_OPERATION_HOUR_DELETE_ENDPOINT = `${API_BASE_URL}operation-hour/de-associate/`;

export const RANGE_ASSOCIATED_RANGES_ENDPOINT = `${API_BASE_URL}range/associated/ranges/`;
export const ASSOCIATED_RANGE_ENDPOINT = `${API_BASE_URL}range/associate/ranges/`;
export const ASSOCIATED_RANGE_DELETE_ENDPOINT = `${API_BASE_URL}range/de-associate/ranges/`;
export const RANGE_TRAINERS_ENDPOINT = `${API_BASE_URL}range/associated/trainers/`;
export const ADD_TRAINER_ENDPOINT = `${API_BASE_URL}trainer`;
export const RANGE_ASSOCIATE_TRAINER_ENDPOINT = `${API_BASE_URL}range/associate/trainers/`;
export const ASSOCIATED_TRAINER_DELETE_ENDPOINT = `${API_BASE_URL}range/de-associate/trainers/`;

// Bay's
export const ADD_BAY_ENDPOINT = `${API_BASE_URL}bay/`;
export const LIST_BAY_ENDPOINT = `${API_BASE_URL}bay/list/`;

// Bay's Lanes
export const LIST_BAY_LANES_ENDPOINT = `${API_BASE_URL}lane/list/`;
export const ADD_LANE_ENDPOINT = `${API_BASE_URL}lane/`;

// Lane's slot
export const LANE_SLOT_ENDPOINT = `${API_BASE_URL}lane/slot/list/`;

// Proficiency
export const ADD_QUESTION_PROFICIENCY_ENDPOINT = `${API_BASE_URL}entity/proficiency/type/`;
export const ANSWER_PROFICIENCY_ENDPOINT = `${API_BASE_URL}entity/proficiency/type/question/`;
export const ADD_PROFICIENCY_ENDPOINT = `${API_BASE_URL}entity/proficiency/type`;
export const RANGE_PROFICIENCY_LIST_ENDPOINT = `${API_BASE_URL}entity/proficiency/type/list`;
export const PROFICIENCY_EXAM_LIST_ENDPOINT = `${API_BASE_URL}entity/proficiency/type/exam/customer/list`;
export const USER_PROFICIENCY_EXAM_LIST_ENDPOINT = `${API_BASE_URL}entity/proficiency/type/exam/list`;
export const PROFICIENCY_EXAM_UPDATE_ENDPOINT = `${API_BASE_URL}entity/proficiency/type/exam/update`;

// File Upload
export const UPLOAD_ENDPOINT = `${API_BASE_URL}file/upload`;
export const REMOVE_ENDPOINT = `${API_BASE_URL}file/remove/`;

// Address
export const ADDRESS_ENDPOINT = `${API_BASE_URL}address`;
export const COUNTRIES_ENDPOINT = `${API_BASE_URL}countries`;

// Instructor
export const INSTRUCTORS_ENDPOINT = `${API_BASE_URL}trainers`;

//Guest
export const GUEST_LIST_ENDPOINT = `${API_BASE_URL}range/guests/`;

// Range (Slot) Booking
export const BOOKING_ENDPOINT = `${API_BASE_URL}range/order/create`;
export const BOOKING_LIST_ENDPOINT = `${API_BASE_URL}range/order/list`;

//Participant
export const PARTICIPANT_ENDPOINT = `${API_BASE_URL}participant`;
export const PARTICIPANT_LIST_ENDPOINT = `${API_BASE_URL}participants`;

//Product
export const PRODUCT_LIST_ENDPOINT = `${API_BASE_URL}product/list/`;
export const PRODUCT_ENDPOINT = `${API_BASE_URL}product/`;

//Category
export const CATEGORY_LIST_ENDPOINT = `${API_BASE_URL}category/list`;

//Brand
export const BRAND_LIST_ENDPOINT = `${API_BASE_URL}brand/list`;

// Course
export const COURSE_LIST_ENDPOINT = `${API_BASE_URL}course/list/`;
export const COURSE_ENDPOINT = `${API_BASE_URL}course/`;

// User Profile
export const UPDATE_PROFILE_ENDPOINT = `${API_BASE_URL}profile/update`;
