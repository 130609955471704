import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../Utils/axiosInstance';
import Toast from '../Utils/Toast';
import ButtonRemoveImage from './ButtonRemoveImage';
import { ASSOCIATED_RANGE_ENDPOINT } from '../Utils/Endpoints';
import ButtonLoader from './ButtonLoader';
import Multiselect from 'multiselect-react-dropdown';
import { fetchRange } from '../Redux/features/ranges/rangeSlice';
import { Link } from 'react-router-dom';
import { getItem } from '../Utils/LocalStorage';

const AssociateRangesModal = ({ fetchAssociateRange }) => {
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [option, setOption] = useState([]);
  const dispatch = useDispatch();
  const rangeList = useSelector((state) => state.range.rangeList);
  const rangeId = getItem('rangeId');

  useEffect(() => {
    dispatch(fetchRange());
  }, []);

  useEffect(() => {
    if (rangeList) {
      setOption(
        rangeList.map((range) => ({
          value: range.id,
          name: range.name,
        }))
      );
    }
  }, [rangeList]);

  const onSelect = (option) => {
    setSelectedIds(option);
  };

  const onRemove = (option) => {
    setSelectedIds(option);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setGeneralError('');
      try {
        const filteredArray = selectedIds.map((item) => item.value);
        const response = await axiosInstance.post(
          ASSOCIATED_RANGE_ENDPOINT + rangeId,
          {
            range_ids: filteredArray,
          }
        );
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        const modalClose = document.getElementsByClassName('close-modal-range');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }

        Toast.success(response.data.message, {
          autoClose: 2000,
        });
        await fetchAssociateRange();
      } catch (error) {
        setGeneralError(error.response?.data?.message);
        return;
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setGeneralError('');
    setSelectedIds([]);
  };

  return (
    <>
      <div
        className="modal fade"
        id="associateRanges"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                MANAGE RANGES
              </h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body modal-height ">
                <div className="row">
                  <div className="col-md-6 mb-5">
                    <label htmlFor="inputState" className="form-label">
                      Range
                    </label>
                    <Multiselect
                      isObject={true}
                      options={option}
                      selectedValues={selectedIds}
                      showCheckbox
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                    />
                  </div>
                  {generalError && (
                    <p className="error text-center mt-3">{generalError}</p>
                  )}
                </div>
              </div>
              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create">
                  <ButtonLoader
                    type="submit"
                    bgChange={`white-bg w-pd-add `}
                    active="orange-outline"
                    isLoading={isLoading}
                  >
                    Save
                  </ButtonLoader>
                  <Link
                    to="#"
                    className="close-modal-range"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

AssociateRangesModal.propTypes = {
  fetchAssociateRange: PropTypes.func,
};

export default AssociateRangesModal;
