import React from 'react';
import Header from '../Components/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import TopNav from '../Components/TopNav';

const MainLayout = ({ children, className }) => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <TopNav />
        <div className={`wrapper-body ${className}`}>{children}</div>
        <ToastContainer />
      </div>
    </>
  );
};

MainLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default MainLayout;
