import Label from '../Label';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import AddressAutocomplete from '../AddressAutocomplete';
import Toast from '../../Utils/Toast';
import { addAddress, updateAddress } from '../../Utils/upload';
import PropTypes from 'prop-types';
import ButtonLoader from '../ButtonLoader';
import { RANGE_CLASS } from '../../Utils/ModelClasses';
import { useDispatch, useSelector } from 'react-redux';
import { setCreateAddressRangeData } from '../../Redux/features/ranges/rangeSlice';
import ButtonRemoveImage from '../ButtonRemoveImage';
import { Link } from 'react-router-dom';

// Validation schema
const AddressSchema = Yup.object().shape({
  addressObject: Yup.object().required('Required'),
  latitude: Yup.string().required('Required'),
  longitude: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zipcode: Yup.string().required('Required'),
});

const Address = ({ handleNextStep, addressID, setAddressID }) => {
  const [addressObject, setAddressObject] = useState({});
  const [address, setAddress] = useState({});
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const rangeData = useSelector((state) => state.range.createRange);
  const addressData = useSelector((state) => state.range.createAddressRange);
  const dispatch = useDispatch();

  const validate = () => {
    try {
      AddressSchema.validateSync(
        {
          addressObject,
          latitude,
          longitude,
          city,
          state,
          zipcode,
        },
        { abortEarly: false }
      );

      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });

      setErrors(validationErrors);
      return false;
    }
  };

  useEffect(() => {
    if (Object.keys(addressData).length > 0) {
      setLatitude(addressData?.lat);
      setLongitude(addressData?.lng);
      setState(addressData?.state);
      setCity(addressData?.city);
      setZipcode(addressData?.zip_code);
      setAddress(addressData);
    }
  }, [addressData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      if (rangeData?.id) {
        let requestAddressObj = { ...addressObject };
        requestAddressObj.contact_number = rangeData?.phone_number;
        requestAddressObj.contact_person_name = rangeData?.name;
        if (!addressID) {
          try {
            const addressData = await addAddress(
              RANGE_CLASS,
              rangeData?.id,
              requestAddressObj
            );
            if (addressData.data.code !== 200) {
              setGeneralError(addressData.data.message);
              return;
            }
            setAddressID(addressData.data.data.id);
            dispatch(setCreateAddressRangeData(addressData.data.data));

            Toast.success('Range address added.', {
              autoClose: 2000,
            });
            handleNextStep();
          } catch (error) {
            setGeneralError(error.response?.data?.message);
            return;
          }
        }
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      if (addressData?.id) {
        let requestAddressObj = { ...addressObject };
        requestAddressObj.contact_number = rangeData?.phone_number;
        requestAddressObj.contact_person_name = rangeData?.name;
        try {
          const addressUpdateData = await updateAddress(
            addressData?.id,
            requestAddressObj
          );

          if (addressUpdateData.data.code !== 200) {
            setGeneralError(addressUpdateData.data.message);
            return;
          }
          setAddressID(addressUpdateData.data.data.id);
          dispatch(setCreateAddressRangeData(addressUpdateData.data.data));

          Toast.success('Range address updated.', {
            autoClose: 2000,
          });
          handleNextStep();
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(addressObject)?.length > 0) {
      setLatitude(addressObject?.lat);
      setLongitude(addressObject?.lng);
      setState(addressObject?.state);
      setCity(addressObject?.city);
      setZipcode(addressObject?.zip_code);
    }
  }, [addressObject]);

  return (
    <>
      <div className="employees employ-set">
        <h3 className="card-top-2">Address</h3>
      </div>
      <form
        className="triangle-top"
        onSubmit={addressData?.id ? handleUpdate : handleSubmit}
      >
        <div className="body-text text-start">
          <div className="row">
            <AddressAutocomplete
              handleAddress={setAddressObject}
              addressList={address}
            />
            <div className="col-md-6">
              <Label labelHeading="Latitude:" />
              <div className="tickets">
                <input
                  type="text"
                  className="form-control input-theme"
                  placeholder=""
                  onChange={(e) => setLatitude(e.target.value)}
                  value={latitude}
                />
                {errors.latitude && (
                  <div className="error">{errors.latitude}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <Label labelHeading="Longitude:" />
              <div className="tickets">
                <input
                  type="text"
                  className="form-control input-theme"
                  placeholder=""
                  onChange={(e) => setLongitude(e.target.value)}
                  value={longitude}
                />
                {errors.longitude && (
                  <div className="error">{errors.longitude}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <Label labelHeading="City:" />
              <div className="tickets">
                <input
                  type="text"
                  className="form-control input-theme"
                  placeholder=""
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
                {errors.city && <div className="error">{errors.city}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <Label labelHeading="State:" />
              <div className="tickets">
                <input
                  type="text"
                  className="form-control input-theme"
                  placeholder=""
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                />
                {errors.state && <div className="error">{errors.state}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <Label labelHeading="Zipcode:" />
              <div className="tickets">
                <input
                  type="text"
                  className="form-control input-theme"
                  placeholder=""
                  onChange={(e) => setZipcode(e.target.value)}
                  value={zipcode}
                />
                {errors.zipcode && (
                  <div className="error">{errors.zipcode}</div>
                )}
              </div>
            </div>
            {generalError && (
              <p className="error text-center mt-3">{generalError}</p>
            )}
            <div className="d-flex mt-3 gap-3">
              <ButtonLoader
                type="submit"
                bgChange={`white-bg save-w`}
                active="orange-outline"
                isLoading={isLoading}
              >
                {addressData?.id ? 'Update' : 'Save'}
              </ButtonLoader>

              {addressData?.id && (
                <Link to="#" className="button-width" onClick={handleNextStep}>
                  <ButtonRemoveImage
                    Heading="Next"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add"
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

Address.propTypes = {
  handleNextStep: PropTypes.func,
  addressID: PropTypes.string,
  setAddressID: PropTypes.func,
};
export default Address;
