import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchRange,
  setAssociatedRangeData,
} from '../../Redux/features/ranges/rangeSlice';
import axiosInstance from '../../Utils/axiosInstance';
import Toast from '../../Utils/Toast';
import ButtonLoader from '../ButtonLoader';
import { ASSOCIATED_RANGE_ENDPOINT } from '../../Utils/Endpoints';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../ButtonRemoveImage';

const AssociateRange = ({ handleNextStep }) => {
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const rangeList = useSelector((state) => state.range.rangeList);
  const rangeData = useSelector((state) => state.range.createRange);
  const associatedRangeData = useSelector(
    (state) => state.range.associatedRange
  );
  const [selectedIds, setSelectedIds] = useState([]);
  const [option, setOption] = useState([]);

  useEffect(() => {
    dispatch(fetchRange());
  }, []);

  useEffect(() => {
    if (rangeList) {
      setOption(
        rangeList.map((range) => ({
          value: range.id,
          name: range.name,
        }))
      );
    }
  }, [rangeList]);

  useEffect(() => {
    if (associatedRangeData?.length > 0) {
      setSelectedIds(
        associatedRangeData.map((range) => ({
          value: range.id,
          name: range.name,
        }))
      );
    }
  }, [associatedRangeData]);

  const onSelect = (option) => {
    setSelectedIds(option);
  };

  const onRemove = (option) => {
    setSelectedIds(option);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setGeneralError('');
      if (rangeData?.id) {
        try {
          const filteredArray = selectedIds.map((item) => item.value);
          const response = await axiosInstance.post(
            ASSOCIATED_RANGE_ENDPOINT + rangeData?.id,
            {
              range_ids: filteredArray,
            }
          );
          if (response.data.code !== 200) {
            setGeneralError(response.data.message);
            return;
          }
          dispatch(setAssociatedRangeData(response.data.data));
          Toast.success(response.data.message, {
            autoClose: 2000,
          });
          handleNextStep();
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="employees employ-set">
        <h3 className="card-top-2"> Manage Ranges</h3>
      </div>

      <form className="triangle-top" onSubmit={handleSubmit}>
        <div className="body-text text-start">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="inputState" className="form-label">
                Range
              </label>
              <Multiselect
                isObject={true}
                options={option}
                selectedValues={selectedIds}
                showCheckbox
                onSelect={onSelect}
                onRemove={onRemove}
                displayValue="name"
              />
            </div>
            <div className="col-md-6 mt-4 mt-md-0">
              {/* <Label labelHeading="My Trainers is not on ShotPro ID yet" />
            <div className="form-switch">
              <input className="form-check-input input-theme" type="checkbox" />
            </div> */}
            </div>

            <div className="mb-5 mt-5 d-flex gap-3 align-items-center">
              <div>
                <ButtonLoader
                  type="submit"
                  bgChange={`white-bg delete-w`}
                  active="orange-outline"
                  isLoading={isLoading}
                >
                  Save
                </ButtonLoader>
              </div>
              <Link to="#" className="button-width" onClick={handleNextStep}>
                <ButtonRemoveImage
                  Heading="Skip"
                  active="orange-outline"
                  bgChange="white-bg w-pd-add"
                />
              </Link>
            </div>
          </div>
          {generalError && (
            <p className="error text-center mt-1">{generalError}</p>
          )}
        </div>
      </form>
    </>
  );
};

AssociateRange.propTypes = {
  handleNextStep: PropTypes.func,
};

export default AssociateRange;
