import React from 'react';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import HexagonSize from '../Components/HexagonSize';
import ButtonAdd from '../Components/ButtonAdd';

const Experience = () => {
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set pt-4 pt-md-1">
                  <div className="back-listing-tab">
                    <Link to="/membership-two" className="button-width pp">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2 exp-h">Experience Add-ons</h3>
                  </div>
                  <div className="all-date-selected mt-3  new-back-btn-add">
                    <Link
                      to="/membership-two"
                      className="button-width b-p expe-2"
                    >
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <div className="experience-sel">
                      <div className="select-input tickets pb-3 pb-md-0">
                        <select className="form-select mb-0">
                          <option selected>All Select</option>
                          <option value="Ammo">Ammo</option>
                          <option value="Eye Protection">Eye Protection</option>
                          <option value="Ammo">Ammo</option>
                          <option value="Hearing Protection">
                            Hearing Protection
                          </option>
                        </select>
                      </div>
                      <div className="input-search-items tickets pb-3 pb-md-0">
                        <input
                          type="search"
                          className="form-control input-theme mb-0"
                          placeholder="Search..."
                        />
                        <img src="../assets/icon/search_icon.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text experience-add">
                    <div className="row">
                      <div className="col-xl-8">
                        <div className="exp-add">
                          <div className="exp-add-listing">
                            <ul>
                              <li className="exp-active">
                                <p className="exp-add-listing_name-image">
                                  <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                  Ankle boots
                                </p>
                                <p>$70.00</p>
                              </li>
                              <li>
                                <p className="exp-add-listing_name-image">
                                  <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                  Ankle boots
                                </p>
                                <p>$70.00</p>
                              </li>
                              <li>
                                <p className="exp-add-listing_name-image">
                                  <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                  Ankle boots
                                </p>
                                <p>$70.00</p>
                              </li>
                              <li>
                                <p className="exp-add-listing_name-image">
                                  <HexagonSize imageUser="../assets/images/Headshot.jpg" />
                                  Ankle boots
                                </p>
                                <p>$70.00</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="reservation-details mb-3">
                          <h4 className="r-d">Reservation Details</h4>
                          <div className="date-select-user pb-0">
                            <h4>
                              {/* <img
                                src="../assets/icon/boking_calendar.svg"
                                alt=""
                              /> */}
                              Selected Date :
                            </h4>
                            <p>September 6th 2024</p>
                          </div>
                          <div className="date-select-user pt-1">
                            <h4>
                              {/* <img src="../assets/icon/time.svg" alt="" /> */}
                              Start Time :
                            </h4>
                            <p>6:00pm</p>
                          </div>
                          <p className="par">
                            {/* <img src="../assets/icon/Icon-M.svg" alt="" /> */}
                            Participants
                          </p>
                          <div className="boj">
                            <p>Bob Johnson</p>
                            <img src="../assets/icon/Icon-M.svg" alt="" />
                          </div>
                          <div className="boj boj-2">
                            <p>Jill Junover</p>
                            <img src="../assets/icon/Icon-G.svg" alt="" />
                          </div>
                          <div className="date-select-user">
                            <h4>
                              {/* <img src="../assets/icon/setting.svg" alt="" /> */}
                              Firearm Selection
                            </h4>
                          </div>
                          <div className="table-responsive w-100">
                            <table className="table t-modal-no">
                              <thead>
                                <tr>
                                  <th className="f-modal">Manufacturer</th>
                                  <th>Model</th>
                                  <th className="l-modal">S/N</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-start">Sig Sauer</td>
                                  <td>123342892</td>
                                  <td className="text-end">P229</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="listing-firearm d-none">
                            <ul>
                              <li>Manufacturer</li>
                              <li>Model</li>
                              <li>S/N</li>
                            </ul>
                          </div>
                          <div className="date-select-user d-none">
                            <h4>Sig Sauer 123342892</h4>
                            <p>P229</p>
                          </div>
                          <div className="date-select-user">
                            <h4>
                              {/* <img src="../assets/icon/target.svg" alt="" /> */}
                              Experience Add-Ons
                            </h4>
                          </div>
                        </div>
                        <Link to="/ranges/order-details">
                          <ButtonRemoveImage
                            Heading="Check Out"
                            bgChange="bg-orange"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
