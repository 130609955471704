import React, { useState } from 'react';
import Label from '../Components/Label';
import Multiselect from 'multiselect-react-dropdown';
import { Link } from 'react-router-dom';
import ButtonAdd from '../Components/ButtonAdd';
import FPEQuestions from '../Components/FPEQuestions';
import OptionModal from '../Components/OptionModal';

const ValidationProcess = () => {
  const [option] = useState([
    'Golden Gun Club',
    "Stoddard's Range and Guns - Atlanta Midtown",
    "Stoddard's Range and Guns - Douglasville",
  ]);
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body ">
                <div className="employees employ-set">
                  <h3 className="card-top-2 ">FPE VALIDATION PROCESS</h3>
                  <div className="all-date-selected">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#FPEQuestions"
                      className="button-width"
                    >
                      <ButtonAdd
                        Heading="ADD"
                        images="../assets/icon/plaus_icon.svg"
                        active="orange-outline"
                        bgChange="white-bg w-pd-add"
                      />
                    </Link>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="">
                          <Label labelHeading="Choose your firearm" />
                          <div className="select-input tickets">
                            <select className="form-select mb-0">
                              <option selected>Select Firearm</option>
                              <option value="HandGun">HandGun</option>
                              <option value=" Rifle">Rifle</option>
                              <option value="Revolver">Revolver</option>
                            </select>
                          </div>
                        </div>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#OptionModal"
                          className="link-firearm"
                        >
                          Create a new Firearm
                        </Link>
                      </div>
                      <div className="col-md-6">
                        <h4 className="qu">Create Questionnaire</h4>
                        <div className="mt-3">
                          <Label labelHeading="What's Your Shooting Skill Level?" />
                          <div className="select-input tickets">
                            <select className="form-select mb-0">
                              <option selected>Select Skill Level</option>
                              <option value="Novice/Never Ever">
                                Novice/Never Ever
                              </option>
                              <option value="Beginner/In-training">
                                Beginner/In-training
                              </option>
                              <option value="Proficient/Intermediate">
                                Proficient/Intermediate
                              </option>
                              <option value="Advanced/Specialist">
                                Advanced/Specialist
                              </option>
                              <option value="Professional">Professional</option>
                            </select>
                          </div>
                        </div>
                        <div className="mt-3">
                          <Label labelHeading="Are you Law Enforcement or a First Responder?" />
                          <div className="select-input tickets">
                            <select className="form-select mb-0">
                              <option selected>First Responder</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="mt-3">
                          <Label labelHeading="Ranges" />

                          <Multiselect
                            isObject={false}
                            options={option}
                            // showCheckbox
                          />
                        </div>
                        <div className="mt-3">
                          <Label labelHeading="Are you shooting with us today?" />
                          <div className="select-input tickets">
                            <select className="form-select mb-0">
                              <option selected>
                                Yes,I&lsquo;m ready to get on the range{' '}
                              </option>
                              <option value="No,just browsing">
                                No,just browsing
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mt-3 d-none">
                        <div className="">
                          <Label labelHeading="What are you training with today?" />
                          <Link to=""></Link>
                        </div>
                        <div className="examination">
                          <div className="table-responsive w-100">
                            <table className="table table-time-gun-create mobile-responsive mobile table-w-d">
                              <thead>
                                <tr>
                                  <th>Gun Image</th>
                                  <th>Gun Name</th>
                                </tr>
                              </thead>
                              <tbody className="table-time-gun-create__old-even mobile__old-even">
                                <tr>
                                  <td>
                                    <img
                                      src="../assets/icon/handgun.svg"
                                      alt=""
                                    />
                                  </td>
                                  <td data-label="CPE Type">Handgun</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OptionModal />
      <FPEQuestions />
    </>
  );
};

export default ValidationProcess;
