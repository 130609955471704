import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import Label from '../Components/Label';
import Multiselect from 'multiselect-react-dropdown';
import ButtonAdd from '../Components/ButtonAdd';

const EventsNew = () => {
  const [option] = useState([
    'Golden Gun Club',
    "Stoddard's Range and Guns - Atlanta Midtown",
    "Stoddard's Range and Guns - Douglasville",
  ]);
  const [optionList] = useState([
    'Marc Tillery',
    'Amr Whitmore',
    'Andy Smith',
    'Jennifer Jones',
    'Don Julio',
    'Mary Tanner',
    'Will Turner',
    'Skylar Thomas',
    'John Dobson',
    'Nancy Walker',
    'Bill Speaker',
    'Mandy Lee',
  ]);

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <ul
                  className="nav nav-event-pro mt-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item  first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress bg-active">
                          1
                        </div>
                      </div>
                      <p className="pro-active-color">General</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">2</div>
                      </div>

                      <p>Ranges</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">3</div>
                      </div>
                      <p>Instructors</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-two-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-two"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">4</div>
                      </div>
                      <p>Tickets</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-one-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-one"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">5</div>
                      </div>
                      <p>Review</p>
                    </div>
                  </li>
                </ul>
                <div className="employees employ-set pt-4 pt-md-1">
                  <div className="back-listing-tab">
                    <Link to="/upcoming/events" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2">Edit Event</h3>
                  </div>
                  <div className="all-date-selected mobile-b-set new-back-btn-add">
                    <Link to="/upcoming/events" className="button-width b-p">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <Link to="#" className="button-width">
                      <ButtonRemoveImage
                        Heading="NEXT"
                        active="orange-outline"
                        bgChange="white-bg w-pd-add"
                      />
                    </Link>
                    <Link to="/upcoming/events" className="button-width d-none">
                      <ButtonRemoveImage
                        Heading="Back"
                        active="gary-light-outline"
                        bgChange="gary-bg w-pd-add"
                      />
                    </Link>
                  </div>
                </div>
                <form className="triangle-top">
                  <div className="body-text text-start">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <Label labelHeading="Event Name" />
                            <div className="tickets">
                              <input
                                type="email"
                                className="form-control input-theme"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <Label labelHeading="Event Description" />
                            <div className="tickets-2">
                              <textarea
                                className="form-control input-theme"
                                rows="3"
                                placeholder=""
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <Label labelHeading="Event Date" />

                            <div className="date-input tickets">
                              <input
                                type="date"
                                className="form-control input-theme"
                                placeholder="dd-mm-yyyy"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <Label labelHeading="Multi Day Event" />
                            <div className="form-switch">
                              <input
                                className="form-check-input input-theme"
                                type="checkbox"
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <Label labelHeading="Event End Date" />

                            <div className="date-input tickets">
                              <input
                                type="date"
                                className="form-control input-theme"
                                placeholder="dd-mm-yyyy"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Label labelHeading="Event Start Time" />
                            <div className="row">
                              <div className="col-md-4">
                                <div className="tickets">
                                  <select
                                    id="inputState"
                                    className="form-select input-theme"
                                  >
                                    <option selected>HH</option>
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="tickets">
                                  <select
                                    id="inputState"
                                    className="form-select input-theme"
                                  >
                                    <option selected>MM</option>
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                    <option>13</option>
                                    <option>14</option>
                                    <option>15</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                    <option>20</option>
                                    <option>21</option>
                                    <option>22</option>
                                    <option>23</option>
                                    <option>24</option>
                                    <option>25</option>
                                    <option>26</option>
                                    <option>27</option>
                                    <option>28</option>
                                    <option>29</option>
                                    <option>30</option>
                                    <option>31</option>
                                    <option>32</option>
                                    <option>33</option>
                                    <option>34</option>
                                    <option>35</option>
                                    <option>36</option>
                                    <option>37</option>
                                    <option>38</option>
                                    <option>39</option>
                                    <option>40</option>
                                    <option>41</option>
                                    <option>42</option>
                                    <option>43</option>
                                    <option>44</option>
                                    <option>45</option>
                                    <option>46</option>
                                    <option>47</option>
                                    <option>48</option>
                                    <option>49</option>
                                    <option>50</option>
                                    <option>51</option>
                                    <option>52</option>
                                    <option>53</option>
                                    <option>54</option>
                                    <option>55</option>
                                    <option>56</option>
                                    <option>57</option>
                                    <option>58</option>
                                    <option>59</option>
                                    <option>60</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="tickets">
                                  <select
                                    id="inputState"
                                    className="form-select input-theme"
                                  >
                                    <option selected>AM</option>

                                    <option>PM</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Label labelHeading="Event End Time" />
                            <div className="row">
                              <div className="col-md-4">
                                <div className="tickets">
                                  <select
                                    id="inputState"
                                    className="form-select input-theme"
                                  >
                                    <option selected>HH</option>
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="tickets">
                                  <select
                                    id="inputState"
                                    className="form-select input-theme"
                                  >
                                    <option selected>MM</option>
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                    <option>13</option>
                                    <option>14</option>
                                    <option>15</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                    <option>20</option>
                                    <option>21</option>
                                    <option>22</option>
                                    <option>23</option>
                                    <option>24</option>
                                    <option>25</option>
                                    <option>26</option>
                                    <option>27</option>
                                    <option>28</option>
                                    <option>29</option>
                                    <option>30</option>
                                    <option>31</option>
                                    <option>32</option>
                                    <option>33</option>
                                    <option>34</option>
                                    <option>35</option>
                                    <option>36</option>
                                    <option>37</option>
                                    <option>38</option>
                                    <option>39</option>
                                    <option>40</option>
                                    <option>41</option>
                                    <option>42</option>
                                    <option>43</option>
                                    <option>44</option>
                                    <option>45</option>
                                    <option>46</option>
                                    <option>47</option>
                                    <option>48</option>
                                    <option>49</option>
                                    <option>50</option>
                                    <option>51</option>
                                    <option>52</option>
                                    <option>53</option>
                                    <option>54</option>
                                    <option>55</option>
                                    <option>56</option>
                                    <option>57</option>
                                    <option>58</option>
                                    <option>59</option>
                                    <option>60</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="tickets">
                                  <select
                                    id="inputState"
                                    className="form-select input-theme"
                                  >
                                    <option selected>AM</option>

                                    <option>PM</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Label labelHeading="Event Door Open" />
                            <div className="row">
                              <div className="col-md-4">
                                <div className="tickets">
                                  <select
                                    id="inputState"
                                    className="form-select input-theme"
                                  >
                                    <option selected>HH</option>
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="tickets">
                                  <select
                                    id="inputState"
                                    className="form-select input-theme"
                                  >
                                    <option selected>MM</option>
                                    <option>01</option>
                                    <option>02</option>
                                    <option>03</option>
                                    <option>04</option>
                                    <option>05</option>
                                    <option>06</option>
                                    <option>07</option>
                                    <option>08</option>
                                    <option>09</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                    <option>13</option>
                                    <option>14</option>
                                    <option>15</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                    <option>20</option>
                                    <option>21</option>
                                    <option>22</option>
                                    <option>23</option>
                                    <option>24</option>
                                    <option>25</option>
                                    <option>26</option>
                                    <option>27</option>
                                    <option>28</option>
                                    <option>29</option>
                                    <option>30</option>
                                    <option>31</option>
                                    <option>32</option>
                                    <option>33</option>
                                    <option>34</option>
                                    <option>35</option>
                                    <option>36</option>
                                    <option>37</option>
                                    <option>38</option>
                                    <option>39</option>
                                    <option>40</option>
                                    <option>41</option>
                                    <option>42</option>
                                    <option>43</option>
                                    <option>44</option>
                                    <option>45</option>
                                    <option>46</option>
                                    <option>47</option>
                                    <option>48</option>
                                    <option>49</option>
                                    <option>50</option>
                                    <option>51</option>
                                    <option>52</option>
                                    <option>53</option>
                                    <option>54</option>
                                    <option>55</option>
                                    <option>56</option>
                                    <option>57</option>
                                    <option>58</option>
                                    <option>59</option>
                                    <option>60</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="tickets">
                                  <select
                                    id="inputState"
                                    className="form-select input-theme"
                                  >
                                    <option selected>AM</option>

                                    <option>PM</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="name" className="label-heading">
                              Public Event
                            </label>
                            <div className="form-switch">
                              <input
                                className="form-check-input input-theme"
                                type="checkbox"
                              />
                            </div>
                          </div>
                          <div className="col-md-5 mb-3">
                            <div className="button-wrapper">
                              <span className="label-input">
                                <strong>Event Image</strong>
                                Selected files are not actually uploaded.
                                Recommended Size 1920 X 700
                                {/* <HexagonSize hexOuterLine="bg-hox" imageUser="../assets/images/user-1.jpeg" hexBg="bg-black  bg-org m-auto" /> */}
                              </span>
                              <input
                                type="file"
                                name="upload"
                                id="upload"
                                className="upload-box input-theme"
                                placeholder="Upload File2"
                              />
                            </div>
                          </div>
                          <div className="col-md-7 mb-3">
                            <div className="button-wrapper">
                              <span className="label-input">
                                <strong>Event Title Image</strong>
                                Selected files are not actually uploaded.
                                Recommended Size 327 X 311
                                {/* <HexagonSize hexOuterLine="bg-hox" imageUser="../assets/images/user-1.jpeg" hexBg="bg-black  bg-org m-auto" /> */}
                              </span>
                              <input
                                type="file"
                                name="upload"
                                id="upload"
                                className="upload-box"
                                placeholder="Upload File2"
                              />
                            </div>
                          </div>
                          <Link
                            to="#"
                            style={{
                              width: '150px',
                            }}
                          >
                            <ButtonRemoveImage
                              Heading="SAVE"
                              active="orange-outline"
                              bgChange="white-bg w-pd-add"
                            />
                          </Link>
                          <Link
                            to="#"
                            style={{
                              width: '150px',
                            }}
                            className="d-block d-md-none"
                          >
                            <ButtonRemoveImage
                              Heading="Back"
                              active="gary-light-outline"
                              bgChange="gary-bg"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-profile">
                        <div className="row">
                          <div className="col-md-6">
                            <Label labelHeading="Ranges" />

                            <Multiselect
                              isObject={false}
                              options={option}
                              // showCheckbox
                            />
                          </div>
                          <div className="col-md-6"></div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-contact">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="inputState" className="form-label">
                              Instructors
                            </label>
                            {/* <div className="tickets">
                            <select
                              id="inputState"
                              className="form-select input-theme"
                            >
                              <option selected>Select Range</option>
                              <option>Golden Gun Club</option>
                              <option>
                                Stoddard's Range and Guns- Atlanta Midtown
                              </option>
                              <option>
                                Stoddard's Range and Guns - Douglasville
                              </option>
                            </select>
                          </div> */}
                            <Multiselect
                              isObject={false}
                              options={optionList}
                              // showCheckbox
                            />
                          </div>
                          <div className="col-md-6 mt-4 mt-md-0">
                            <Label labelHeading="My Instructor is not on ShotPro ID yet" />
                            <div className="form-switch">
                              <input
                                className="form-check-input input-theme"
                                type="checkbox"
                              />
                            </div>
                          </div>

                          <div
                            className="mb-5 mt-4"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            style={{ maxWidth: '300px', width: '100%' }}
                          >
                            <ButtonRemoveImage
                              Heading="ADD NEW INSTRUCTOR"
                              active="orange-outline"
                              bgChange="white-bg py-2"
                            />
                          </div>

                          {/* <div className="col-lg-2 ">
                                                    <Link to="#">
                                                        <ButtonRemoveImage Heading="BACK" active="gary-light-outline mt-3 mt-md-0" bgChange="gary-bg  " />
                                                    </Link>

                                                </div> */}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-two">
                        <div className="row">
                          <div className="col-md-6">
                            <Label labelHeading="This Performance is" />
                            <div className="tickets">
                              <select
                                id="inputState"
                                className="form-select input-theme"
                              >
                                <option selected>Paid with tickets</option>
                                <option>Golden Gun Club</option>
                                <option>
                                  Stoddard&apos;s Range and Guns- Atlanta
                                  Midtown
                                </option>
                                <option>
                                  Stoddard&apos;s Range and Guns - Douglasville
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Label labelHeading="Paid with Tickets" />
                            <div className="tickets">
                              <select
                                id="inputState"
                                className="form-select input-theme"
                              >
                                <option selected>
                                  I am selling tickets on ShotPro ID
                                </option>
                                <option>Golden Gun Club</option>
                                <option>
                                  Stoddard&apos;s Range and Guns- Atlanta
                                  Midtown
                                </option>
                                <option>
                                  Stoddard&apos;s Range and Guns - Douglasville
                                </option>
                              </select>
                            </div>
                          </div>
                          <Label labelHeading="This event uses a seating chart for tickets" />
                          <div className="d-flex align-items-center justify-content-between flex-wrap py-2">
                            <div className="list-check">
                              <div className="form-check">
                                <input
                                  className="form-check-input out-line-check"
                                  type="radio"
                                  name="click"
                                  id="checkFor"
                                  checked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="checkFor"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input out-line-check"
                                  type="radio"
                                  name="click"
                                  id="checkedFor"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="checkedFor"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                            <div className="mb-4" style={{ width: '200px' }}>
                              <ButtonRemoveImage
                                Heading="ADD TICKETS"
                                active="orange-outline"
                                bgChange="white-bg"
                              />
                            </div>
                          </div>
                          <div className="tickets-pay">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="input-search tickets">
                                  <input
                                    type="text"
                                    className="form-control input-theme"
                                    placeholder="Ticket Name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="tickets">
                                  <input
                                    type="number"
                                    className="form-control input-theme"
                                    placeholder="Ticket Price"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="tickets">
                                  <input
                                    type="number"
                                    className="form-control input-theme"
                                    placeholder="Ticket Quantity"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <Label labelHeading="Event Terms of Use" />
                            <div className="tickets-2">
                              <textarea
                                className="form-control input-theme "
                                rows="3"
                                placeholder=""
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <Label labelHeading="ID Title" />

                            <div className="input-search tickets">
                              <input
                                type="text"
                                className="form-control input-theme"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <Label labelHeading="ID Description" />
                            <div className="tickets-2">
                              <textarea
                                className="form-control input-theme "
                                rows="3"
                                placeholder=""
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-5 mb-4">
                            <div className="button-wrapper">
                              <span className="label-input">
                                <strong>Drop Your ID Here</strong>
                                Selected files are not actually uploaded.
                                {/* <HexagonSize
                                hexOuterLine="bg-hox"
                                imageUser="../assets/images/user-1.jpeg"
                                hexBg="bg-black  bg-org m-auto"
                              /> */}
                              </span>
                              <input
                                type="file"
                                name="upload"
                                id="upload"
                                className="upload-box"
                                placeholder="Upload File2"
                              />
                            </div>
                          </div>
                          <div className="col-md-7"></div>
                          <div className="col-md-12">
                            <div className="form-check mb-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="gridCheck"
                              >
                                I agree that I have the right to create this
                                event.
                              </label>
                            </div>
                          </div>
                          {/* <div className="col-md-2 mb-4">
                                                    <Link to="#">
                                                        <ButtonRemoveImage Heading="BACK" active="gary-light-outline" bgChange="gary-bg" />
                                                    </Link>
                                                </div> */}
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-one">
                        <div className="row">
                          <div className="col-md-6">
                            <table className="table table-time-gun-create">
                              <tbody className="table-time-gun-create__old-even user-all-plan-select">
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Event Name</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>Sports for Aim</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Event Description</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>Sports for Aim</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Event Date</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>28 Sep, 2023</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Multi Day Event</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>Yes</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Event End Date</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>30 Sep, 2023</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Event Start Time</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>01:00 AM</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Event End Time</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>01:00 PM</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Event Door Open</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>01:00 AM</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Public Event</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>Public</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>ID Title</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>Sports for Aim</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>ID Description</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>Sports for Aim</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-6">
                            <table className="table table-time-gun-create">
                              <tbody className="table-time-gun-create__old-even user-all-plan-select">
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Event Logo</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>
                                    <img
                                      src="../assets/icon/Icon-M.svg"
                                      alt=""
                                      style={{ width: '43px' }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Event Title Image</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>
                                    <img
                                      src="../assets/icon/shotgun.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Ranges</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>
                                    Stoddard&apos;s Range and Guns - Atlanta
                                    Midtown,Stoddard&apos;s Range and Guns -
                                    Douglasville
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Instructors</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>
                                    Amy Whitmore,Jennifer Jones,Nancy Walker
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Performance Ticket Type</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>Paid with tickets</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Paid with Tickets</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>PickleJar Live</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span> seating chart for tickets</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>Yes</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Event Terms of Use</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>Sports for Aim</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <Link
                            to="#"
                            style={{ width: '150px', marginTop: '20px' }}
                          >
                            <ButtonRemoveImage
                              Heading="SAVE"
                              active="orange-outline"
                              bgChange="white-bg w-pd-add"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content modal-website">
                  <div className="modal-body">
                    <form action="">
                      <div className="row gy-3">
                        <div className="col-md-6">
                          <div className="input-search tickets">
                            <input
                              type="text"
                              className="form-control input-theme mb-0"
                              placeholder="User Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-search tickets">
                            <input
                              type="text"
                              className="form-control input-theme mb-0"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-search tickets">
                            <input
                              type="text"
                              className="form-control input-theme mb-0"
                              placeholder="Instructor Website"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-search tickets">
                            <input
                              type="Email"
                              className="form-control input-theme mb-0"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-search tickets">
                            <input
                              type="phone"
                              className="form-control input-theme mb-0"
                              placeholder="Phone"
                            />
                          </div>
                        </div>
                        <Label labelHeading="Home Town" />
                        <div className="col-md-6">
                          <div className="input-search tickets">
                            <input
                              type="city"
                              className="form-control input-theme mb-0"
                              placeholder="City"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="tickets">
                            <select id="inputState" className="form-select">
                              <option selected>State</option>
                              <option>India</option>
                              <option>Pakistan</option>
                              <option>Nepal</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer border-0 justify-content-center">
                    <Link to="#" data-bs-dismiss="modal">
                      <ButtonRemoveImage
                        Heading="CANCEL"
                        active="gary-light-outline"
                        bgChange="gary-bg w-pd-add"
                      />
                    </Link>
                    <ButtonRemoveImage Heading="SAVE" bgChange="w-pd-add" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsNew;
