import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Calendar = ({ selectedDate, setSelectedDate }) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const [month, setMonth] = useState(
    currentDate.toLocaleString('en-US', { month: 'long' })
  );
  const [year, setYear] = useState(currentDate.getFullYear());
  const [days, setDays] = useState([]);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const getDaysInMonth = (month, year) => {
    const date = new Date(year, months.indexOf(month), 1);
    const daysInMonth = [];
    while (date.getMonth() === months.indexOf(month)) {
      daysInMonth.push(date.getDate());
      date.setDate(date.getDate() + 1);
    }
    return daysInMonth;
  };

  const handlePrevMonth = () => {
    const prevMonthIndex = months.indexOf(month) - 1;
    if (
      prevMonthIndex >= 0 &&
      (year > currentYear ||
        (year === currentYear && prevMonthIndex >= currentMonth))
    ) {
      setMonth(months[prevMonthIndex]);
    } else if (prevMonthIndex < 0 && year > currentYear) {
      setMonth(months[11]);
      setYear(year - 1);
    }
  };

  const handleNextMonth = () => {
    const nextMonthIndex = months.indexOf(month) + 1;
    if (nextMonthIndex > 11) {
      setMonth(months[0]);
      setYear(year + 1);
    } else {
      setMonth(months[nextMonthIndex]);
    }
  };

  const handleDateSelect = (date) => {
    const selectedDateObj = new Date(year, months.indexOf(month), date);

    // Compare only the date part (ignoring time)
    if (
      selectedDateObj.getFullYear() > currentYear ||
      (selectedDateObj.getFullYear() === currentYear &&
        selectedDateObj.getMonth() > currentMonth) ||
      (selectedDateObj.getFullYear() === currentYear &&
        selectedDateObj.getMonth() === currentMonth &&
        selectedDateObj.getDate() >= currentDay)
    ) {
      const formattedDate = `${year}-${String(months.indexOf(month) + 1).padStart(2, '0')}-${String(date).padStart(2, '0')}`;
      setSelectedDate(formattedDate);
    }
  };

  useEffect(() => {
    setDays(getDaysInMonth(month, year));
  }, [month, year]);

  const getFirstDayOfMonth = (month, year) => {
    const date = new Date(year, months.indexOf(month), 1);
    return date.getDay();
  };

  return (
    <div className="row align-items-center">
      <div className="col-lg-5">
        <ul className="month-select">
          <li>
            <span>
              <i
                className="fa fa-angle-left"
                aria-hidden="true"
                onClick={handlePrevMonth}
                style={{
                  color:
                    (year === currentYear &&
                      months.indexOf(month) === currentMonth) ||
                    year < currentYear
                      ? 'grey'
                      : '#ea7b44',
                  cursor:
                    (year === currentYear &&
                      months.indexOf(month) === currentMonth) ||
                    year < currentYear
                      ? 'not-allowed'
                      : 'pointer',
                }}
              />
            </span>
            <p className="m-y-option">
              {' '}
              {month} {year}
            </p>
            <span>
              <i
                className="fa fa-angle-right"
                aria-hidden="true"
                onClick={handleNextMonth}
              />
            </span>
          </li>
        </ul>
      </div>
      <div className="col-lg-7">
        <div className="calender-list mt-0">
          <table className="calendar">
            <thead>
              <tr>
                <th className="ps-1">Sun</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
              </tr>
            </thead>
            <tbody>
              {Array(6)
                .fill(0)
                .map((_, weekIndex) => {
                  const week = Array(7)
                    .fill(0)
                    .map((_, dayIndex) => {
                      const day =
                        weekIndex * 7 +
                        dayIndex -
                        getFirstDayOfMonth(month, year) +
                        1;
                      if (day > 0 && day <= days.length) {
                        const isPastDate =
                          new Date(year, months.indexOf(month), day) <
                          new Date(currentYear, currentMonth, currentDay);
                        return (
                          <td
                            key={dayIndex}
                            onClick={() => !isPastDate && handleDateSelect(day)}
                            className={
                              selectedDate ===
                              `${year}-${String(months.indexOf(month) + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`
                                ? 'active'
                                : isPastDate
                                  ? 'disabled-c'
                                  : ''
                            }
                          >
                            {day}
                          </td>
                        );
                      } else {
                        return <td key={dayIndex} />;
                      }
                    });

                  // Only render the row if it has at least one valid day
                  if (week.some((cell) => cell.props.children)) {
                    return <tr key={weekIndex}>{week}</tr>;
                  }

                  return null;
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

Calendar.propTypes = {
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
};

export default Calendar;
