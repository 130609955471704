// src/components/ButtonLoader.js

import React from 'react';
import PropTypes from 'prop-types';

const ButtonLoader = ({ isLoading, disabled, children, ...props }) => (
  <div className={`custom-action-button  ${props.active}`}>
    <button
      {...props}
      disabled={disabled ? disabled : isLoading}
      className={` ${props.bgChange ? props.bgChange : 'button-with-loader'}`}
    >
      {isLoading ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          className="spinner"
        >
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          />
        </svg>
      ) : (
        children
      )}
    </button>
  </div>
);
ButtonLoader.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  bgChange: PropTypes.string,
  active: PropTypes.string,
};
export default ButtonLoader;
