import React, { useEffect, useState } from 'react';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import { Link } from 'react-router-dom';
import Label from '../Components/Label';
import Logger from '../Utils/Logger';
import RangeNotFound from '../Components/RangeNotFound';
import SkeletonLoader from '../Components/SkeletonLoader';
import Pagination from '../Components/Pagination';
import { BOOKING_LIST_ENDPOINT } from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import moment from 'moment';
import { getItem } from '../Utils/LocalStorage';

const Orders = () => {
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');

  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const rangeId = getItem('rangeId');

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchOrderList();
  }, [search, currentPage, itemsPerPage, date]);

  const fetchOrderList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        BOOKING_LIST_ENDPOINT +
          `/${rangeId}?page=${currentPage}&per_page=${itemsPerPage}&search=${search}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setOrderList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">All Orders</h3>
                  <div className="all-date-selected">
                    <div className="date-input tickets">
                      <input
                        type="date"
                        className="form-control input-theme mb-0"
                        placeholder="date"
                        onChange={(e) => {
                          setDate(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={date}
                      />
                    </div>
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={search}
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      {orderList?.length === 0 && !isLoading && (
                        <RangeNotFound title="Orders" isInfo={true} />
                      )}
                      {isLoading && <SkeletonLoader />}
                      {orderList?.length > 0 && (
                        <div className="col-md-12">
                          <div className="table-responsive w-100">
                            <table className="table table-time-gun-create mobile-responsive">
                              <thead>
                                <tr>
                                  <th>ORDER</th>
                                  <th>DATE</th>
                                  <th>FPE TYPE</th>
                                  <th>WAIVER</th>
                                  <th>TOTAL</th>
                                  <th>STATUS</th>
                                  <th>RECEIPT</th>
                                </tr>
                              </thead>
                              <tbody className="table-time-gun-create__old-even">
                                {orderList?.map((item, index) => (
                                  <tr key={index}>
                                    <td data-label="Order">
                                      <Link
                                        to="/store/product-order-details"
                                        state={{
                                          order: item,
                                        }}
                                        className="fw-bold"
                                      >
                                        <p>#{item?.order_number}</p>
                                      </Link>
                                    </td>
                                    <td data-label="Date">
                                      <p>
                                        {moment(item?.created_at).format(
                                          'DD MMM, YYYY h:mm A'
                                        )}
                                      </p>
                                    </td>
                                    <td data-label="Fpe Type">
                                      {/* <p>
                                        {item?.transactions &&
                                        item?.transactions[0]?.payment_mode ===
                                          'COD'
                                          ? 'CASH'
                                          : item?.transactions[0]?.payment_mode}
                                      </p> */}
                                      <p>123</p>
                                    </td>
                                    <td data-label="Waiver">
                                      {/* <p>
                                        {item?.transactions &&
                                        item?.transactions[0]?.payment_mode ===
                                          'COD'
                                          ? 'CASH'
                                          : item?.transactions[0]?.payment_mode}
                                      </p> */}
                                      <p>Pending</p>
                                    </td>
                                    <td data-label="Total">
                                      <p>
                                        $
                                        {item?.transactions &&
                                          item?.transactions[0]?.amount?.toFixed(
                                            2
                                          )}
                                      </p>
                                    </td>
                                    <td data-label="Status">
                                      <span>
                                        <img
                                          src="../assets/images/box-check.png"
                                          alt="box-check"
                                          className="size-cover-check-box"
                                        />
                                        {item?.order_status?.identifier}
                                      </span>
                                    </td>
                                    {/* <td data-label="Actions">
                                      <span
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        className="color-theme car-ser"
                                      >
                                        <img
                                          src="../assets/icon/eyes.png"
                                          alt="eyes"
                                          className="size-cover-check-box"
                                        />
                                        View
                                      </span>
                                    </td> */}
                                    <td data-label="Receipt">
                                      <img
                                        src="../assets/icon/view-eye.svg"
                                        alt=""
                                        className="edit-icon-w me-2"
                                      />
                                      <Link to="/order/receiptsnew">
                                        <img
                                          src="../assets/icon/download_icon.svg"
                                          alt=""
                                          className="icon-w "
                                        />
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                    {orderList?.length > 0 && (
                      <Pagination
                        setItemsPerPage={setItemsPerPage}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content modal-order-bg">
            <div className="modal-header">
              <h5 className="modal-title order-title">Order #45900</h5>
            </div>
            <div className="modal-body py-2 modal-height">
              <div className="table-responsive w-100">
                <table className="table table-time-gun-create">
                  <tbody className="table-time-gun-create__old-even">
                    <tr>
                      <td>
                        <p className="color-black">Billing details</p>
                      </td>
                      <td>
                        <p>Note</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="color-black">Product</p>
                      </td>
                      <td>
                        <p>DAKA® Pouch, Medium</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="color-black">Quantity</p>
                      </td>
                      <td>
                        <p>1</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="color-black">Email</p>
                      </td>
                      <td>
                        <Link to="email to:7ourabh@gmail.com">
                          7ourabh@gmail.com
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="color-black">Phone</p>
                      </td>
                      <td>
                        <p>+91 9877781112</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="color-black">Payment via</p>
                      </td>
                      <td>
                        <p>Card</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="color-black">Total</p>
                      </td>
                      <td>
                        <p>$22.95</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="color-black">Status</p>
                      </td>
                      <td>
                        <div className="select-input tickets mb-3">
                          <select className="form-select mb-0">
                            <option selected>Select Status</option>
                            <option value="1">Pending Payment</option>
                            <option value="2">Processing</option>
                            <option value="3">Completed</option>
                            <option value="3">Cancelled</option>
                            <option value="3">Refunded</option>
                            <option value="3">Failed</option>
                            <option value="3">Ready To Pick Up</option>
                          </select>
                        </div>
                        <Label labelHeading="Pickup Date" />
                        <div className="date-input tickets mb-3">
                          <input
                            type="date"
                            className="form-control mb-0"
                            placeholder="dd-mm-yyyy"
                            required
                          />
                        </div>
                        <Label labelHeading="Pickup Time" />
                        <div className="date-input tickets">
                          <input
                            type="time"
                            className="form-control mb-0"
                            placeholder="--:--"
                            required
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer justify-content-center border-top-0">
              <Link to="#">
                <ButtonRemoveImage
                  Heading="EDIT"
                  active="orange-outline"
                  bgChange="white-bg w-pd-add"
                />
              </Link>
              <Link to="" data-bs-dismiss="modal">
                <ButtonRemoveImage
                  Heading="Cancel"
                  active="gary-light-outline"
                  bgChange="gary-bg w-pd-add"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
