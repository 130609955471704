import React from 'react';
import PropTypes from 'prop-types';

const Label = (props) => {
  return (
    <>
      <label
        htmlFor="inputState"
        className={` form-label label-heading ${props.addCss}`}
      >
        {props.labelHeading}
      </label>
    </>
  );
};

Label.propTypes = {
  props: PropTypes.object,
  addCss: PropTypes.string,
  labelHeading: PropTypes.string,
};
export default Label;
