import React, { useEffect, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BOOKING_LIST_ENDPOINT } from '../Utils/Endpoints';
import Logger from '../Utils/Logger';
import RangeNotFound from '../Components/RangeNotFound';
import SkeletonLoader from '../Components/SkeletonLoader';
import axiosInstance from '../Utils/axiosInstance';
import Pagination from '../Components/Pagination';
import Calendar from '../Components/Calendar';
import moment from 'moment';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import HexLine from '../Components/HexLine';
import HexagonSize from '../Components/HexagonSize';
import { getItem } from '../Utils/LocalStorage';

const UpComing = () => {
  const [bookedList, setBookedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
  );
  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBooking, setSelectedBooking] = useState({});
  const rangeId = getItem('rangeId');

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchBookedList();
  }, [selectedDate, currentPage, itemsPerPage]);

  const fetchBookedList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        BOOKING_LIST_ENDPOINT +
          `/${rangeId}?order_item_type=LANE_BOOKING&page=${currentPage}&per_page=${itemsPerPage}&booking_from=${selectedDate}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setBookedList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="outer-border outer-overflow-show">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center mt-0 mt-lg-5">
            <Calendar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
            <div className="hr-color-changes"></div>
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees">
                  <h3 className="card-top-2">Upcoming</h3>
                </div>
                <div className="triangle-top">
                  <div className="body-text up-qq">
                    <div className="table-responsive w-100">
                      {bookedList?.length === 0 && !isLoading && (
                        <RangeNotFound title="Lane Booking" isInfo={true} />
                      )}
                      {isLoading && <SkeletonLoader />}
                      {bookedList?.length > 0 && !isLoading && (
                        <table className="table table-time-gun-create mobile-responsive">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Booking</th>
                              <th>Slot Details</th>
                              <th>Membership Type</th>
                              <th>Check-In</th>
                              <th>Booking Details</th>
                            </tr>
                          </thead>
                          <tbody className="table-time-gun-create__old-even ">
                            {bookedList?.map((item, index) => (
                              <tr key={index}>
                                <td data-label="Name" className="t-m-b">
                                  {item?.user?.name}
                                </td>
                                <td data-label="Booking">
                                  <img
                                    src="../assets/icon/id_verification.svg"
                                    alt=""
                                  />
                                  <p className="color-black-fw">ON-RANGE</p>
                                </td>
                                <td
                                  data-label="Slot Details"
                                  className="table-button-width"
                                >
                                  <p>
                                    {item?.order_items &&
                                      moment
                                        .utc(
                                          item?.order_items[0]?.bookings[0]
                                            .fromSlotDateTime
                                        )
                                        .format('MMM D, YYYY ')}
                                  </p>
                                  <p>
                                    {item?.order_items &&
                                      moment
                                        .utc(
                                          item?.order_items[0]?.bookings[0]
                                            .fromSlotDateTime
                                        )
                                        .format('h:mm A - ')}
                                    {item?.order_items &&
                                      moment
                                        .utc(
                                          item?.order_items[0]?.bookings[0]
                                            .toSlotDateTime
                                        )
                                        .format('h:mm A')}
                                  </p>
                                </td>
                                <td data-label="Membership Type">
                                  <img
                                    src="../assets/icon/Icon-G.svg"
                                    alt=""
                                    style={{ width: '43px' }}
                                  />
                                </td>
                                {/* <td
                                  data-label="Account Status"
                                  className="table-button-width"
                                >
                                  <ButtonRemoveImage
                                    Heading="APPROVED"
                                    bgChange="success-bg-color success-bg-fill"
                                    active="success-outline"
                                  />
                                </td> */}
                                <td
                                  data-label="Check-In"
                                  className="table-button-width"
                                >
                                  <label
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    className="display"
                                    onClick={() => setSelectedBooking(item)}
                                  >
                                    <ButtonRemoveImage
                                      Heading="PENDING"
                                      bgChange="bg-orange"
                                    />
                                  </label>
                                </td>
                                <td data-label="Booking Details">
                                  <span className="color-black">RECEIPT</span>
                                  <img
                                    src="../assets/icon/download_icon.svg"
                                    alt="download_icon"
                                    style={{
                                      width: '9px',
                                      marginLeft: '5px',
                                    }}
                                  />
                                  <p>
                                    {moment(item.created_at).format(
                                      'MMMM D, YYYY h:mm A '
                                    )}
                                  </p>
                                </td>
                              </tr>
                            ))}
                            {/* <tr>
                              <td data-label="Name">AJAY</td>
                              <td data-label="Date">27/07/97</td>
                              <td data-label="Time">23:00</td>
                              <td data-label="Max-Size">22</td>
                              <td data-label="Location">Fazilka</td>
                              <td data-label="Booking">No</td>
                            </tr> */}
                          </tbody>
                        </table>
                      )}
                      {bookedList?.length > 0 && !isLoading && (
                        <Pagination
                          itemsPerPage={itemsPerPage}
                          totalItems={totalItems}
                          currentPage={currentPage}
                          onPageChange={handlePageChange}
                          setItemsPerPage={setItemsPerPage}
                        />
                      )}
                    </div>
                    {/* Modal */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content modal-table">
                          <div className="modal-header table-header pb-0">
                            <div aria-label="Close" data-bs-dismiss="modal">
                              <HexLine
                                hexBg="bg-bo-li-th"
                                hexOuterLine="bg-dark-bc"
                                imageUserTwo="../assets/icon/cross_icon.svg"
                              />
                            </div>
                            <h5 className="modal-title" id="exampleModalLabel">
                              LANE CHECK-IN
                            </h5>
                          </div>
                          <div className="modal-body py-0">
                            <div className="user-check ">
                              <div className="user-check__head">
                                <img
                                  src="../assets/icon/Icon-G.svg"
                                  alt=""
                                  className="mt-4"
                                  style={{ width: '50px' }}
                                />
                                <div className="user-check__id-list">
                                  <div className="user-check__img-name user-check__line-mob">
                                    <HexagonSize
                                      hexOuterLine="bg-trans"
                                      imageUser={
                                        selectedBooking?.user?.profile_picture
                                          ? selectedBooking?.user
                                              .profile_picture?.fullUrl
                                          : '../assets/images/profile.png'
                                      }
                                      hexBg="bg-black bg-org "
                                    />
                                    <div className="user-check__title">
                                      <h2>{selectedBooking?.user?.name}</h2>
                                      <p>
                                        {selectedBooking?.order_items &&
                                          selectedBooking?.order_items[0]
                                            ?.bookings[0].booking_participants
                                            .length}{' '}
                                        Participants
                                      </p>
                                    </div>
                                  </div>
                                  <div className="user-check__name ">
                                    <div className="user-check__month">
                                      <div className="user-check__out-line-right">
                                        <div className="pro-hex ">
                                          <div className="bg-gray"></div>
                                        </div>
                                      </div>
                                      <div className="user-check__cst">
                                        <h4>Check-In</h4>
                                        <p>January 1, 2023 10:33 AM CST</p>
                                        <div className="d-none d-md-block">
                                          <ButtonRemoveImage
                                            Heading="CHECK-IN"
                                            active="success-outline"
                                            bgChange="bg-dark-gray-success"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="user-check__month">
                                      <div className="pro-hex">
                                        <div className="bg-gray"></div>
                                      </div>
                                      <div className="user-check__cst">
                                        <h4>Check-Out</h4>
                                        <p>January 1, 2023 10:33 AM CST</p>
                                        <div className="d-block d-md-none">
                                          <ButtonRemoveImage
                                            Heading="CHECK-IN"
                                            active="success-outline"
                                            bgChange="bg-dark-gray-success"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpComing;
