import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonAdd from '../Components/ButtonAdd';
import FPEQuestions from '../Components/FPEQuestions';
import { ADD_QUESTION_PROFICIENCY_ENDPOINT } from '../Utils/Endpoints';
import { getItem } from '../Utils/LocalStorage';
import axiosInstance from '../Utils/axiosInstance';
import Logger from '../Utils/Logger';
import RangeNotFound from '../Components/RangeNotFound';
import SkeletonLoader from '../Components/SkeletonLoader';
import DeleteModal from '../Components/DeleteModal';

const CreateQuestions = () => {
  const [questionAnswerList, setQuestionAnswerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const proficiencyId = getItem('proficiencyId');
  const [selectedData, setSelectedData] = useState({});
  const [selectedQuestionAnswer, setSelectedQuestionAnswer] = useState({});

  useEffect(() => {
    fetchQuestionAnswerList();
  }, []);

  const fetchQuestionAnswerList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ADD_QUESTION_PROFICIENCY_ENDPOINT + proficiencyId
      );
      if (response.data.code === 200) {
        setQuestionAnswerList(response.data.data.questions);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (id, type) => {
    setSelectedData({
      id,
      type,
    });
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <div className="back-listing-tab">
                    <Link to="/ranges/manage" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2">Create Questionnaire</h3>
                  </div>
                  <div className="all-date-selected mt-3 new-back-btn-add">
                    <Link to="/ranges/manage" className="button-width b-p">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#FPEQuestions"
                      className="button-width"
                    >
                      <ButtonAdd
                        Heading="ADD"
                        images="../assets/icon/plaus_icon.svg"
                        active="orange-outline"
                        bgChange="white-bg w-pd-add"
                      />
                    </Link>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text pt-0">
                    <div className="qqq-card">
                      {questionAnswerList?.length > 0 && (
                        <>
                          {questionAnswerList.map((item, index) => (
                            <div className="qq-user mt-3" key={index}>
                              <div className="h-e-d">
                                <h4 className="q-h">{item.question}</h4>
                                <div className="qu-edit-det">
                                  <img
                                    src="../assets/icon/edit.svg"
                                    alt="edit"
                                    data-bs-toggle="modal"
                                    data-bs-target="#FPEQuestions"
                                    onClick={() =>
                                      setSelectedQuestionAnswer(item)
                                    }
                                  />
                                  <img
                                    src="../assets/icon/delete.svg"
                                    alt="delete"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                    onClick={() =>
                                      handleDelete(
                                        item.id,
                                        'proficiency_question'
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <ol>
                                {item?.answers?.map((ans, ind) => (
                                  <li key={ind}> {ans.answer}</li>
                                ))}
                              </ol>
                            </div>
                          ))}
                        </>
                      )}
                      {isLoading && <SkeletonLoader />}
                    </div>
                    {questionAnswerList?.length === 0 && !isLoading && (
                      <RangeNotFound title="Questionnaire" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <DeleteModal
              selectedData={selectedData}
              handleUpdateList={fetchQuestionAnswerList}
            />
            <FPEQuestions
              fetchQuestionAnswerList={fetchQuestionAnswerList}
              setSelectedQuestionAnswer={setSelectedQuestionAnswer}
              selectedQuestionAnswer={selectedQuestionAnswer}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuestions;
