import React from 'react';
import PropTypes from 'prop-types';

const HexagonSize = (props) => {
  return (
    <>
      <div className={` box-bg ${props.hexBg}`}>
        <div className={`bg-hox ${props.hexOuterLine}`}>
          <img
            src={props.imageUser}
            alt=""
            className={` img-size imag-hox ${props.imgSize}`}
          />
        </div>
      </div>
    </>
  );
};

HexagonSize.propTypes = {
  props: PropTypes.object,
  hexBg: PropTypes.string,
  hexOuterLine: PropTypes.string,
  imageUser: PropTypes.string,
  imgSize: PropTypes.string,
};

export default HexagonSize;
