import React, { useState } from 'react';
import Label from '../Components/Label';
import RichTextEditor from '../Components/RichTextEditor';
import * as Yup from 'yup';
import ButtonLoader from '../Components/ButtonLoader';

// Validation schema
const CategorySchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  slug: Yup.string('')
    .required('Required')
    .matches(
      /^[a-z0-9-]+$/,
      'Slug must be all lowercase, letters, numbers, and hyphens only'
    ),
});

const EventCategories = () => {
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validate = () => {
    try {
      CategorySchema.validateSync(
        {
          name,
          description,
          slug,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">Add Event Categories</h3>
                  <div className="all-date-selected">
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      <div className="col-md-5">
                        <form onSubmit={handleSubmit}>
                          <div className="category-search">
                            <Label labelHeading="Name" />
                            <div className="tickets">
                              <input
                                type="text"
                                className="form-control input-theme"
                                placeholder=""
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                              />
                              {errors.name && (
                                <div className="error">{errors.name}</div>
                              )}
                            </div>
                            <Label labelHeading="Slug" />
                            <div className="tickets">
                              <input
                                type="text"
                                className="form-control input-theme"
                                placeholder=""
                                onChange={(e) => setSlug(e.target.value)}
                                value={slug}
                              />
                            </div>
                            <Label labelHeading="The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens." />
                            {errors.slug && (
                              <div className="error">{errors.slug}</div>
                            )}

                            <Label labelHeading="Description" addCss="mt-2" />
                            <div className="tickets-2">
                              <RichTextEditor
                                value={description}
                                setEditorData={setDescription}
                                index="description"
                              />
                              {errors.description && (
                                <div className="error">
                                  {errors.description}
                                </div>
                              )}
                            </div>

                            {generalError && (
                              <p className="error text-center mt-3">
                                {generalError}
                              </p>
                            )}

                            <div className="d-flex mt-4">
                              <ButtonLoader
                                type="submit"
                                bgChange="bg-orange w-pd-add save-w"
                                isLoading={isLoading}
                              >
                                Save
                              </ButtonLoader>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-md-7">
                        <div className="table-responsive w-100 mt-3 mb-md-0">
                          <table className="table table-time-gun-create mobile-responsive">
                            <thead>
                              <tr>
                                <th>NAME</th>
                                <th>DESCRIPTION</th>
                                <th>SLUG</th>
                                <th>COUNT</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            <tbody className="table-time-gun-create__old-even">
                              <tr>
                                <td data-label="Name">1</td>
                                <td data-label="Description">2</td>
                                <td data-label="Slugs">No Category Found</td>
                                <td data-label="Count">3</td>
                                <td data-label="Action">4</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCategories;
