// src/Utils/axiosInstance.js
import axios from 'axios';
import Logger from './Logger';
import { getObject, logoutUser } from './LocalStorage'; // Import getObject from your local storage utils
import Toast from './Toast'; // Import the Toast helper

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  headers: (config) => {
    if (config.data instanceof FormData) {
      return {};
    } else {
      return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
    }
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getObject('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    Logger.log(`Request: ${config.method.toUpperCase()} ${config.url}`, config);
    return config;
  },
  (error) => {
    Logger.error('Request Error:', error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    Logger.log(`Response from: ${response.config.url}`, response);
    return response;
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;
      Logger.error(
        `Response Error from: ${error.response.config.url}`,
        error.response
      );

      // Handle specific status codes
      switch (status) {
        case 401:
          Toast.error('Unauthorized access. Please log in again.');
          break;
        case 404:
          Toast.error('Resource not found.');
          break;
        case 403:
          Toast.error('Unauthorized access. Please log in again.');
          logoutUser();
          break;
        case 422:
          if (data.errors) {
            Object.values(data.errors).forEach((errorMessages) => {
              if (typeof errorMessages !== 'string') {
                errorMessages.forEach((message) => {
                  Toast.error(message);
                });
              } else {
                Toast.error(errorMessages);
              }
            });
          } else {
            Toast.error('Validation error.');
          }
          break;
        case 500:
          Toast.error('Internal server error. Please try again later.');
          break;
        default:
          Toast.error(data.message || 'An error occurred. Please try again.');
      }
    } else {
      Logger.error('Response Error:', error);
      Toast.error('Network error. Please check your connection.');
    }
    return Promise.reject(error);
  }
);

// Delete method to support request body
axiosInstance.deleteWithBody = (url, data, config = {}) => {
  return axiosInstance.delete(url, { data, ...config });
};

export default axiosInstance;
