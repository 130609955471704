export const GENERAL_TYPE = 1;
export const USER_PROFILE_PICTURE_TYPE = 2;
export const USER_GOVT_ID_FRONT_TYPE = 3;
export const USER_GOVT_ID_BACK_TYPE = 4;
export const RANGE_LOGO_TYPE = 5;
export const RANGE_BANNER_TYPE = 6;
export const RANGE_GALLERY_TYPE = 7;
export const PROFICIENCY_LOGO_TYPE = 8;
export const BAY_BANNER_TYPE = 9;
export const USER_SMARTWAIVER_TYPE = 10;
export const CATEGORY_IMAGE_TYPE = 11;
export const BRAND_IMAGE_TYPE = 12;
export const PRODUCT_BANNER_TYPE = 13;
export const PRODUCT_GALLERY_TYPE = 14;
export const COURSE_BANNER_TYPE = 15;
export const COURSE_GALLERY_TYPE = 16;
