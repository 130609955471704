import React from 'react';
import PropTypes from 'prop-types';

const AssociateRangesCard = (props) => {
  return (
    <>
      <div
        className="card user-card mt-2 triangle-card line-card"
        key={props.key}
      >
        {/* <div className="hex hex-size black-bg">
                    <img src={props.UserImages} />
                </div> */}
        <div className={` box-bg m-auto ${props.hexBg}`}>
          <div className={`bg-hox ${props.hexOuterLine}`}>
            <img
              src={props.UserImages}
              alt=""
              className={` img-size ${props.imgSize}`}
            />
          </div>
        </div>

        <div className="card-body-2">
          <div
            className="text-danger fw-bolder d-img-c"
            data-bs-toggle="modal"
            data-bs-target="#deleteModal"
            onClick={() =>
              props.handleDelete(
                props.associateRangeData.associated_range_id,
                'associate_range'
              )
            }
          >
            <img src="../assets/icon/delete.svg" alt="" className="ddd-card" />
          </div>
          <h4 className="pt-2">{props.RangeName}</h4>
          <h5 className="py-2 d-none">{props.userEmail}</h5>
          <p className="">{props.Address}</p>
          <h6 className="d-none">{props.Experience}</h6>
          {/* <img src="../assets/images/three-gun.png" alt="" className="img-fluid gun" /> */}
          <div className="gun-bg d-none">
            <div className="practice-gun">
              <img src="../assets/icon/handgun_white.svg" alt="" />
            </div>
            <div className="practice-gun">
              <img src="../assets/icon/rifle_white.svg" alt="" />
            </div>
            <div className="practice-gun">
              <img src="../assets/icon/shotgun_white.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AssociateRangesCard.propTypes = {
  props: PropTypes.object,
  key: PropTypes.number,
  hexBg: PropTypes.string,
  hexOuterLine: PropTypes.string,
  UserImages: PropTypes.string,
  RangeName: PropTypes.string,
  deleteImages: PropTypes.string,
  Address: PropTypes.string,
  imgSize: PropTypes.string,
  userEmail: PropTypes.string,
  Experience: PropTypes.string,
  handleDelete: PropTypes.func,
  associateRangeData: PropTypes.object,
};

export default AssociateRangesCard;
