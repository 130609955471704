import React, { useState } from 'react';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import { Link } from 'react-router-dom';
import axiosInstance from '../Utils/axiosInstance';
import {
  ADD_BAY_ENDPOINT,
  ADD_LANE_ENDPOINT,
  ADD_QUESTION_PROFICIENCY_ENDPOINT,
  ADD_TRAINER_ENDPOINT,
  ASSOCIATED_RANGE_DELETE_ENDPOINT,
  ASSOCIATED_TRAINER_DELETE_ENDPOINT,
  PARTICIPANT_ENDPOINT,
  PRODUCT_ENDPOINT,
  RANGE_ENDPOINT,
  RANGE_OPERATION_HOUR_DELETE_ENDPOINT,
  RANGE_SOCIAL_LINK_DELETE_ENDPOINT,
  REMOVE_ENDPOINT,
} from '../Utils/Endpoints';
import Toast from '../Utils/Toast';
import ButtonLoader from './ButtonLoader';
import { getItem } from '../Utils/LocalStorage';
import PropTypes from 'prop-types';

const DeleteModal = ({ selectedData, handleUpdateList }) => {
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const rangeId = getItem('rangeId');
  const bayData = getItem('bayData');

  const proficiencyId = getItem('proficiencyId');

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setGeneralError('');
      let endpoint = '';
      let object = {};
      switch (selectedData.type) {
        case 'range_list':
          endpoint = RANGE_ENDPOINT + '/' + selectedData.id;
          break;
        case 'social_link':
          endpoint = RANGE_SOCIAL_LINK_DELETE_ENDPOINT + selectedData.id;
          break;
        case 'operation_hour':
          endpoint = RANGE_OPERATION_HOUR_DELETE_ENDPOINT + selectedData.id;
          break;
        case 'associate_range':
          endpoint = ASSOCIATED_RANGE_DELETE_ENDPOINT + rangeId;
          object = {
            range_ids: [selectedData.id],
          };
          break;
        case 'firearm':
          // Call delete image
          if (selectedData.imageId) {
            await axiosInstance.delete(REMOVE_ENDPOINT + selectedData.imageId);
          }
          endpoint = ADD_QUESTION_PROFICIENCY_ENDPOINT + selectedData.id;
          break;
        case 'trainer':
          endpoint = ASSOCIATED_TRAINER_DELETE_ENDPOINT + rangeId;
          object = {
            trainer_ids: [selectedData.id],
          };
          break;
        case 'manage_bay':
          endpoint = ADD_BAY_ENDPOINT + `${selectedData.id}/` + rangeId;
          break;
        case 'manage_lane':
          endpoint = ADD_LANE_ENDPOINT + `${selectedData.id}/` + bayData?.id;
          break;
        case 'instructor':
          endpoint = ADD_TRAINER_ENDPOINT + `/${selectedData.id}`;
          break;
        case 'product_list':
          endpoint = PRODUCT_ENDPOINT + `${selectedData.id}/` + rangeId;
          break;
        case 'participant_list':
          endpoint = PARTICIPANT_ENDPOINT + `/${selectedData.id}`;
          break;
        case 'proficiency_question':
          endpoint =
            ADD_QUESTION_PROFICIENCY_ENDPOINT +
            proficiencyId +
            '/question/' +
            selectedData.id;
          break;
        default:
          throw new Error(`Unsupported type: ${selectedData.type}`);
      }

      const response = await axiosInstance.deleteWithBody(endpoint, object);

      if (response.status === 204) {
        const modalClose =
          document.getElementsByClassName('close-delete-modal');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        if (
          selectedData.type === 'proficiency_question' ||
          selectedData.type === 'range_list' ||
          selectedData.type === 'manage_bay' ||
          selectedData.type === 'instructor'
        ) {
          handleUpdateList();
        } else {
          handleUpdateList(selectedData.id, 'DELETE');
        }

        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        data-bs-backdrop="static"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content delete-fill-detail">
            <div className="modal-body id-file">
              <h2>Are you sure?</h2>
              <p>
                Do you really want to delete this record? This process cannot be
                undone.
              </p>
            </div>
            {generalError && <p className="error">{generalError}</p>}
            <div className="modal-footer border-0 justify-content-center">
              <Link to="#" onClick={handleDelete}>
                <ButtonLoader
                  bgChange="white-bg w-pd-add delete-w "
                  isLoading={isLoading}
                >
                  DELETE
                </ButtonLoader>
              </Link>
              <Link
                to=""
                data-bs-dismiss="modal"
                className="close-delete-modal"
                onClick={() => setGeneralError('')}
              >
                <ButtonRemoveImage
                  Heading="CANCEL"
                  active="gary-light-outline"
                  bgChange="gary-bg w-pd-add"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DeleteModal.propTypes = {
  selectedData: PropTypes.func,
  handleUpdateList: PropTypes.func,
};

export default DeleteModal;
