import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import ButtonAdd from '../Components/ButtonAdd';
import CreateSlots from '../Components/CreateSlots';
import Logger from '../Utils/Logger';
import axiosInstance from '../Utils/axiosInstance';
import { LANE_SLOT_ENDPOINT } from '../Utils/Endpoints';
import SkeletonLoader from '../Components/SkeletonLoader';
import RangeNotFound from '../Components/RangeNotFound';
import { getItem } from '../Utils/LocalStorage';
import { formatDate } from '../Utils/common';
import { Link } from 'react-router-dom';
import ButtonAdd from '../Components/ButtonAdd';

const Availability = () => {
  const [availabilityList, setAvailabilityList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(formatDate(new Date()));
  const laneId = getItem('laneId');
  const bayData = getItem('bayData');

  useEffect(() => {
    fetchAvailability();
  }, [date]);

  const fetchAvailability = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        LANE_SLOT_ENDPOINT + laneId + `?date=${date}`
      );
      if (response.data.code === 200) {
        setAvailabilityList(response?.data?.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <div className="back-listing-tab">
                    <Link to="/ranges/manage-lanes" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2">Availability</h3>
                  </div>
                  <div className="all-date-selected new-back-btn-add">
                    <Link
                      to="/ranges/manage-lanes"
                      className="button-width b-p"
                    >
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <div className="date-input tickets">
                      <input
                        type="date"
                        className="form-control input-theme mb-0"
                        placeholder="date"
                        min={new Date().toISOString().split('T')[0]}
                        onChange={(e) => setDate(e.target.value)}
                        value={date}
                      />
                    </div>
                    {/* <Link
                      to="#"
                      className="button-width"
                      data-bs-toggle="modal"
                      data-bs-target="#CreateSlots"
                    >
                      <ButtonAdd
                        images="../assets/icon/plaus_icon.svg"
                        Heading="ADD NEW"
                        active="orange-outline"
                        bgChange="white-bg"
                      />
                    </Link> */}
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="table-responsive w-100">
                      {availabilityList?.length === 0 && !isLoading && (
                        <RangeNotFound title="Slot" />
                      )}

                      {isLoading && <SkeletonLoader />}
                      {availabilityList?.length > 0 && !isLoading && (
                        <table className="table table-time-gun-create mobile-responsive mobile">
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Date</th>
                              <th>Slot Start Time</th>
                              <th>Slot End Time</th>
                              <th>Status</th>
                              <th>Guest Cost</th>
                              <th>Member Cost</th>
                              {/* <th>Action</th> */}
                            </tr>
                          </thead>
                          <tbody className="table-time-gun-create__old-even mobile__old-even">
                            {availabilityList?.map((item, index) => (
                              <tr key={index}>
                                <td data-label="ID">{index + 1}</td>
                                <td data-label="date">{item.date}</td>
                                <td data-label="Slot Start Time">
                                  {item.start}
                                </td>
                                <td data-label="Slot End Time">{item.end}</td>
                                <td data-label="Status" className="color-theme">
                                  {!item.booked ? 'instock' : 'out of stock'}
                                </td>
                                <td data-label="Price" className="color-black">
                                  ${bayData?.base_reservation_cost}
                                </td>
                                <td data-label="Price" className="color-black">
                                  ${bayData?.member_reservation_cost}
                                </td>
                                {/* <td data-label="Price" className="color-black">
                                  $2.00
                                </td>
                                <td data-label="Action">
                                  <button className="t-delete">
                                    <img
                                      src="../assets/icon/delete.svg"
                                      alt=""
                                    />
                                  </button>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateSlots />
      </div>
    </>
  );
};

export default Availability;
