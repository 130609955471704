import React from 'react';
import PropTypes from 'prop-types';

const HexLine = (props) => {
  return (
    <>
      <div className={` box-bg-2 ${props.hexBg}`}>
        <div className={` bg-hox-2 ${props.hexOuterLine}`}>
          <img
            src={props.imageUserTwo}
            alt=""
            className={`img-size-2 ${props.imgSizeTwo}`}
          />
        </div>
      </div>
    </>
  );
};

HexLine.propTypes = {
  props: PropTypes.object,
  hexBg: PropTypes.string,
  hexOuterLine: PropTypes.string,
  imageUserTwo: PropTypes.string,
  imgSizeTwo: PropTypes.string,
};
export default HexLine;
