import React from 'react';
import HexLine from './HexLine';
import PropTypes from 'prop-types';

const ProgressItems = (props) => {
  return (
    <>
      <div className="class-users">
        <div className="progress-border">
          <HexLine
            imageUserTwo={props.classImage}
            imgSizeTwo="size-cover-2 clip"
            hexOuterLine="bg-line-p"
          />
        </div>
        <p>{props.className}</p>
        <h3>{props.classList}</h3>
      </div>
    </>
  );
};

ProgressItems.propTypes = {
  props: PropTypes.object,
  classImage: PropTypes.string,
  className: PropTypes.string,
  classList: PropTypes.string,
};
export default ProgressItems;
