import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import Label from '../Components/Label';
import Address from '../Components/RangeOperation/Address';
import ModalLaneInput from '../Components/ModalLaneInput';
// import OperationHoursModal from '../Components/OperationHoursModal';
import CreateRange from '../Components/RangeOperation/CreateRange';
import AssociateRange from '../Components/RangeOperation/AssociateRange';
import OperationHours from '../Components/RangeOperation/OperationHours';
import Banner from '../Components/RangeOperation/Banner';
import SocialLink from '../Components/RangeOperation/SocialLink';
import Trainers from '../Components/RangeOperation/Trainers';

const CreateRangeForm = () => {
  const [rangeID, setRangeID] = useState(null);
  const [addressID, setAddressID] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);

      if (activeStep + 1 > 3) {
        const tabContainer = document.getElementById('pills-tab');
        const activeTab = tabContainer.querySelector(
          `li:nth-child(${activeStep + 2})`
        );
        activeTab.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
        setTimeout(() => {
          tabContainer.scrollTop = activeTab.offsetTop;
          tabContainer.scrollBy(activeTab.offsetTop + 100, 0);
        }, 100);
      }
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    const tabContainer = document.getElementById('pills-tab');
    const activeTab = tabContainer.querySelector(`li:nth-child(${step + 1})`);
    activeTab.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
    setTimeout(() => {
      tabContainer.scrollTop = activeTab.offsetTop;
      tabContainer.scrollBy(0, activeTab.offsetTop);
    }, 100);
  };

  const steps = [
    {
      id: 'general',
      label: 'General',
      Component: (
        <CreateRange
          rangeID={rangeID}
          setRangeID={setRangeID}
          handleNextStep={handleNextStep}
        />
      ),
    },
    {
      id: 'address',
      label: 'Address',
      Component: (
        <Address
          rangeID={rangeID}
          setAddressID={setAddressID}
          addressID={addressID}
          handleNextStep={handleNextStep}
        />
      ),
    },
    {
      id: 'banner',
      label: 'Banner',
      Component: (
        <Banner
          handleNextStep={handleNextStep}
          imageID={imageID}
          setImageID={setImageID}
        />
      ),
    },
    {
      id: 'associate',
      label: 'Manage Ranges',
      Component: <AssociateRange handleNextStep={handleNextStep} />,
    },
    {
      id: 'instructors',
      label: 'Instructors',
      Component: <Trainers handleNextStep={handleNextStep} />,
    },
    {
      id: 'operation-hour',
      label: 'Operation Hours',
      Component: <OperationHours handleNextStep={handleNextStep} />,
    },
    {
      id: 'social',
      label: 'Social Link',
      Component: <SocialLink />,
    },
  ];

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div>
                  <ul
                    className="nav nav-event-pro mt-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    {steps.map((step, index) => (
                      <li
                        className={
                          'nav-item first-user-2 ' +
                          (activeStep - 1 >= index ? 'nav-active-dash' : '')
                        }
                        role="presentation"
                        key={index}
                      >
                        <div
                          className={activeStep >= index ? 'active ' : ''}
                          id={`pills-${step.id}-tab`}
                          // data-bs-toggle="pill"
                          // data-bs-target={`#pills-${step.id}`}
                          onClick={
                            // () => handleStepChange(index)
                            activeStep >= index
                              ? () => handleStepChange(index)
                              : null
                          }
                        >
                          <div className="pro-hex">
                            <div
                              className={`bg-gray bg-white-progress ${activeStep >= index ? 'bg-active' : ''}`}
                            >
                              {index + 1}
                            </div>
                          </div>
                          <p
                            className={
                              activeStep >= index ? 'pro-active-color' : ''
                            }
                          >
                            {step.label}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div className="tab-content" id="pills-tabContent">
                    {steps.map((step, index) => (
                      <div
                        className={`tab-pane fade ${activeStep === index ? 'show active' : ''}`}
                        id={`pills-${step.id}`}
                        key={index}
                      >
                        {activeStep === index && step.Component}
                      </div>
                    ))}
                  </div>
                </div>

                {/* <ul
                  className="nav nav-event-pro mt-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item  first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress bg-active">
                          1
                        </div>
                      </div>
                      <p className="pro-active-color">General</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">2</div>
                      </div>

                      <p>Address</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-two-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-two"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">3</div>
                      </div>
                      <p>Manage Bay&lsquo;s</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-one-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-one"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">4</div>
                      </div>
                      <p>Associate Range</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">5</div>
                      </div>
                      <p>Trainers</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-one-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-six"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">6</div>
                      </div>
                      <p>Operation Hours</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-one-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-seven"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">7</div>
                      </div>
                      <p>Social Link</p>
                    </div>
                  </li>
                  <li className="nav-item first-user-2" role="presentation">
                    <div
                      className=""
                      id="pills-one-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-eight"
                    >
                      <div className="pro-hex">
                        <div className="bg-gray bg-white-progress">8</div>
                      </div>
                      <p>FPE</p>
                    </div>
                  </li>
                </ul> */}
                {/* <div className="employees employ-set pt-4 pt-md-1 d-none">
                  <h3 className="card-top-2">Edit Event</h3>
                  <div className="all-date-selected mobile-b-set">
                    <Link to="#" className="button-width">
                      <ButtonRemoveImage
                        Heading="NEXT"
                        active="orange-outline"
                        bgChange="white-bg w-pd-add"
                      />
                    </Link>
                    <Link to="/upcoming/events" className="button-width">
                      <ButtonRemoveImage
                        Heading="Back"
                        active="gary-light-outline"
                        bgChange="gary-bg w-pd-add"
                      />
                    </Link>
                  </div>
                </div> */}
                {/* <div className="tab-content" id="pills-tabContent"> */}
                {/* <div className="tab-pane fade show active" id="pills-home">
                    <div className="employees employ-set">
                      <h3 className="card-top-2">General</h3>
                      <div className="all-date-selected mobile-b-set d-none">
                        <Link to="#" className="button-width">
                          <ButtonRemoveImage
                            Heading="NEXT"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                        <Link to="/upcoming/events" className="button-width">
                          <ButtonRemoveImage
                            Heading="Back"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                    <CreateRange rangeID={rangeID} setRangeID={setRangeID} />
                  </div> */}
                {/* <div className="tab-pane fade" id="pills-profile">
                    <div className="employees employ-set">
                      <h3 className="card-top-2">Address</h3>
                      <div className="all-date-selected mobile-b-set d-none">
                        <Link to="#" className="button-width">
                          <ButtonRemoveImage
                            Heading="NEXT"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                        <Link to="/upcoming/events" className="button-width">
                          <ButtonRemoveImage
                            Heading="Back"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                  </div> */}
                {/* <div className="tab-pane fade" id="pills-contact">
                    <div className="employees employ-set">
                      <h3 className="card-top-2">Trainers</h3>
                      <div className="all-date-selected mobile-b-set d-none">
                        <Link to="#" className="button-width">
                          <ButtonRemoveImage
                            Heading="NEXT"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                        <Link to="/upcoming/events" className="button-width">
                          <ButtonRemoveImage
                            Heading="Back"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                  </div> */}
                {/* <div className="tab-pane fade" id="pills-two">
                    <div className="employees employ-set">
                      <h3 className="card-top-2">Manage Bay&#39;s</h3>
                      <div className="all-date-selected mobile-b-set ">
                        <Link
                          to="#"
                          className="button-width"
                          data-bs-toggle="modal"
                          data-bs-target="#modalLane"
                        >
                          <ButtonAdd
                            Heading="ADD"
                            images="../assets/icon/plaus_icon.svg"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                        <Link
                          to="/upcoming/events"
                          className="button-width d-none"
                        >
                          <ButtonRemoveImage
                            Heading="Back"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                    <form action="" className="triangle-top">
                      <div className="body-text   text-start">
                        <div className="row">
                          <div className="create-box">
                            <MangeBay
                              lanesImages="../assets/icon/edit.svg"
                              lanesImagesDelete="../assets/icon/delete.svg"
                              bayHeading="Atlanta Bay 1"
                              lanes="Lanes: 8"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> */}
                {/* <div className="tab-pane fade" id="pills-one">
                    <div className="employees employ-set">
                      <h3 className="card-top-2">Associate Range</h3>
                      <div className="all-date-selected mobile-b-set d-none">
                        <Link to="#" className="button-width">
                          <ButtonAdd
                            Heading="ADD"
                            images="../assets/icon/plaus_icon.svg"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                        <Link
                          to="/upcoming/events"
                          className="button-width  d-none"
                        >
                          <ButtonRemoveImage
                            Heading="Back"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                  </div> */}
                {/* <div className="tab-pane fade" id="pills-six">
                    <div className="employees employ-set">
                      <h3 className="card-top-2">Operation Hours</h3>
                      <div className="all-date-selected mobile-b-set">
                        <Link
                          to="#"
                          className="button-width"
                          data-bs-toggle="modal"
                          data-bs-target="#OperationHoursModal"
                        >
                          <ButtonAdd
                            Heading="ADD"
                            images="../assets/icon/plaus_icon.svg"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                        <Link
                          to="/upcoming/events"
                          className="button-width d-none"
                        >
                          <ButtonRemoveImage
                            Heading="Back"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                  </div> */}
                {/* <div className="tab-pane fade" id="pills-seven">
                    <div className="employees employ-set">
                      <h3 className="card-top-2">Social Link</h3>
                      <div className="all-date-selected mobile-b-set">
                        <Link
                          to="#"
                          className="button-width"
                          data-bs-toggle="modal"
                          data-bs-target="#socialLink"
                        >
                          <ButtonAdd
                            Heading="ADD"
                            images="../assets/icon/plaus_icon.svg"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                        <Link
                          to="/upcoming/events"
                          className="button-width  d-none"
                        >
                          <ButtonRemoveImage
                            Heading="Back"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                  </div> */}
                {/* <div className="tab-pane fade" id="pills-eight">
                    <FPE />
                    <div className="employees employ-set d-none">
                      <h3 className="card-top-2">FPE PROCESS</h3>
                      <div className="all-date-selected mobile-b-set d-none">
                        <Link to="#" className="button-width">
                          <ButtonRemoveImage
                            Heading="NEXT"
                            active="orange-outline"
                            bgChange="white-bg w-pd-add"
                          />
                        </Link>
                        <Link to="/upcoming/events" className="button-width">
                          <ButtonRemoveImage
                            Heading="Back"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content ">
                  <div className="modal-body">
                    <form action="">
                      <div className="row gy-3">
                        <div className="col-lg-6">
                          <div className="input-search tickets">
                            <input
                              type="text"
                              className="form-control input-theme mb-0"
                              placeholder="User Name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-search tickets">
                            <input
                              type="text"
                              className="form-control input-theme mb-0"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-search tickets">
                            <input
                              type="text"
                              className="form-control input-theme mb-0"
                              placeholder="Instructor Website"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-search tickets">
                            <input
                              type="Email"
                              className="form-control input-theme mb-0"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-search tickets">
                            <input
                              type="phone"
                              className="form-control input-theme mb-0"
                              placeholder="Phone"
                            />
                          </div>
                        </div>
                        <Label labelHeading="Home Town" />
                        <div className="col-lg-6">
                          <div className="input-search tickets">
                            <input
                              type="city"
                              className="form-control input-theme mb-0"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="tickets">
                            <select id="inputState" className="form-select">
                              <option selected>State</option>
                              <option>India</option>
                              <option>Pakistan</option>
                              <option>Nepal</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer border-0 justify-content-center">
                    <ButtonRemoveImage Heading="SAVE" bgChange="w-pd-add" />
                    <Link to="#" data-bs-dismiss="modal">
                      <ButtonRemoveImage
                        Heading="CANCEL"
                        active="gary-light-outline"
                        bgChange="gary-bg w-pd-add"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DeleteModal
          selectedData={selectedData}
          handleUpdateList={handleUpdateFunction()}
        /> */}
      {/* <SocialLinksModal
      //   selectedSocialLink={selectedSocialLink}
      //   handleUpdateList={handleUpdateList}
      //   setSelectedSocialLink={setSelectedSocialLink}
      /> */}
      {/* <OperationHoursModal /> */}
      <ModalLaneInput headingBay="Create Range Bay" />
    </>
  );
};

export default CreateRangeForm;
