import { Link } from 'react-router-dom';
import ButtonAdd from '../ButtonAdd';
import { useEffect, useState } from 'react';
import axiosInstance from '../../Utils/axiosInstance';
import { RANGE_SOCIAL_LINK_ENDPOINT } from '../../Utils/Endpoints';
import Logger from '../../Utils/Logger';
import { useSelector } from 'react-redux';
import RangeNotFound from '../RangeNotFound';
import SkeletonLoader from '../SkeletonLoader';
import DeleteModal from '../DeleteModal';
import SocialLinksModal from '../SocialLinksModal';
import ButtonRemoveImage from '../ButtonRemoveImage';
import Toast from '../../Utils/Toast';

const SocialLink = () => {
  const [socialLinkList, setSocialLinkList] = useState([]);
  const rangeData = useSelector((state) => state.range.createRange);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSocialLink, setSelectedSocialLink] = useState({});
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    fetchSocialLink();
  }, []);

  const fetchSocialLink = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        RANGE_SOCIAL_LINK_ENDPOINT + rangeData?.id + '/list'
      );
      if (response.data.code === 200) {
        setSocialLinkList(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRange = () => {
    Toast.success('Range has been successfully created.', {
      autoClose: 2000,
    });
  };

  return (
    <>
      <div className="employees employ-set mt-3">
        <h3 className="card-top-2">Social Link</h3>
        <div className="d-flex gap-3">
          <Link
            to="#"
            className="button-width"
            data-bs-toggle="modal"
            data-bs-target="#socialLink"
          >
            <ButtonAdd
              Heading="ADD"
              images="../assets/icon/plaus_icon.svg"
              active="orange-outline"
              bgChange="white-bg w-pd-add"
            />
          </Link>
          <Link
            to="/ranges/range-locations"
            className="button-width"
            onClick={handleRange}
          >
            <ButtonRemoveImage
              Heading="Save"
              active="orange-outline"
              bgChange="white-bg w-pd-add"
            />
          </Link>
        </div>
      </div>
      <form action="" className="triangle-top">
        <div className="body-text text-start">
          <div className="hours">
            {isLoading && <SkeletonLoader />}

            {socialLinkList?.length === 0 && !isLoading && (
              <RangeNotFound title="Social Links" />
            )}
            {socialLinkList?.length > 0 && (
              <div className="table-responsive w-100">
                <table className="table table-time-gun-create mobile-responsive time-select">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>URL</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-time-gun-create__old-even">
                    {socialLinkList?.map((item, index) => (
                      <tr key={index}>
                        <td data-label="Type">
                          <p>{item.type}</p>
                        </td>
                        <td data-label="URL">
                          <p>{item.url}</p>
                        </td>
                        <td className="td-left">
                          <div className="edit-user-id edit-link">
                            <Link
                              className="or-col border-theme"
                              data-bs-toggle="modal"
                              data-bs-target="#socialLink"
                              onClick={() => setSelectedSocialLink(item)}
                            >
                              <img src="../assets/icon/edit.svg" alt="" />
                            </Link>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteModal"
                              className="dan-col border-red"
                              onClick={() =>
                                setSelectedData({
                                  id: item.id,
                                  type: 'social_link',
                                })
                              }
                            >
                              <img src="../assets/icon/delete.svg" alt="" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </form>

      <DeleteModal
        selectedData={selectedData}
        handleUpdateList={fetchSocialLink}
      />
      <SocialLinksModal
        selectedSocialLink={selectedSocialLink}
        handleUpdateList={fetchSocialLink}
        setSelectedSocialLink={setSelectedSocialLink}
      />
    </>
  );
};

export default SocialLink;
