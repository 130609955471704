import { Link } from 'react-router-dom';
import ButtonAdd from '../ButtonAdd';
import { RANGE_OPERATION_HOUR_ENDPOINT } from '../../Utils/Endpoints';
import { RANGE_CLASS } from '../../Utils/ModelClasses';
import Logger from '../../Utils/Logger';
import { useEffect, useState } from 'react';
import axiosInstance from '../../Utils/axiosInstance';
import { useSelector } from 'react-redux';
import RangeNotFound from '../RangeNotFound';
import SkeletonLoader from '../SkeletonLoader';
import DeleteModal from '../DeleteModal';
import OperationHoursModal from '../OperationHoursModal';
import PropTypes from 'prop-types';
import ButtonRemoveImage from '../ButtonRemoveImage';

const OperationHours = ({ handleNextStep }) => {
  const [operationHourList, setOperationHourList] = useState([]);
  const rangeData = useSelector((state) => state.range.createRange);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    fetchOperationHour();
  }, []);

  const fetchOperationHour = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        RANGE_OPERATION_HOUR_ENDPOINT +
          `/list?owner_class=${RANGE_CLASS}&owner_id=${rangeData?.id}`
      );
      if (response.data.code === 200) {
        setOperationHourList(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="employees employ-set mt-3">
        <h3 className="card-top-2"> Operation Hours</h3>
        <div className="d-flex gap-3 mt-3 mt-lg-0">
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#OperationHoursModal"
            className="button-width"
          >
            <ButtonAdd
              Heading="ADD"
              images="../assets/icon/plaus_icon.svg"
              active="orange-outline"
              bgChange="white-bg w-pd-add"
            />
          </Link>
          <Link to="#" className="button-width" onClick={handleNextStep}>
            <ButtonRemoveImage
              Heading="Skip"
              active="orange-outline"
              bgChange="white-bg w-pd-add"
            />
          </Link>
        </div>
      </div>

      <form action="" className="triangle-top">
        <div className="body-text  text-start">
          <div className="hours">
            {operationHourList?.length === 0 && !isLoading && (
              <RangeNotFound title="Operation Hours" />
            )}
            {isLoading && <SkeletonLoader />}

            {operationHourList?.length > 0 && (
              <div className="table-responsive w-100">
                <table className="table table-time-gun-create mobile-responsive time-select">
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Open Time</th>
                      <th>Close Time</th>
                      <th>Open</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-time-gun-create__old-even">
                    {operationHourList?.map((item, index) => (
                      <tr key={index}>
                        <td data-label="Day">{item.dayName}</td>
                        <td data-label="Start">
                          <p>{item.start}</p>
                        </td>
                        <td data-label="End">
                          <p>{item.end}</p>
                        </td>
                        <td data-label="End">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="active"
                            checked={item.is_open}
                          />
                        </td>
                        <td className="td-left">
                          <div className="edit-user-id edit-link">
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteModal"
                              className="dan-col border-red"
                              onClick={() =>
                                setSelectedData({
                                  id: item.id,
                                  type: 'operation_hour',
                                })
                              }
                            >
                              <img src="../assets/icon/delete.svg" alt="" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </form>
      <DeleteModal
        selectedData={selectedData}
        handleUpdateList={fetchOperationHour}
      />
      <OperationHoursModal handleUpdateList={fetchOperationHour} />
    </>
  );
};

OperationHours.propTypes = {
  handleNextStep: PropTypes.func,
};

export default OperationHours;
