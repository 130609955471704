const isDebug = process.env.REACT_APP_DEBUG === 'true';

const Logger = {
  log: (...args) => {
    if (isDebug) {
      console.log(...args);
    }
  },
  error: (...args) => {
    if (isDebug) {
      console.error(...args);
    }
  },
  warning: (...args) => {
    if (isDebug) {
      console.warning(...args);
    }
  },
};

export default Logger;
