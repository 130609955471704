import React from 'react';
import { Link } from 'react-router-dom';
import HexagonSize from '../Components/HexagonSize';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';

const Account = () => {
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body px-0 py-3 d-none">
                <div className="table-responsive">
                  <table className="table-user-data">
                    <thead>
                      <tr>
                        <th style={{ transform: 'translateX(4px)' }}>
                          <input type="checkbox" />
                        </th>
                        <th style={{ width: '170px' }}>Name</th>
                        <th style={{ textAlign: 'right' }}>M Type</th>
                        <th>Shotpro ID</th>
                        <th
                          style={{
                            textAlign: 'left',
                            transform: 'translateX(-33px)',
                          }}
                        >
                          Status
                        </th>
                        <th style={{ textAlign: 'left' }}>Email</th>
                        <th style={{ textAlign: 'right' }}>Phone</th>
                        <th>ID Verified</th>
                      </tr>
                    </thead>
                    <tbody className="body-text triangle-top changes-top ">
                      <tr>
                        <td colSpan="8" style={{ border: '0px solid' }}>
                          <table className="crop">
                            <tbody>
                              <tr>
                                <td className="remove">
                                  <input type="checkbox" />
                                </td>
                                <td className="remove">
                                  {/* <Hexagon Href="/membership-two" images="../assets/images/Headshot.jpg" heading=" Billy Squire" /> */}
                                  <Link
                                    to="/membership-two"
                                    className="billy-name"
                                  >
                                    <HexagonSize imageUser="../assets/images/man-user.jpg" />
                                    Billy Squire
                                  </Link>
                                </td>
                                <td className="first-l">INDIVIDUAL</td>
                                <td>1234-52182</td>
                                <td>Active</td>
                                <td>
                                  <Link to="mailto:billysquire@outlook.com">
                                    billysquire@outlook.com
                                  </Link>
                                </td>
                                <td>404.674.4848</td>
                                <td className="first-r">
                                  <button className="custom-all-button parrot-button">
                                    VERIFIED
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td className="remove">
                                  <input type="checkbox" />
                                </td>
                                <td className="remove">
                                  {/* <Hexagon Href="/membership-two" images="../assets/images/Headshot.jpg" heading=" Billy Squire" /> */}
                                  <Link
                                    to="/membership-two"
                                    className="billy-name"
                                  >
                                    <HexagonSize imageUser="../assets/images/man-user.jpg" />
                                    Billy Squire
                                  </Link>
                                </td>
                                <td className="first-l">INDIVIDUAL</td>
                                <td>1234-52182</td>
                                <td>Active</td>
                                <td>
                                  <Link to="mailto:billysquire@outlook.com">
                                    billysquire@outlook.com
                                  </Link>
                                </td>
                                <td>404.674.4848</td>
                                <td className="first-r">
                                  <button className="custom-all-button parrot-button">
                                    VERIFIED
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-body">
                <h3>Account User</h3>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      <div className="table-responsive w-100">
                        <table className="table table-time-gun-create mobile-responsive mobile">
                          <thead>
                            <tr>
                              <th className="d-block d-md-none"></th>
                              <th>Name</th>
                              <th>M Type</th>
                              <th>Shotpro ID</th>
                              <th>Status</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>ID Verified</th>
                            </tr>
                          </thead>
                          <tbody className="table-time-gun-create__old-even">
                            <tr>
                              <td className="mob-tab-view td-left">
                                <Link to="/membership-two">
                                  <HexagonSize imageUser="../assets/images/man-user.jpg" />
                                </Link>
                              </td>
                              <td data-label="Name">
                                <Link to="/membership-two">
                                  <span className="user-n-2 mob-tab-view-2">
                                    <HexagonSize imageUser="../assets/images/man-user.jpg" />
                                    Billy Squire
                                  </span>
                                  <span className="mob-tab-view">
                                    Billy Squire
                                  </span>
                                </Link>
                              </td>
                              <td data-label="M Type">INDIVIDUAL</td>
                              <td data-label="Shotpro id">1234-52182</td>
                              <td data-label="Status">Active</td>
                              <td data-label="Email">
                                <Link
                                  to="mailto:billysquire@outlook.com"
                                  className="table-button-width"
                                >
                                  billysquire@outlook.com
                                </Link>
                              </td>
                              <td data-label="Phone">404.674.4848</td>
                              <td data-label="Id Verified">
                                <ButtonRemoveImage
                                  Heading="VERIFIED"
                                  bgChange="success-bg-color success-bg-fill"
                                  active="success-outline"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="mob-tab-view td-left">
                                <Link to="/membership-two">
                                  <HexagonSize imageUser="../assets/images/man-user.jpg" />
                                </Link>
                              </td>
                              <td data-label="Name">
                                <Link to="/membership-two">
                                  <span className="user-n-2 mob-tab-view-2">
                                    <HexagonSize imageUser="../assets/images/man-user.jpg" />
                                    Billy Squire
                                  </span>
                                  <span className="mob-tab-view">
                                    Billy Squire
                                  </span>
                                </Link>
                              </td>
                              <td data-label="M Type">INDIVIDUAL</td>
                              <td data-label="Shotpro id">1234-52182</td>
                              <td data-label="Status">Active</td>
                              <td data-label="Email">
                                <Link
                                  to="mailto:billysquire@outlook.com"
                                  className="table-button-width"
                                >
                                  billysquire@outlook.com
                                </Link>
                              </td>
                              <td data-label="Phone">404.674.4848</td>
                              <td data-label="Id Verified">
                                <ButtonRemoveImage
                                  Heading="VERIFIED"
                                  bgChange="success-bg-color success-bg-fill"
                                  active="success-outline"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="mob-tab-view td-left">
                                <Link to="/membership-two">
                                  <HexagonSize imageUser="../assets/images/man-user.jpg" />
                                </Link>
                              </td>
                              <td data-label="Name">
                                <Link to="/membership-two">
                                  <span className="user-n-2 mob-tab-view-2">
                                    <HexagonSize imageUser="../assets/images/man-user.jpg" />
                                    Billy Squire
                                  </span>
                                  <span className="mob-tab-view">
                                    Billy Squire
                                  </span>
                                </Link>
                              </td>
                              <td data-label="M Type">INDIVIDUAL</td>
                              <td data-label="Shotpro id">1234-52182</td>
                              <td data-label="Status">Active</td>
                              <td data-label="Email">
                                <Link
                                  to="mailto:billysquire@outlook.com"
                                  className="table-button-width"
                                >
                                  billysquire@outlook.com
                                </Link>
                              </td>
                              <td data-label="Phone">404.674.4848</td>
                              <td data-label="Id Verified">
                                <ButtonRemoveImage
                                  Heading="VERIFIED"
                                  bgChange="success-bg-color success-bg-fill"
                                  active="success-outline"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
