import React from 'react';
import HexLine from './HexLine';
import PropTypes from 'prop-types';

const UserProcessing = (props) => {
  return (
    <>
      <div className="processing-list">
        <HexLine
          imageUserTwo={props.imagesCard}
          imgSizeTwo="today-size clip"
          hexOuterLine="pad"
        />

        <div className="processing-list__text">
          <p>{props.userHeading}</p>
          <h4>{props.userTitle}</h4>
        </div>
      </div>
    </>
  );
};

UserProcessing.propTypes = {
  props: PropTypes.object,
  imagesCard: PropTypes.string,
  userHeading: PropTypes.string,
  userTitle: PropTypes.string,
};
export default UserProcessing;
