import React from 'react';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';

const AddAdditionalParticipant = () => {
  return (
    <>
      {/* <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#addAdditional"
      >
        Launch demo modal
      </button> */}

      <div
        className="modal fade"
        id="addAdditional"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Add Additional Participant
              </h5>
              <Link
                to="#"
                className="close-firearm-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="../assets/icon/cross-svgrepo-com.svg" alt="" />
              </Link>
            </div>
            <div className="modal-body d-none">...</div>
            <div className="modal-footer border-0 justify-content-center">
              <Link to="#" data-bs-dismiss="modal" aria-label="Close">
                <ButtonRemoveImage
                  Heading="No"
                  active="orange-outline"
                  bgChange="white-bg w-pd-add"
                />
              </Link>
              <Link to="/ranges/experience" data-bs-dismiss="modal">
                <ButtonRemoveImage
                  Heading="Yes"
                  active="orange-outline"
                  bgChange="white-bg w-pd-add"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdditionalParticipant;
