import PropTypes from 'prop-types';
import Logger from '../../Utils/Logger';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ButtonRemoveImage from '../ButtonRemoveImage';
import { Link } from 'react-router-dom';
import ButtonLoader from '../ButtonLoader';
import Multiselect from 'multiselect-react-dropdown';
import { INSTRUCTORS_ENDPOINT } from '../../Utils/Endpoints';
import axiosInstance from '../../Utils/axiosInstance';

const ClassInstructor = ({ handleNextStep }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [option, setOption] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const trainerRangeData = useSelector((state) => state.range.trainerRange);
  const [generalError, setGeneralError] = useState('');

  useEffect(() => {
    fetchTrainerList();
  }, [searchText]);

  const fetchTrainerList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        INSTRUCTORS_ENDPOINT + `?search=${searchText}`
      );
      if (response.data.code === 200) {
        setOption(
          response.data.data.data.map((user) => ({
            value: user.id,
            name: user.name,
          }))
        );
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (trainerRangeData?.length > 0) {
      setSelectedIds(
        trainerRangeData.map((trainer) => ({
          value: trainer.id,
          name: trainer.name,
        }))
      );
    }
  }, [trainerRangeData]);

  const onSelect = (option) => {
    setSelectedIds(option);
  };

  const onRemove = (option) => {
    setSelectedIds(option);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingList(true);
      setGeneralError('');
      //   const filteredArray = selectedIds.map((item) => item.value);
      //   const response = await axiosInstance.post(
      //     RANGE_ASSOCIATE_TRAINER_ENDPOINT + rangeData?.id,
      //     {
      //       trainer_ids: filteredArray,
      //     }
      //   );
      //   if (response.data.code !== 200) {
      //     setGeneralError(response.data.message);
      //     return;
      //   }
      handleNextStep();
      //   Toast.success(response.data.message, {
      //     autoClose: 2000,
      //   });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoadingList(false);
    }
  };
  return (
    <>
      <div className="employees employ-set">
        <h3 className="card-top-2">Instructors</h3>
      </div>
      <form onSubmit={handleSubmit} className="triangle-top">
        <div className="body-text text-start">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="inputState" className="form-label">
                Instructors
              </label>
              <Multiselect
                isObject={true}
                options={option}
                showCheckbox
                loading={isLoading}
                selectedValues={selectedIds}
                displayValue="name"
                onSelect={onSelect}
                onRemove={onRemove}
                onSearch={(text) => setSearchText(text)}
              />
            </div>
            <div className="col-md-6 mt-4 mt-md-0"></div>

            <div className="d-flex gap-3 align-items-center">
              <div className="mb-5 mt-4">
                <ButtonLoader
                  type="submit"
                  bgChange={`white-bg delete-w`}
                  active="orange-outline"
                  isLoading={isLoadingList}
                >
                  Save
                </ButtonLoader>
              </div>
              <div className="mb-5 mt-4">
                <Link to="#" className="button-width " onClick={handleNextStep}>
                  <ButtonRemoveImage
                    Heading="Next"
                    active="gary-light-outline"
                    bgChange="gary-bg w-pd-add"
                  />
                </Link>
              </div>
            </div>
            {generalError && (
              <p className="error text-center mt-1">{generalError}</p>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

ClassInstructor.propTypes = {
  handleNextStep: PropTypes.func,
};

export default ClassInstructor;
