import Multiselect from 'multiselect-react-dropdown';
import { useEffect, useState } from 'react';
import axiosInstance from '../../Utils/axiosInstance';
import {
  INSTRUCTORS_ENDPOINT,
  RANGE_ASSOCIATE_TRAINER_ENDPOINT,
} from '../../Utils/Endpoints';
import Logger from '../../Utils/Logger';
import Toast from '../../Utils/Toast';
import { useDispatch, useSelector } from 'react-redux';
import ButtonLoader from '../ButtonLoader';
import { setTrainerRangeData } from '../../Redux/features/ranges/rangeSlice';
import ButtonRemoveImage from '../ButtonRemoveImage';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Trainers = ({ handleNextStep }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [option, setOption] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const rangeData = useSelector((state) => state.range.createRange);
  const trainerRangeData = useSelector((state) => state.range.trainerRange);
  const [generalError, setGeneralError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTrainerList();
  }, [searchText]);

  const fetchTrainerList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        INSTRUCTORS_ENDPOINT + `?search=${searchText}`
      );
      if (response.data.code === 200) {
        setOption(
          response.data.data.data.map((user) => ({
            value: user.id,
            name: user.name,
          }))
        );
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (trainerRangeData?.length > 0) {
      setSelectedIds(
        trainerRangeData.map((trainer) => ({
          value: trainer.id,
          name: trainer.name,
        }))
      );
    }
  }, [trainerRangeData]);

  const onSelect = (option) => {
    setSelectedIds(option);
  };

  const onRemove = (option) => {
    setSelectedIds(option);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingList(true);
      setGeneralError('');
      const filteredArray = selectedIds.map((item) => item.value);
      const response = await axiosInstance.post(
        RANGE_ASSOCIATE_TRAINER_ENDPOINT + rangeData?.id,
        {
          trainer_ids: filteredArray,
        }
      );

      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      dispatch(setTrainerRangeData(response.data.data));
      handleNextStep();

      Toast.success(response.data.message, {
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoadingList(false);
    }
  };

  return (
    <>
      <div className="employees employ-set">
        <h3 className="card-top-2">Instructors</h3>
      </div>
      <form onSubmit={handleSubmit} className="triangle-top">
        <div className="body-text   text-start">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="inputState" className="form-label">
                Instructors
              </label>
              <Multiselect
                isObject={true}
                options={option}
                showCheckbox
                loading={isLoading}
                selectedValues={selectedIds}
                displayValue="name"
                onSelect={onSelect}
                onRemove={onRemove}
                onSearch={(text) => setSearchText(text)}
              />
            </div>
            <div className="col-md-6 mt-4 mt-md-0">
              {/* <Label labelHeading="My Trainers is not on ShotPro ID yet" />
            <div className="form-switch">
              <input className="form-check-input input-theme" type="checkbox" />
            </div> */}
            </div>

            {/* <div
            className="mb-5 mt-4"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            // style={{ maxWidth: '300px', width: '100%' }}
          >
            <ButtonRemoveImage
              Heading="ADD NEW Trainers"
              active="orange-outline"
              bgChange="white-bg py-2 create-range-2"
            />
          </div> */}
            <div className="d-flex gap-3 align-items-center">
              <div className="mb-5 mt-4">
                <ButtonLoader
                  type="submit"
                  bgChange={`white-bg delete-w`}
                  active="orange-outline"
                  isLoading={isLoadingList}
                >
                  Save
                </ButtonLoader>
              </div>
              <div className="mb-5 mt-4">
                <Link to="#" className="button-width " onClick={handleNextStep}>
                  <ButtonRemoveImage
                    Heading="Skip"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add"
                  />
                </Link>
              </div>
            </div>
            {generalError && (
              <p className="error text-center mt-1">{generalError}</p>
            )}
            {/* <div className="col-lg-2 ">
                                  <Link to="#">
                                      <ButtonRemoveImage Heading="BACK" active="gary-light-outline mt-3 mt-md-0" bgChange="gary-bg  " />
                                  </Link>

                              </div> */}
          </div>
        </div>
      </form>
    </>
  );
};
Trainers.propTypes = {
  handleNextStep: PropTypes.func,
};

export default Trainers;
