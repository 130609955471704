import React from 'react';
import Receipt from '../Components/Receipt';

const Receiptsnew = () => {
  return (
    <>
      <div className="receipt-in">
        <Receipt />
      </div>
    </>
  );
};

export default Receiptsnew;
