import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { RESET_PASSWORD_ENDPOINT } from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import ButtonLoader from '../Components/ButtonLoader';
import Logger from '../Utils/Logger';

// Validation schema
const ConfirmSchema = Yup.object().shape({
  OTP: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string().required('Required'),
});

const ConfirmPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { token, email, message } = (location ?? {}).state;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [OTP, setOTP] = useState('');
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [successMsg, setSuccessMsg] = useState(message ? message : '');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const validate = () => {
    try {
      setGeneralError('');
      ConfirmSchema.validateSync(
        { OTP, password, confirmPassword },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(RESET_PASSWORD_ENDPOINT, {
        email,
        confirmation_token: token,
        code: OTP,
        password: password,
        confirm_password: confirmPassword,
      });
      if (response.data.code === 200) {
        setSuccessMsg(response.data.message);
        setTimeout(() => {
          setSuccessMsg('');
          navigate('/');
        }, 3500);
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="sign-in">
      <div className="text-center">
        <img src="../assets/images/webside.webp" alt="" className="sign-logo" />
      </div>
      <h3>Confirm Password</h3>
      {generalError && <p className="error">{generalError}</p>}
      {successMsg && <p className="successfully">{successMsg}</p>}

      <div className="triangle-card sign-in-card">
        <form className="sign-form" onSubmit={handleSubmit}>
          <label htmlFor="exampleInputEmail1" className="form-label">
            OTP*
          </label>
          <div className="input-search-items tickets">
            <input
              type="text"
              className="form-control input-theme mb-0"
              value={OTP}
              onChange={(e) => setOTP(e.target.value)}
              maxLength={4}
              required
            />
            {errors.OTP && <div className="error">{errors.OTP}</div>}
          </div>
          <label htmlFor="exampleInputEmail1" className="form-label">
            Password*
          </label>
          <div className="password input-search-items tickets">
            <input
              type={showPasswordConfirm ? 'type' : 'password'}
              className="form-control input-theme mb-0"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {errors.password && <div className="error">{errors.password}</div>}

            <span className="input-group-text">
              {showPasswordConfirm ? (
                <img
                  src="../assets/icon/eye-password-hide.svg"
                  alt=""
                  onClick={togglePasswordVisibilityConfirm}
                />
              ) : (
                <img
                  src="../assets/icon/eye-password-show.svg"
                  alt=""
                  onClick={togglePasswordVisibilityConfirm}
                />
              )}
            </span>
          </div>
          <label htmlFor="exampleInputEmail1" className="form-label">
            Confirm Password*
          </label>
          <div className="password input-search-items tickets">
            <input
              type={showPassword ? 'type' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="form-control input-theme mb-0"
              name="Confirm password"
              required
            />
            {errors.confirmPassword && (
              <div className="error">{errors.confirmPassword}</div>
            )}

            <span className="input-group-text">
              {showPassword ? (
                <img
                  src="../assets/icon/eye-password-hide.svg"
                  alt=""
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <img
                  src="../assets/icon/eye-password-show.svg"
                  alt=""
                  onClick={togglePasswordVisibility}
                />
              )}
            </span>
          </div>
          <div className="d-block text-left">
            <label className="form-label-2 ps-2 pt-1" htmlFor="Check1">
              Use at least 8 characters
            </label>
          </div>
          {/* <div className="custom-action-button mt-3">
            <button type="submit" className="white-bg">
              <Link to="/" className="link-a">
              Confirm Password
              </Link>
            </button>
          </div> */}
          <div className="mt-3">
            <ButtonLoader
              type="submit"
              bgChange={`white-bg`}
              active="orange-outline"
              isLoading={isLoading}
            >
              Confirm Password
            </ButtonLoader>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmPassword;
