import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HexagonSize from './HexagonSize';
import { getItem, getLoginUser, saveItem } from './../Utils/LocalStorage';
import LogoutModal from './LogoutModal';
import LoadingOverlayMain from './LoadingOverlayMain';
import Logger from '../Utils/Logger';
import { useSelector } from 'react-redux';
import { truncateString } from '../Utils/common';

const TopNav = () => {
  const [show, setShow] = useState(false);
  const [showIn, setShowOut] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [dateTime, setDateTime] = useState('');
  const [currentUser, setCurrentUser] = useState(getLoginUser());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRangeChange, setIsLoadingRangeChange] = useState(false);
  // const [rangeList, setRangeList] = useState([]);
  const [selectedRange, setSelectedRange] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  const navigate = useNavigate();
  const rangeList = useSelector((state) => state.range.rangeList);

  useEffect(() => {
    setCurrentUser(getLoginUser());
    const formatDateTime = () => {
      const date = new Date();
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const formattedDate = date.toLocaleDateString('en-US', options);
      const formattedTime = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        second: 'numeric',
      });
      const formattedDateTime = `${formattedDate} | ${formattedTime} `;
      setDateTime(formattedDateTime);
    };

    formatDateTime();
    const intervalId = setInterval(formatDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile && location?.pathname) {
      setShowOut(false);
      setShow(false);
      navigate(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchRangeList();
  }, [rangeList]);

  useEffect(() => {
    // Check if the current view is mobile
    if (window.innerWidth < 992) {
      const modalClose = document.getElementsByClassName('cross-icon-modal');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
    }
  }, [location.pathname]);

  // old //
  // const fetchRangeList = async () => {
  //   try {
  //     setIsLoading(true);
  //     const localRange = getItem('rangeId');
  //     const response = await axiosInstance.get(RANGE_ENDPOINT);
  //     if (response.data.code === 200) {
  //       setRangeList(response.data.data.data);
  //       if (response?.data?.data?.data?.length > 0) {
  //         const list = response?.data?.data?.data;
  //         if (localRange) {
  //           const selectedItem = list.find((item) => item.id === localRange);
  //           setSelectedRange(selectedItem);
  //         } else {
  //           setSelectedRange(list[0]);
  //           saveItem('rangeId', list[0]?.id);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     Logger.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  //

  const fetchRangeList = async () => {
    try {
      setIsLoading(true);
      const localRange = getItem('rangeId');
      if (rangeList?.length > 0) {
        if (localRange) {
          const selectedItem = rangeList.find((item) => item.id === localRange);
          setSelectedRange(selectedItem);
        } else {
          setSelectedRange(rangeList[0]);
          saveItem('rangeId', rangeList[0]?.id);
        }
      } else {
        setSelectedRange({});
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedRange = (range) => {
    setIsLoadingRangeChange(true);
    setSelectedRange(range);
    saveItem('rangeId', range?.id);
    setTimeout(() => {
      setIsLoadingRangeChange(false);
      window.location.reload();
    }, 600);
  };

  return (
    <>
      {isLoadingRangeChange && <LoadingOverlayMain />}
      <div className="nav-line">
        <div className="nav-box">
          <div className="dropdown drop-list">
            <div className="triangle"></div>
            <div className="bg-color-remove"></div>
            <div
              className={
                rangeList?.length === 0 &&
                Object.keys(selectedRange).length === 0
                  ? 'drop-bg-color'
                  : ''
              }
            >
              {!isLoading && Object.keys(selectedRange).length > 0 && (
                <Link
                  className={
                    'range-button r-b-m ' +
                    (rangeList?.length > 1 ? 'dropdown-toggle' : '')
                  }
                  to="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle={rangeList?.length > 1 ? 'dropdown' : null}
                  aria-expanded="false"
                >
                  {truncateString(selectedRange?.name, 22)} -{' '}
                  <span>{selectedRange?.address?.city}</span>
                </Link>
              )}

              {rangeList?.length > 1 && (
                <ul
                  className="dropdown-menu w-100 action-drop "
                  aria-labelledby="dropdownMenuLink"
                >
                  {rangeList?.map((range, index) => (
                    <li key={index} onClick={() => handleSelectedRange(range)}>
                      <Link className="dropdown-item" to="#">
                        <p>
                          {truncateString(range?.name, 22)} -{' '}
                          <span>{range?.address?.city}</span>
                        </p>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="shot-pro-link">
          <div className="user-changes">
            <p>{dateTime}</p>
          </div>
          <div className="user-detail">
            <Link to="/user-profile">
              <div
                className="d-flex align-items-center gap-2 user-id-list"
                onClick={() => setUpdateProfile(!updateProfile)}
              >
                <HexagonSize
                  imageUser={
                    currentUser?.profile_picture
                      ? currentUser?.profile_picture?.fullUrl
                      : '../assets/images/profile.png'
                  }
                />
                <div>
                  <h3>{currentUser?.name}</h3>
                  {currentUser?.role?.length > 0 && (
                    <p className="text-uppercase">
                      {
                        currentUser?.role[currentUser?.role?.length - 1]
                          .roleName
                      }
                    </p>
                  )}
                </div>
              </div>
            </Link>
            <div className="user-id">
              <div className="bill-icon">
                <img src="../assets/icon/notification_icon.svg" alt="" />
                <div className="number-add">1</div>
              </div>
              {show ? <div className="overlay"></div> : null}
              <input type="checkbox" id="checked" className="d-none" />
              <label
                htmlFor="checked"
                className="click"
                onClick={() => setShow(!show)}
              >
                {showIn ? (
                  <img
                    src="../assets/icon/cross_icon.svg"
                    alt=""
                    onClick={() => setShowOut(!showIn)}
                    className="cross-icon  cross-icon-modal"
                  />
                ) : null}
                <i
                  className="fa fa-align-justify"
                  aria-hidden="true"
                  onClick={() => setShowOut(!showIn)}
                ></i>
              </label>
              <button
                type="submit"
                className="tooltip-logout"
                data-bs-toggle="modal"
                data-bs-target="#logOutModal"
                // data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Logout"
              >
                <img src="../assets/icon/logout-min.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <LogoutModal />
    </>
  );
};

export default TopNav;
