import React, { useEffect, useState } from 'react';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';
import { getItem } from '../Utils/LocalStorage';
import axiosInstance from '../Utils/axiosInstance';
import Toast from '../Utils/Toast';
import {
  INSTRUCTORS_ENDPOINT,
  RANGE_ASSOCIATE_TRAINER_ENDPOINT,
} from '../Utils/Endpoints';
import ButtonLoader from './ButtonLoader';
import PropTypes from 'prop-types';
import Multiselect from 'multiselect-react-dropdown';
import Logger from '../Utils/Logger';

const TrainersModal = ({ fetchTrainerRangeList }) => {
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const rangeId = getItem('rangeId');

  useEffect(() => {
    fetchTrainerList();
  }, [searchText]);

  const fetchTrainerList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        INSTRUCTORS_ENDPOINT + `?search=${searchText}`
      );
      if (response.data.code === 200) {
        setOption(
          response.data.data.data.map((user) => ({
            value: user.id,
            name: user.name,
          }))
        );
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelect = (option) => {
    setSelectedIds(option);
  };

  const onRemove = (option) => {
    setSelectedIds(option);
  };

  const handleReset = () => {
    setGeneralError('');
    setSelectedIds([]);
    setSearchText('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setGeneralError('');
      const filteredArray = selectedIds.map((item) => item.value);
      const response = await axiosInstance.post(
        RANGE_ASSOCIATE_TRAINER_ENDPOINT + rangeId,
        {
          trainer_ids: filteredArray,
        }
      );
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      const modalClose = document.getElementsByClassName('close-modal-trainer');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
      Toast.success(response.data.message, {
        autoClose: 2000,
      });
      await fetchTrainerRangeList();
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="trainersModal"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Instructors Range
              </h5>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body modal-height">
                {/* <div className="row d-none">
                  <div className="col-md-6">
                    <Label labelHeading="Username*" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        onChange={(e) => setUserName(e.target.value)}
                        value={userName}
                      />
                      {errors.userName && (
                        <div className="error">{errors.userName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Phone:" />
                    <div className="tickets">
                      <input
                        type="phone"
                        className="form-control input-theme"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      {errors.phoneNumber && (
                        <div className="error">{errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="First Name*" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                      />
                      {errors.firstName && (
                        <div className="error">{errors.firstName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Last Name*" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                      />
                      {errors.lastName && (
                        <div className="error">{errors.lastName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Email*" />
                    <div className="tickets">
                      <input
                        type="email"
                        className="form-control input-theme"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                      {errors.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Password*" />
                    <div className="tickets">
                      <input
                        type="password"
                        className="form-control input-theme"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      {errors.password && (
                        <div className="error">{errors.password}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Dob" />
                    <div className="tickets">
                      <input
                        type="date"
                        className="form-control input-theme"
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                      />
                      {errors.dob && <div className="error">{errors.dob}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Gender*" />
                    <div className="select-input tickets">
                      <select
                        name="Gender"
                        id="Gender*"
                        className="form-select mb-0"
                        onChange={(e) => setGender(e.target.value)}
                        value={gender}
                      >
                        <option value="">Select an option</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Non Binary</option>
                      </select>
                      {errors.gender && (
                        <div className="error">{errors.gender}</div>
                      )}
                    </div>
                  </div>
                  <AddressAutocomplete handleAddress={setAddressObject} />
                  <div className="col-md-6 ">
                    <Label labelHeading="Bio" />
                    <div className="tickets-2">
                      <textarea
                        className="form-control input-theme"
                        rows="3"
                        onChange={(e) => setBio(e.target.value)}
                        value={bio}
                      ></textarea>
                      {errors.bio && <div className="error">{errors.bio}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Label labelHeading="Skill level*" />
                    <div className="select-input tickets">
                      <select
                        name="Skill"
                        id="Skill"
                        className="form-select mb-0"
                        onChange={(e) => setSkillLevel(e.target.value)}
                        value={skillLevel}
                      >
                        <option value="">Select an option</option>
                        <option value="Novice/Never Ever">
                          Novice/Never Ever
                        </option>
                        <option value="Beginner/In-training">
                          Beginner/In-training
                        </option>
                        <option value="Proficient/Intermediate">
                          Proficient/Intermediate
                        </option>
                        <option value="Advanced/Specialist">
                          Advanced/Specialist
                        </option>
                        <option value="Professional">Professional</option>
                      </select>
                      {errors.skillLevel && (
                        <div className="error">{errors.skillLevel}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <Label labelHeading="Logo" />
                    <ImageUploadInput setFile={setFile} />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-6 mb-5">
                    <label htmlFor="inputState" className="form-label">
                      Instructors
                    </label>
                    <Multiselect
                      isObject={true}
                      options={option}
                      showCheckbox
                      loading={isLoading}
                      selectedValues={selectedIds}
                      displayValue="name"
                      onSelect={onSelect}
                      onRemove={onRemove}
                      onSearch={(text) => setSearchText(text)}
                    />
                  </div>
                </div>
                {generalError && (
                  <p className="error text-center mt-3">{generalError}</p>
                )}
              </div>
              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create mt-5">
                  <ButtonLoader
                    Heading="SAVE"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add save-w"
                    type="submit"
                    isLoading={isLoading}
                  >
                    Save
                  </ButtonLoader>

                  <Link
                    to="#"
                    className="close-modal-trainer"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

TrainersModal.propTypes = {
  fetchTrainerRangeList: PropTypes.func,
};

export default TrainersModal;
