import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PropTypes from 'prop-types';

const PhoneInputView = ({ phoneNumber, setPhoneNumber }) => {
  return (
    <PhoneInput
      international
      defaultCountry="US"
      value={phoneNumber}
      onChange={setPhoneNumber}
      placeholder="Enter phone number"
      className="form-control input-theme"
    />
  );
};

PhoneInputView.propTypes = {
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func,
};

export default PhoneInputView;
