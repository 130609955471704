import React, { useState } from 'react';
import Label from './Label';
import { Link } from 'react-router-dom';
import { ADD_PROFICIENCY_ENDPOINT } from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import Toast from '../Utils/Toast';
import ButtonLoader from './ButtonLoader';
import { getItem } from '../Utils/LocalStorage';
import PropTypes from 'prop-types';
import { uploadFile } from '../Utils/upload';
import {
  ENTITY_HAS_PROFICIENCY_TYPE_CLASS,
  RANGE_CLASS,
} from '../Utils/ModelClasses';
import { PROFICIENCY_LOGO_TYPE } from '../Utils/UploadType';

const OptionModal = ({ fetchFirearm }) => {
  const [name, setName] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const rangeId = getItem('rangeId');
  const [preview, setPreview] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.post(
        ADD_PROFICIENCY_ENDPOINT,

        {
          name: name,
          owner_class: RANGE_CLASS,
          owner_id: rangeId,
        }
      );

      if (response.data.code === 200) {
        if (logo) {
          await uploadFile(
            ENTITY_HAS_PROFICIENCY_TYPE_CLASS,
            response.data.data.id,
            PROFICIENCY_LOGO_TYPE,
            logo
          );
        }
        const modalClose = document.getElementsByClassName('close-firearm-2');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        fetchFirearm();
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setName('');
    setGeneralError('');
    setPreview(null);
    setLogo(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(event.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="OptionModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Create a Firearm
              </h5>
              <Link
                to="#"
                className="close-firearm-2"
                data-bs-dismiss="modal"
                onClick={handleReset}
              >
                <img src="../assets/icon/cross-svgrepo-com.svg" alt="" />
              </Link>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body modal-height">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="row align-items-center">
                      <div className="col-lg-4">
                        <div className="ww-2">
                          <Label labelHeading="Firearm " />
                          <div className="tickets">
                            <input
                              type="text"
                              className="form-control input-theme"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="button-wrapper ">
                          <span className="label-input img-logo-3">
                            <p>Logo Image</p>
                          </span>
                          <input
                            type="file"
                            name="upload"
                            id="upload"
                            className="upload-box input-theme"
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="d-flex gap-3 align-items-center justify-content-between mt-4 mt-lg-0">
                          {preview && (
                            <div className="fir-m-c">
                              <img src={preview} alt="" />
                            </div>
                          )}
                          <div className="options-save oo">
                            <ButtonLoader
                              Heading="ADD"
                              images="../assets/icon/plaus_icon.svg"
                              active="orange-outline"
                              bgChange="white-bg w-pd-add save-w"
                              type="submit"
                              isLoading={isLoading}
                            >
                              {/* <img src="../assets/icon/plaus_icon.svg" alt="" /> */}
                              SAVE
                            </ButtonLoader>
                          </div>
                          {/* <img
                              src="../assets/icon/delete.svg"
                              alt=""
                              className="dd"
                            /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {generalError && (
                  <p className="error text-center">{generalError}</p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

OptionModal.propTypes = {
  fetchFirearm: PropTypes.func,
};

export default OptionModal;
