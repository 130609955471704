import React from 'react';

const Receipt = () => {
  return (
    <>
      <div className="invoice-container">
        <div className="invoice-header">
          <div className="company-logo">
            <img src="../assets/images/webside.webp" alt="Company Logo" />
          </div>
          <div className="company-details">
            <h1>RECEIPT</h1>
            <p>Saldo Apps</p>
            <p>Gleb Piankov</p>
            <p>wiz@saldoapps.com</p>
            <p>80296979597</p>
            <p>Nekrasova str. 28-32, Minsk, Belarus, 220040</p>
          </div>
        </div>
        <section className="invoice-details">
          <div className="bill-to">
            <p>
              <strong>Bill to</strong>
            </p>
            <p>Shepard corp.</p>
            <p>wiz@saldoapps.com</p>
            <p>Nekrasova str. 28-32, Minsk, Belarus, 220040</p>
          </div>
          <div className="invoice-info">
            <p>
              <strong>Order ID:</strong> 001
            </p>
            <p>
              <strong>Invoice date:</strong> Jul 13th, 2021
            </p>
            <p>
              <strong>Due:</strong> Feb 28th, 2022
            </p>
          </div>
        </section>
        <section className="invoice-table">
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Rate (USD)</th>
                <th>Qty</th>
                <th>Tax (%)</th>
                <th>Disc. (%)</th>
                <th>Amount (USD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Prototype</td>
                <td>20,230,450.00</td>
                <td>2000</td>
                <td>20.50</td>
                <td>20.50</td>
                <td>20,230,450.00</td>
              </tr>
              <tr>
                <td>Design</td>
                <td>20,230,450.00</td>
                <td>2000</td>
                <td>20.50</td>
                <td>20.50</td>
                <td>20,230,450.00</td>
              </tr>
              <tr>
                <td>Front-end</td>
                <td>20,230,450.00</td>
                <td>2000</td>
                <td>20.50</td>
                <td>20.50</td>
                <td>20,230,450.00</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="invoice-summary">
          <p>
            <span>Subtotal:</span>
            <span>USD 0.00</span>
          </p>
          <p>
            <span>Discount (20%):</span>
            <span>USD 0.00</span>
          </p>
          <p>
            <span>Shipping Cost:</span> <span>USD 0.00</span>
          </p>
          <p>
            <span>Sales Tax:</span> <span>USD 0.00</span>
          </p>
          <p>
            <strong>Total:</strong> <strong>USD 20,230,450.00</strong>
          </p>
          <p>
            <span>Amount paid:</span> <span>USD 20,230,450.00</span>
          </p>
          <p>
            <span>Balance Due:</span> <span>USD 0.00</span>
          </p>
        </section>
        <section className="last-info">
          <h4>For questions concerning this invoice, please contact</h4>
          <p>Name : Ajay chayal</p>
          <p>Mobile : (+91) 9999999999</p>
          <p>Email : ajay.chayal123@gmail.com</p>
          <a href="https://shotpro-rangeadmin.yatilabs.com/dashboard">
            www.ShotPro.com
          </a>
        </section>
      </div>
    </>
  );
};

export default Receipt;
