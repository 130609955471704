import React, { useEffect, useState } from 'react';
import Label from './Label';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ButtonLoader from './ButtonLoader';
import ButtonRemoveImage from './ButtonRemoveImage';
import * as Yup from 'yup';
import axiosInstance from '../Utils/axiosInstance';
import { PARTICIPANT_ENDPOINT } from '../Utils/Endpoints';
import Toast from '../Utils/Toast';
import { emailRegExp, phoneRegExp } from '../Utils/common';
import PhoneInputView from './PhoneInput';

// Validation schema
const ParticipantSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  dob: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
});

const ParticipantForm = ({ selectedParticipant, UpdateList }) => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');

  useEffect(() => {
    if (selectedParticipant?.id) {
      setEmail(selectedParticipant?.email);
      setPhoneNumber(selectedParticipant?.phone_number);
      setFirstName(selectedParticipant?.first_name);
      setLastName(selectedParticipant?.last_name);
      setDob(selectedParticipant?.dob);
      setGender(selectedParticipant?.gender);
    }
  }, [selectedParticipant]);

  const validate = () => {
    try {
      ParticipantSchema.validateSync(
        {
          firstName,
          lastName,
          dob,
          email,
          phoneNumber,
          gender,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleParticipant = async (e) => {
    e.preventDefault();
    try {
      if (!validate()) return;
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.patch(
        PARTICIPANT_ENDPOINT + `/${selectedParticipant?.id}`,
        {
          email: email,
          phone_number: phoneNumber,
          first_name: firstName,
          last_name: lastName,
          dob: dob,
          gender: gender,
        }
      );
      if (response.data.code === 200) {
        const modalClose = document.getElementsByClassName(
          'close-participant-modal'
        );
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        UpdateList();
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setGeneralError('');
    setEmail('');
    setPhoneNumber('');
    setFirstName('');
    setLastName('');
    setDob('');
    setGender('');
    setErrors({});
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalParticipant"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                {selectedParticipant?.id ? 'Edit' : 'Add'} Participant
              </h5>
              <a
                className="close-firearm-2 close-booking-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="../assets/icon/cross-svgrepo-com.svg" alt="" />
              </a>
            </div>
            <form onSubmit={handleParticipant}>
              <div className="modal-body modal-height">
                <div className="row">
                  <div className="col-lg-6">
                    <Label labelHeading="First Name" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        placeholder=""
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      {errors.firstName && (
                        <div className="error">{errors.firstName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="Last Name" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        placeholder=""
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      {errors.lastName && (
                        <div className="error">{errors.lastName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="Email" />
                    <div className="tickets">
                      <input
                        type="email"
                        className="form-control input-theme"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="Dob" />
                    <div className="tickets">
                      <input
                        type="date"
                        className="form-control input-theme"
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                      />
                      {errors.dob && <div className="error">{errors.dob}</div>}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="Phone" />
                    <div className="tickets">
                      <PhoneInputView
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                      />
                      {errors.phoneNumber && (
                        <div className="error">{errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="Gender" />
                    <div className="select-input tickets">
                      <select
                        className="form-select mb-0"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option selected>Gender</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Non Binary</option>
                      </select>
                      {errors.gender && (
                        <div className="error">{errors.gender}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {generalError && (
                <p className="error text-center">{generalError}</p>
              )}
              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create">
                  <ButtonLoader
                    Heading="SAVE"
                    active="orange-outline"
                    bgChange="bg-orange save-w"
                    type="submit"
                    isLoading={isLoading}
                  >
                    {selectedParticipant?.id ? 'Update' : 'Save'}
                  </ButtonLoader>
                  <Link
                    to="#"
                    className="close-solcial-link-modal close-participant-modal"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

ParticipantForm.propTypes = {
  selectedParticipant: PropTypes.object,
  UpdateList: PropTypes.func,
};

export default ParticipantForm;
