import React from 'react';

const CardDesk = () => {
  return (
    <>
      <div className="card-body">
        <h3>Range Desk to do </h3>
        <div className="triangle-top">
          <div className="body-text body-font  pt-4 body-p-b">
            <div className="row">
              <div className="col-6 col-md-6">
                <p>Pending FPE</p>
                <h4>3</h4>
              </div>
              <div className="col-6 col-md-6">
                <p>Pending ID Verification</p>
                <h4>2</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDesk;
