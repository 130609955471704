import React, { useEffect, useState } from 'react';
import { PARTICIPANT_LIST_ENDPOINT } from '../Utils/Endpoints';
import Logger from '../Utils/Logger';
import RangeNotFound from '../Components/RangeNotFound';
import SkeletonLoader from '../Components/SkeletonLoader';
import axiosInstance from '../Utils/axiosInstance';
import Pagination from '../Components/Pagination';
import { Link } from 'react-router-dom';
import DeleteModal from '../Components/DeleteModal';
import ParticipantForm from '../Components/ParticipantForm';

const Participants = () => {
  const [search, setSearch] = useState('');
  const [participantList, setParticipantList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [selectedParticipant, setSelectedParticipant] = useState({});

  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchParticipantList();
  }, [search, currentPage, itemsPerPage]);

  const fetchParticipantList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        PARTICIPANT_LIST_ENDPOINT +
          `?per_page=${itemsPerPage}&page=${currentPage}&search=${search}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setParticipantList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">Participants</h3>
                  <div className="all-date-selected">
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                        id="search"
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={search}
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="row">
                      {participantList?.length === 0 && !isLoading && (
                        <RangeNotFound title="Participant" isInfo={true} />
                      )}
                      {isLoading && <SkeletonLoader />}
                      {participantList?.length > 0 && !isLoading && (
                        <>
                          <div className="table-responsive">
                            <table className="table table-time-gun-create mobile-responsive mobile">
                              <thead className="head-left">
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Contact</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody className=" table-time-gun-create__old-even">
                                {participantList?.map((item, index) => (
                                  <tr key={index}>
                                    <td data-label="Name">{item?.name}</td>
                                    <td data-label="Email">
                                      <p>{item?.email}</p>
                                    </td>
                                    <td data-label="Contact">
                                      {item?.phone_number}
                                    </td>
                                    <td data-label="Action">
                                      <div className="mt-0 mt-lg-1 justify-content-lg-center d-flex align-items-center gap-2 gap-lg-2">
                                        <Link
                                          className="or-col"
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModalParticipant"
                                          onClick={() =>
                                            setSelectedParticipant(item)
                                          }
                                        >
                                          <img
                                            src="../assets/icon/edit.svg"
                                            alt="edit"
                                            style={{
                                              width: '23px',
                                            }}
                                          />
                                        </Link>
                                        <Link
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteModal"
                                          className="dan-col"
                                          onClick={() =>
                                            setSelectedData({
                                              id: item.id,
                                              type: 'participant_list',
                                            })
                                          }
                                        >
                                          <img
                                            className="ddd-card"
                                            src="../assets/icon/delete.svg"
                                            alt="delete"
                                            style={{
                                              width: '25px',
                                            }}
                                          />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <Pagination
                            setItemsPerPage={setItemsPerPage}
                            itemsPerPage={itemsPerPage}
                            totalItems={totalItems}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ParticipantForm
          selectedParticipant={selectedParticipant}
          UpdateList={fetchParticipantList}
        />
        <DeleteModal
          selectedData={selectedData}
          handleUpdateList={fetchParticipantList}
        />
      </div>
    </>
  );
};

export default Participants;
