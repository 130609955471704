import ConfirmPassword from '../Pages/ConfirmPassword';
import ResetPassword from '../Pages/ResetPassword';
import SignIn from '../Pages/SignIn';

// Public Routes
export const publicRoutes = [
  { path: '/', element: <SignIn />, redirectTo: '/dashboard' },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    redirectTo: '/dashboard',
  },
  {
    path: '/confirm-password',
    element: <ConfirmPassword />,
    redirectTo: '/dashboard',
  },
];
