/* eslint-disable no-useless-escape */
export const phoneRegExp =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export const emailRegExp =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

/* eslint-enable no-useless-escape */

// Function to format date to YYYY-MM-DD
export const formatDate = (date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const convertTimeToUnix = (timeString) => {
  if (!timeString.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)) {
    throw new Error(
      'Invalid datetime string format. Expected "YYYY-MM-DDTHH:mm".'
    );
  }
  const dateTimeParts = timeString.split('T');
  const dateParts = dateTimeParts[0].split('-');
  const timeParts = dateTimeParts[1].split(':');
  const date = new Date(
    Date.UTC(
      parseInt(dateParts[0], 10), // year
      parseInt(dateParts[1], 10) - 1, // month (0-based)
      parseInt(dateParts[2], 10), // day
      parseInt(timeParts[0], 10), // hour
      parseInt(timeParts[1], 10) // minute
    )
  );
  return Math.floor(date.getTime() / 1000);
};

export function truncateString(str, length) {
  return str?.length > length ? str.substring(0, length) + '...' : str;
}
