import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
  itemsPerPage,
  totalItems,
  currentPage,
  onPageChange,
  setItemsPerPage,
}) => {
  const [pages, setPages] = useState([]);
  const [activePage, setActivePage] = useState(currentPage);

  useEffect(() => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    setPages(pageNumbers);
  }, [totalItems, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    onPageChange(pageNumber);
  };

  const handlePreviousPage = () => {
    if (activePage > 1) {
      handlePageChange(activePage - 1);
    }
  };

  const handleNextPage = () => {
    if (activePage < pages.length) {
      handlePageChange(activePage + 1);
    }
  };

  const renderPageNumbers = () => {
    if (pages.length <= 3) {
      return pages.map((pageNumber) => (
        <li
          key={pageNumber}
          className={`page-item ${activePage === pageNumber ? 'active' : ''}`}
        >
          <a className="page-link" onClick={() => handlePageChange(pageNumber)}>
            {pageNumber}
          </a>
        </li>
      ));
    }

    const pagesToShow = [];

    if (activePage > 2) {
      pagesToShow.push(
        <li key={1} className="page-item">
          <a className="page-link" onClick={() => handlePageChange(1)}>
            1
          </a>
        </li>
      );
    }

    if (activePage > 3) {
      pagesToShow.push(
        <li key="start-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    const startPage = Math.max(1, activePage - 1);
    const endPage = Math.min(pages.length, activePage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pagesToShow.push(
        <li key={i} className={`page-item ${activePage === i ? 'active' : ''}`}>
          <a className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    if (activePage < pages.length - 2) {
      pagesToShow.push(
        <li key="end-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    if (activePage < pages.length - 1) {
      pagesToShow.push(
        <li key={pages.length} className="page-item">
          <a
            className="page-link"
            onClick={() => handlePageChange(pages.length)}
          >
            {pages.length}
          </a>
        </li>
      );
    }

    return pagesToShow;
  };

  return (
    <nav aria-label="Page navigation example">
      <div className="pagination-nav">
        <p>
          Showing {itemsPerPage * (activePage - 1) + 1} to{' '}
          {Math.min(itemsPerPage * activePage, totalItems)} of {totalItems}{' '}
          entries
        </p>
        <div className="pages-pagination">
          <p>Pages</p>
          <div className="select-input tickets">
            <select
              className="form-select mb-0"
              onChange={(e) => setItemsPerPage(e.target.value)}
              value={itemsPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <ul className="pagination">
          <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" onClick={handlePreviousPage}>
              Previous
            </a>
          </li>
          {renderPageNumbers()}
          <li
            className={`page-item ${
              activePage === pages.length ? 'disabled' : ''
            }`}
          >
            <a className="page-link" onClick={handleNextPage}>
              Next
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  currentPage: PropTypes.any,
  onPageChange: PropTypes.func,
  setItemsPerPage: PropTypes.func,
};

export default Pagination;
