import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import ButtonLoader from './ButtonLoader';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';
import axiosInstance from '../Utils/axiosInstance';
import { PRODUCT_LIST_ENDPOINT } from '../Utils/Endpoints';
import { getItem } from '../Utils/LocalStorage';
import Logger from '../Utils/Logger';

const UpdateProductsModal = () => {
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedData, setSelectedData] = useState([]);

  const rangeId = getItem('rangeId');

  useEffect(() => {
    fetchProductList();
  }, [searchText]);

  const fetchProductList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        PRODUCT_LIST_ENDPOINT + `${rangeId}?search=${searchText}`
      );
      if (response.data.code === 200) {
        setProductList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelect = (option) => {
    setSelectedData(option);
    setSearchText('');
  };
  const onRemove = (option) => {
    setSelectedData(option);
    setSearchText('');
  };

  return (
    <>
      <div
        className="modal fade"
        id="productsUpdateModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Add Products
              </h5>
              <a
                className="close-firearm-2 close-booking-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="../assets/icon/cross-svgrepo-com.svg" alt="" />
              </a>
            </div>
            <form className="mt-3">
              <div className="modal-body  modal-height">
                <div className="row">
                  <div className="col-12 mb-4">
                    {/* <Multiselect
                      isObject={true}
                      options={productList}
                      selectedValues={selectedData}
                      showCheckbox
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                      loading={isLoading}
                      onSearch={(text) => setSearchText(text)}
                    /> */}

                    <Multiselect
                      isObject={true}
                      options={productList}
                      selectedValues={selectedData}
                      showCheckbox
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                      loading={isLoading}
                      onSearch={(text) => setSearchText(text)}
                      customCloseIcon={<span>&times;</span>} // Customize the close icon
                      optionRenderer={(option) => {
                        // Check if is_active is 0, and if so, disable the option
                        const isDisabled = option.is_active === 0;
                        return (
                          <div
                            style={{
                              pointerEvents: isDisabled ? 'none' : 'auto',
                              opacity: isDisabled ? 0.5 : 1,
                            }}
                          >
                            {option.name}
                          </div>
                        );
                      }}
                    />

                    <div className="user-count-select">
                      {selectedData?.map((item, index) => (
                        <div className="user-box " key={index}>
                          <p>{item.name}</p>
                          <p className="cr-s-i">
                            <img
                              src="../assets/icon/cross-svgrepo-com.svg"
                              alt=""
                              onClick={() => {
                                setSelectedData(
                                  selectedData.filter(
                                    (currentItem) => currentItem !== item
                                  )
                                );
                              }}
                            />
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 justify-content-center">
                <div className="button-range-create">
                  <ButtonLoader
                    Heading="SAVE"
                    active="orange-outline"
                    bgChange="bg-orange save-w"
                    type="submit"
                    // isLoading={isLoading}
                  >
                    Save
                  </ButtonLoader>
                  <Link
                    to="#"
                    className="close-solcial-link-modal close-participant-modal"
                    data-bs-dismiss="modal"
                    // onClick={handleClose}
                  >
                    <ButtonRemoveImage
                      Heading="Cancel"
                      active="gary-light-outline"
                      bgChange="gary-bg w-pd-add"
                    />
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProductsModal;
