import React from 'react';

const Attendees = () => {
  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">Event Tickets</h3>
                  <div className="all-date-selected">
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="table-responsive w-100">
                      <table className="table table-time-gun-create mobile-responsive">
                        <thead>
                          <tr>
                            <th>NAME</th>
                            <th>TICKET CODE</th>
                            <th>TICKET TYPE</th>
                            <th>EVENT</th>
                            <th>ORDER</th>
                            <th>ORDER STATUS</th>
                            <th>TICKET</th>
                            <th>ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody className="table-time-gun-create__old-even">
                          <tr>
                            <td data-label="Name">1</td>
                            <td data-label="Ticket Code">2</td>
                            <td data-label="Ticket Type">3</td>
                            <td data-label="Event">No Category Found</td>
                            <td data-label="Order">4</td>
                            <td data-label="Order Status">5</td>
                            <td data-label="Ticket">6</td>
                            <td data-label="Actions">7</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendees;
