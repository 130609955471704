import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import axiosInstance from '../Utils/axiosInstance';
import {
  ASSOCIATED_RANGE_ENDPOINT,
  RANGE_ENDPOINT,
  REMOVE_ENDPOINT,
} from '../Utils/Endpoints';
import ButtonRemoveImage from './ButtonRemoveImage';
import ButtonLoader from './ButtonLoader';
import Label from './Label';
import RichTextEditor from './RichTextEditor';
import { emailRegExp, phoneRegExp } from '../Utils/common';
import { useNavigate } from 'react-router';
import Toast from '../Utils/Toast';
import { Link } from 'react-router-dom';
import { getItem } from '../Utils/LocalStorage';
import PropTypes from 'prop-types';
import { addAddress, updateAddress, uploadFile } from '../Utils/upload';
import ImageUploadInput from './ImageUploadInput';
import AddressAutocomplete from './AddressAutocomplete';
import { RANGE_CLASS } from '../Utils/ModelClasses';
import { RANGE_LOGO_TYPE } from '../Utils/UploadType';

// Validation schema
const CreateRangeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  subHeading: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  policy: Yup.string().required('Required'),
  membershipPolicy: Yup.string().required('Required'),
  isShotproPartner: Yup.string().required('Required'),
});

const RangeForm = ({
  type,
  fetchAssociateRange,
  selectedRange,
  setSelectedRange,
  updateList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [policy, setPolicy] = useState('');
  const [membershipPolicy, setMembershipPolicy] = useState('');
  const [file, setFile] = useState(null);
  const [isShotproPartner, setIsShotproPartner] = useState(false);
  const [addressObject, setAddressObject] = useState({});
  const [address, setAddress] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [imageData, setImageData] = useState({});
  const [rangeID, setRangeID] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [addressID, setAddressID] = useState(null);
  const navigate = useNavigate();
  const rangeId = getItem('rangeId');

  const validate = () => {
    try {
      CreateRangeSchema.validateSync(
        {
          name,
          subHeading,
          description,
          email,
          phoneNumber,
          policy,
          membershipPolicy,
          isShotproPartner,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  useEffect(() => {
    if (selectedRange?.id) {
      setSubHeading(selectedRange?.sub_heading);
      setName(selectedRange?.name);
      setDescription(selectedRange?.description);
      setIsShotproPartner(
        selectedRange?.is_shotpro_partner === 1 ? true : false
      );
      setMembershipPolicy(selectedRange?.membership_policy);
      setPhoneNumber(selectedRange?.phone_number);
      setPolicy(selectedRange?.policy);
      setEmail(selectedRange?.email);
      setImageData(selectedRange?.logo);
      setAddress(selectedRange?.address);
    }
  }, [selectedRange]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      let responseRangeId;
      if (!rangeID) {
        const response = await axiosInstance.post(RANGE_ENDPOINT, {
          name: name,
          sub_heading: subHeading,
          description: description,
          email: email,
          phone_number: phoneNumber,
          policy: policy,
          membership_policy: membershipPolicy,
          is_shotpro_partner: isShotproPartner,
        });
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        responseRangeId = response.data.data.id;
        setRangeID(response.data.data.id);
      }

      if (file && !imageID) {
        try {
          const imageData = await uploadFile(
            RANGE_CLASS,
            responseRangeId ? responseRangeId : rangeID,
            RANGE_LOGO_TYPE,
            file
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }

      let requestAddressObj = { ...addressObject };
      requestAddressObj.contact_number = phoneNumber;
      requestAddressObj.contact_person_name = name;

      if (!addressID) {
        try {
          const addressData = await addAddress(
            RANGE_CLASS,
            responseRangeId ? responseRangeId : rangeID,
            requestAddressObj
          );
          setAddressID(addressData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }

      if (type === 'create_range') {
        handleReset();
        const modalClose = document.getElementsByClassName('close-modal');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        updateList();
        Toast.success('Range created.', {
          onClose: () => {
            navigate('/ranges/range-locations');
          },
          autoClose: 2000,
        });
      } else {
        await handleAssociateRange(responseRangeId ? responseRangeId : rangeID);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssociateRange = async (id) => {
    const response = await axiosInstance.post(
      ASSOCIATED_RANGE_ENDPOINT + rangeId,
      {
        range_ids: [id],
      }
    );
    if (response.data.code === 200) {
      handleReset();
      const modalClose = document.getElementsByClassName('close-modal');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
      await fetchAssociateRange();
      Toast.success(response.data.message, {
        autoClose: 2000,
      });
    }
  };

  const handleReset = () => {
    setSubHeading('');
    setName('');
    setDescription('');
    setIsShotproPartner(false);
    setMembershipPolicy('');
    setPhoneNumber('');
    setPolicy('');
    setEmail('');
    setGeneralError('');
    setAddressObject({});
    setAddress({});
    setErrors({});
    if (selectedRange?.id) {
      setSelectedRange({});
    }
  };

  const handleDeleteImage = async (id) => {
    try {
      const response = await axiosInstance.delete(REMOVE_ENDPOINT + id);

      if (response.status === 204) {
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');

      const response = await axiosInstance.patch(
        RANGE_ENDPOINT + '/' + selectedRange?.id,
        {
          name: name,
          sub_heading: subHeading,
          description: description,
          email: email,
          phone_number: phoneNumber,
          policy: policy,
          membership_policy: membershipPolicy,
          is_shotpro_partner: isShotproPartner,
        }
      );
      if (response.data.code === 200) {
        if (file) {
          await uploadFile(
            RANGE_CLASS,
            selectedRange?.id,
            RANGE_LOGO_TYPE,
            file
          );
        }
        if (Object.keys(addressObject).length > 0) {
          let requestAddressObj = { ...addressObject };
          requestAddressObj.contact_number = phoneNumber;
          requestAddressObj.contact_person_name = name;
          if (address?.id) {
            await updateAddress(address?.id, requestAddressObj);
          } else {
            await addAddress(RANGE_CLASS, selectedRange.id, requestAddressObj);
          }
        }

        if (type === 'create_range') {
          handleReset();
          const modalClose = document.getElementsByClassName('close-modal');
          for (let i = 0; i < modalClose.length; i++) {
            modalClose[i].click();
          }
          updateList();

          Toast.success('Range updated.', {
            onClose: () => {
              navigate('/ranges/range-locations');
            },
            autoClose: 2000,
          });
        }
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={selectedRange?.id ? handleUpdate : handleSubmit}>
      <div className="modal-body modal-height">
        <div className="row">
          <div className="col-lg-12">
            <Label labelHeading="Name:" />
            <div className="tickets ">
              <input
                type="text"
                className="form-control input-theme"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </div>
          </div>
          <AddressAutocomplete
            handleAddress={setAddressObject}
            addressList={address}
          />
          <div className="col-lg-6">
            <Label labelHeading="Description:" />
            <div className="multiselect-container multiSelectContainer tickets-text">
              <RichTextEditor
                value={description}
                setEditorData={setDescription}
                index="description"
              />
              {errors.description && (
                <div className="error">{errors.description}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mt-3">
            <Label labelHeading="Sub Heading:" />
            <div className="tickets">
              <input
                type="text"
                className="form-control input-theme"
                value={subHeading}
                onChange={(e) => setSubHeading(e.target.value)}
              />
              {errors.subHeading && (
                <div className="error">{errors.subHeading}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mt-0 mt-lg-3">
            <Label labelHeading="Phone:" />
            <div className="tickets">
              <input
                type="phone"
                className="form-control input-theme"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              {errors.phoneNumber && (
                <div className="error">{errors.phoneNumber}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mt-3">
            <Label labelHeading="Email:" />
            <div className="tickets">
              <input
                type="email"
                className="form-control input-theme"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
          </div>
          <div className="col-lg-6">
            <Label labelHeading="Policy:" />
            <div className="multiselect-container multiSelectContainer tickets-text">
              <RichTextEditor
                value={policy}
                setEditorData={setPolicy}
                index="policy"
              />
              {errors.policy && <div className="error">{errors.policy}</div>}
            </div>
          </div>
          <div className="col-lg-6 mt-3 mt-lg-0">
            <Label labelHeading="Membership Policy:" />
            <div className="multiselect-container multiSelectContainer tickets-text">
              <RichTextEditor
                value={membershipPolicy}
                setEditorData={setMembershipPolicy}
                index="membershipPolicy"
              />
              {errors.membershipPolicy && (
                <div className="error">{errors.membershipPolicy}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6 mt-3">
            <Label labelHeading="Shotpro Partner:" />
            <div className="form-switch text-left">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isShotproPartner}
                onChange={(e) => setIsShotproPartner(e.target.checked)}
              />
              {errors.isShotproPartner && (
                <div className="error">{errors.isShotproPartner}</div>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <Label labelHeading="Logo:" />
            <ImageUploadInput
              setFile={setFile}
              handleDeleteImage={handleDeleteImage}
              imageData={imageData}
            />
          </div>
        </div>
        {generalError && (
          <p className="error text-center mt-3">{generalError}</p>
        )}
      </div>

      <div className="modal-footer border-0 justify-content-center">
        <div className="button-range-create">
          <ButtonLoader
            type="submit"
            bgChange={`white-bg w-pd-add ${type === 'create_range' ? ' create-range-w ' : ' save-w '}`}
            active="orange-outline"
            isLoading={isLoading}
          >
            {type === 'create_range'
              ? selectedRange?.id
                ? 'Update Range'
                : 'Create Range'
              : 'Save'}
          </ButtonLoader>
          <Link
            to="#"
            className="close-modal"
            onClick={handleReset}
            data-bs-dismiss="modal"
          >
            <ButtonRemoveImage
              Heading="Cancel"
              active="gary-light-outline"
              bgChange="gary-bg w-pd-add"
            />
          </Link>
        </div>
      </div>
    </form>
  );
};

RangeForm.propTypes = {
  type: PropTypes.string,
  fetchAssociateRange: PropTypes.func,
  setSelectedRange: PropTypes.func,
  updateList: PropTypes.func,
  selectedRange: PropTypes.object,
};
export default RangeForm;
