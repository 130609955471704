import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Logger from '../../Utils/Logger';
import ButtonRemoveImage from '../ButtonRemoveImage';
import { Link } from 'react-router-dom';
import ButtonLoader from '../ButtonLoader';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = () => {
  const [errorMsg, SetErrorMsg] = useState('');
  const [isError, SetIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success] = useState(false);
  //   const [success, setSuccess] = useState(false);
  //   const [error, setError] = useState('');
  const [, setHide] = useState(false);
  //   const [hide, setHide] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  //   const [total, setTotal] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      setIsLoading(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        SetIsError(true);
        SetErrorMsg(error.message);
        setTimeout(() => {
          SetIsError(false);
        }, 4000);
        setIsLoading(false);
      }

      if (paymentMethod) {
        const formdata = new FormData();
        formdata.append('payment_method_id', paymentMethod.id);

        setIsLoading(false);
        // let access_token = Cookies.get('userToken');
        // postApi(subscribe, formdata, access_token)
        //   .then((res) => {
        //     if (res.data.success) {
        //       setIsLoading(false);
        //       setSuccess(true);
        //     } else {
        //       SetIsError(true);
        //       SetErrorMsg('Something went wrong. Please try again!');

        //       setTimeout(() => {
        //         SetIsError(false);
        //       }, 4000);
        //       setIsLoading(false);
        //     }
        //   })
        //   .catch((e) => {
        //     SetIsError(true);
        //     SetErrorMsg(e.response?.data?.message);

        //     setTimeout(() => {
        //       SetIsError(false);
        //     }, 4000);
        //     setIsLoading(false);
        //   });
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
        setHide(false);
      } else {
        setHide(false);
        Logger.log(isMobile);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    // <form onSubmit={handleSubmit}>
    <div className="row">
      {success === false && (
        <>
          <div className="payment-card-tm">
            <div className="card-payment-ab">
              <div className="row">
                <div className="col-12">
                  <div className="tickets">
                    <CardNumberElement className="form-control input-theme" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tickets">
                    <CardExpiryElement className="form-control input-theme" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tickets">
                    <CardCvcElement className="form-control input-theme" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pay-pay">
              <div className="button-range-create mt-5">
                <ButtonLoader
                  Heading="Book"
                  active="orange-outline"
                  bgChange="white-bg w-pd-add save-w"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!stripe || !elements ? true : false}
                  isLoading={isLoading}
                >
                  Book
                </ButtonLoader>
                <Link
                  to="/ranges/lane-check-list"
                  className="close-solcial-link-modal"
                  data-bs-dismiss="modal"
                  // onClick={handleClose}
                >
                  <ButtonRemoveImage
                    Heading="Cancel"
                    active="gary-light-outline"
                    bgChange="gary-bg w-pd-add"
                  />
                </Link>
              </div>
            </div>
            {isError && (
              <h6 className="text-center text-danger d-md-block error-mag">
                {errorMsg}
              </h6>
            )}
          </div>
        </>
      )}
      {success && (
        <>
          <div className="thank-you d-none">
            <h3 className="text-success text-align-list">Thank you !</h3>
          </div>
        </>
      )}
    </div>
    // </form>
  );
};

const StripePayment = ({ selectedBay, handleStripeSubmit }) => {
  return (
    <div className="row">
      <h4 className="cash-price">
        ${selectedBay?.base_reservation_cost?.toFixed(2)}
      </h4>
      <Elements stripe={stripePromise}>
        <CheckoutForm handleStripeSubmit={handleStripeSubmit} />
      </Elements>
    </div>
  );
};

StripePayment.propTypes = {
  selectedBay: PropTypes.object,
  handleStripeSubmit: PropTypes.func,
};

export default StripePayment;
