import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ButtonAdd from '../Components/ButtonAdd';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import HexagonSize from '../Components/HexagonSize';
import SelectRentalFirearm from '../Components/SelectRentalFirearm';
import AddAdditionalParticipant from '../Components/AddAdditionalParticipant';

const MembershipTwo = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,

    // autoplay: true,
    // navText: ["<", ">"],
    // smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className="outer-border outer-overflow-show">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card user-id-card">
                <div className="card-body card-id">
                  <h4 className="sub-heading">BILLY SQUIRE</h4>
                  <div className="check-right">
                    <HexagonSize
                      imageUser="../assets/images/user-1.jpeg"
                      hexBg="bg-black bg-success "
                    />
                  </div>
                  <div className="body-id-user">
                    <h4>ID STATUS</h4>
                    <div className="phat">
                      <div className="first-phat">
                        <p>PROVE ID</p>
                        <button className="verified">VERIFIED</button>
                      </div>
                      <div className="seconde-phat">
                        <p>GOVT</p>
                        <button className="expired">EXPIRED</button>
                      </div>
                    </div>
                  </div>
                  <div className="save-data ps-md-5 ps-0 mt-3 mt-md-0 mob-tab-view-2">
                    <ButtonRemoveImage
                      Heading="ID CHALLENGE"
                      active="orange-outline"
                      bgChange="white-bg "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card-body body-triangle-3">
                <div className="employees employ-set mem-tow">
                  <div className="back-listing-tab">
                    <Link
                      to="/accounts/account"
                      className="button-width mob-tab-view-2"
                    >
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <div className="menu-button-listing mob-tab-view">
                      <div>
                        <ButtonRemoveImage
                          Heading="ID CHALLENGE"
                          active="orange-outline"
                          bgChange="white-bg"
                        />
                      </div>
                      <ButtonAdd
                        Heading="EDIT PROFILE"
                        images="../assets/icon/edit.svg"
                        active="orange-outline"
                        bgChange="white-bg"
                      />
                      <ButtonAdd
                        Heading="ADD A TAG"
                        images="../assets/icon/tag.svg"
                        active="orange-outline"
                        bgChange="white-bg"
                      />
                      <ButtonAdd
                        Heading="ADD NOTE"
                        images="../assets/icon/notes.svg"
                        active="orange-outline"
                        bgChange="white-bg"
                      />
                      <ButtonAdd
                        Heading="MEMBERSHIP"
                        images="../assets/icon/id_verification.svg"
                        active="orange-outline"
                        bgChange="white-bg"
                      />
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#SelectRentalFirearm"
                      >
                        <ButtonAdd
                          Heading="History"
                          images="../assets/icon/cart_add.svg"
                          active="orange-outline"
                          bgChange="white-bg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="all-date-selected mt-3  new-back-btn-add">
                    <Link
                      to="/accounts/account"
                      className="button-width new-cum b-p"
                    >
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <div className="as">
                      <ButtonAdd
                        Heading="EDIT PROFILE"
                        images="../assets/icon/edit.svg"
                        active="orange-outline mob-tab-view-2"
                        bgChange="white-bg"
                      />
                      <ButtonAdd
                        Heading="ADD A TAG"
                        images="../assets/icon/tag.svg"
                        active="orange-outline mob-tab-view-2"
                        bgChange="white-bg"
                      />
                      <ButtonAdd
                        Heading="ADD NOTE"
                        images="../assets/icon/notes.svg"
                        active="orange-outline mob-tab-view-2"
                        bgChange="white-bg"
                      />
                      <ButtonAdd
                        Heading="MEMBERSHIP"
                        images="../assets/icon/id_verification.svg"
                        active="orange-outline mob-tab-view-2"
                        bgChange="white-bg"
                      />
                      <Link
                        to=""
                        data-bs-toggle="modal"
                        data-bs-target="#SelectRentalFirearm"
                        className="mob-tab-view-2"
                      >
                        <ButtonAdd
                          Heading="History"
                          images="../assets/icon/cart_add.svg"
                          active="orange-outline"
                          bgChange="white-bg"
                        />
                      </Link>

                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#addAdditional"
                        className="mob-tab-view"
                      >
                        <ButtonRemoveImage
                          Heading="Start a Lane a booking"
                          active="orange-outline"
                          bgChange="white-bg"
                        />
                      </Link>
                      <ButtonRemoveImage
                        Heading="Start a Purchase"
                        active="orange-outline mob-tab-view"
                        bgChange="white-bg"
                      />
                    </div>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text mt-3 ac-mem">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="classes-shot">
                              <div>
                                <h5>Membership Type</h5>
                                <div className="mm-card">
                                  <img src="../assets/icon/Icon-M.svg" alt="" />
                                </div>
                              </div>
                              <div>
                                <h5>Skill Level</h5>
                                <h4 className="beginner">Beginner</h4>
                              </div>
                              <div>
                                <h5>Account Since</h5>
                                <h4 className="beginner">Feb 2021</h4>
                              </div>
                              <div>
                                <h5>Passport Progress</h5>

                                <h4>5</h4>
                              </div>
                              <div>
                                <h5>Shot Points</h5>
                                <h4>1.2K</h4>
                              </div>
                              <div>
                                <h5>Reward LV</h5>
                                <h4>N/A</h4>
                              </div>
                              <div>
                                <h5>Classes</h5>
                                <h4>2</h4>
                              </div>
                              <div>
                                <h5>Privates</h5>
                                <h4>3</h4>
                              </div>
                              <div>
                                <h5>Age</h5>
                                <h4>25</h4>
                              </div>
                              <div>
                                <h5>Gender</h5>
                                <h4>M</h4>
                              </div>
                              <div>
                                <h5>On-Range</h5>
                                <h4>5</h4>
                              </div>
                              <div>
                                <h5>Last Trained</h5>
                                <h4>2.1.23</h4>
                              </div>
                              <div>
                                <h5>Visits</h5>
                                <h4>10</h4>
                              </div>
                              <div>
                                <h5>Last Visit</h5>
                                <h4>2.1.23</h4>
                              </div>
                              <div>
                                <h5>Purchases</h5>
                                <h4>11</h4>
                              </div>
                              <div>
                                <h5>Profile Tags</h5>
                                <h4>0</h4>
                              </div>
                            </div>
                            <div className="sel-lane mob-tab-view-2 ">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#addAdditional"
                              >
                                <ButtonRemoveImage
                                  Heading="Start a Lane a booking"
                                  active="orange-outline"
                                  bgChange="white-bg"
                                />
                              </Link>
                              <ButtonRemoveImage
                                Heading="Start a Purchase"
                                active="orange-outline"
                                bgChange="white-bg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="account-range">
                                <div>
                                  <h5>Account Details</h5>
                                  <h4 className="number-round">(2)</h4>
                                </div>
                                <div>
                                  <h5>FPE Validation</h5>
                                  <h4 className="number-round">1/4</h4>
                                </div>
                                <div>
                                  <h5>Range Notes</h5>
                                  <h4>Feb 2021</h4>
                                </div>
                                <div>
                                  <h5>Ranges Offers</h5>

                                  <h4>5</h4>
                                </div>
                              </div>
                            </div>
                            <OwlCarousel
                              className="slider-items owl-carousel carouser-items-list mt-3 "
                              {...options}
                            >
                              <div className="upcoming triangle-card triangle-m-t">
                                <div className="upcoming-title">
                                  <div className="upcoming-heading">
                                    <h4>
                                      UPCOMING <span>3</span>
                                    </h4>
                                  </div>
                                  <div className="upcoming-button">
                                    <ButtonRemoveImage
                                      Heading="VIEW ALL"
                                      active="white-outline"
                                      bgChange="bg-orange"
                                    />
                                  </div>
                                </div>
                                <div className="byo-data">
                                  <div className="reservation">
                                    <p>0235814582</p>
                                    <h4>On-Range Reservation</h4>
                                    <h6>Stoddard’s Midtown - Atlanta GA</h6>
                                    <ul>
                                      <li>
                                        <i
                                          className="fa fa-calendar"
                                          aria-hidden="true"
                                        ></i>
                                        4.12.2022
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-clock-o"
                                          aria-hidden="true"
                                        ></i>
                                        4 PM CST
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>
                                        Jeff James +2
                                      </li>
                                    </ul>
                                    <div className="right-icon-angel right">
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                  <div className="reservation remove-border-right">
                                    <p>0235814582</p>
                                    <h4>On-Range Reservation</h4>
                                    <h6>Stoddard’s Midtown - Atlanta GA</h6>
                                    <ul>
                                      <li>
                                        <i
                                          className="fa fa-calendar"
                                          aria-hidden="true"
                                        ></i>
                                        4.12.2022
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-clock-o"
                                          aria-hidden="true"
                                        ></i>
                                        4 PM CST
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>
                                        Jeff James +2
                                      </li>
                                    </ul>
                                    <div className="right-icon-angel right">
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="upcoming triangle-card">
                                <div className="upcoming-title">
                                  <div className="upcoming-heading">
                                    <h4>
                                      UPCOMING <span>3</span>
                                    </h4>
                                  </div>
                                  <div className="upcoming-button">
                                    <ButtonRemoveImage
                                      Heading="VIEW ALL"
                                      active="white-outline"
                                      bgChange="bg-orange"
                                    />
                                  </div>
                                </div>
                                <div className="byo-data">
                                  <div className="reservation">
                                    <p>0235814582</p>
                                    <h4>On-Range Reservation</h4>
                                    <h6>Stoddard’s Midtown - Atlanta GA</h6>
                                    <ul>
                                      <li>
                                        <i
                                          className="fa fa-calendar"
                                          aria-hidden="true"
                                        ></i>
                                        4.12.2022
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-clock-o"
                                          aria-hidden="true"
                                        ></i>
                                        4 PM CST
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>
                                        Jeff James +2
                                      </li>
                                    </ul>
                                    <div className="right-icon-angel right">
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                  <div className="reservation remove-border-right">
                                    <p>0235814582</p>
                                    <h4>On-Range Reservation</h4>
                                    <h6>Stoddard’s Midtown - Atlanta GA</h6>
                                    <ul>
                                      <li>
                                        <i
                                          className="fa fa-calendar"
                                          aria-hidden="true"
                                        ></i>
                                        4.12.2022
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-clock-o"
                                          aria-hidden="true"
                                        ></i>
                                        4 PM CST
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>
                                        Jeff James +2
                                      </li>
                                    </ul>
                                    <div className="right-icon-angel right">
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </OwlCarousel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SelectRentalFirearm />
      <AddAdditionalParticipant />
    </>
  );
};

export default MembershipTwo;
