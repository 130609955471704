import React from 'react';
import PropTypes from 'prop-types';

const RangeNotFound = ({ title, isInfo }) => {
  return (
    <>
      <div className="page-not-found">
        <img src="../assets/icon/cross.svg" alt="" />
        <h4 className="">No {title} found</h4>
        {!isInfo && <p>Create a new {title} to get Started</p>}
      </div>
    </>
  );
};
RangeNotFound.propTypes = {
  title: PropTypes.string,
  isInfo: PropTypes.bool,
};
export default RangeNotFound;
