import React from 'react';
import ButtonLoader from './ButtonLoader';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';
const CreateSlots = () => {
  return (
    <>
      {/* <button
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
         data-bs-target="#bookSlot"
         data-bs-target="#CreateSlots"
      >
        Launch demo modalss
      </button> */}

      <div
        className="modal fade"
        id="CreateSlots"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lane-name" id="exampleModalLabel">
                Create Slots
              </h5>
              <a
                className="close-firearm-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                href="/ranges/manage"
              >
                <img src="../assets/icon/cross-svgrepo-com.svg" alt="" />
              </a>
            </div>
            <div className="modal-body modal-height">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <ul className="month-select">
                    <li>
                      <span>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                      </span>
                      JANUARY 2023
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-7">
                  <div className="calender-list mt-0">
                    <table className="calendar">
                      <thead>
                        <tr>
                          <th className="ps-1">Sun</th>
                          <th>Mon</th>
                          <th>Tue</th>
                          <th>Wed</th>
                          <th>Thu</th>
                          <th>Fri</th>
                          <th>Sat</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="prev-month">30</td>
                          <td>1</td>
                          <td>2</td>
                          <td>3</td>
                          <td>4</td>
                          <td>5</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>8</td>
                          <td>9</td>
                          <td>10</td>
                          <td>11</td>
                          <td>12</td>
                          <td>13</td>
                        </tr>
                        <tr>
                          <td>14</td>
                          <td>15</td>
                          <td>16</td>
                          <td className="active">17</td>
                          <td>18</td>
                          <td>19</td>
                          <td>20</td>
                        </tr>
                        <tr>
                          <td>21</td>
                          <td>22</td>
                          <td>23</td>
                          <td>24</td>
                          <td>25</td>
                          <td>26</td>
                          <td>27</td>
                        </tr>
                        <tr>
                          <td className="border-remove">28</td>
                          <td className="border-remove">29</td>
                          <td className="border-remove">30</td>
                          <td className="border-remove">31</td>
                          <td className="prev-month border-remove">1</td>
                          <td className="prev-month border-remove">2</td>
                          <td className="prev-month border-remove">3</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="create-slots">
                <div className="tickets">
                  <input
                    type="time"
                    className="form-control input-theme"
                    placeholder="Start Hours"
                    step="1"
                  />
                </div>
                <div className="tickets">
                  <input
                    type="time"
                    className="form-control input-theme"
                    placeholder="End Hours"
                    step="1"
                  />
                </div>
                <div className="tickets">
                  <input
                    type="name"
                    className="form-control input-theme"
                    placeholder="$Price"
                  />
                </div>
                <div className="delete-slots">
                  <img src="../assets/icon/delete.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <div className="button-range-create mt-5">
                <ButtonLoader
                  Heading="SAVE"
                  active="orange-outline"
                  bgChange="white-bg w-pd-add save-w"
                  type="submit"
                >
                  save
                </ButtonLoader>

                <Link
                  to="#"
                  className="close-solcial-link-modal"
                  data-bs-dismiss="modal"
                >
                  <ButtonRemoveImage
                    Heading="Cancel"
                    active="gary-light-outline"
                    bgChange="gary-bg w-pd-add"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSlots;
