// src/components/SkeletonLoader.js

import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import PropTypes from 'prop-types';

const SkeletonLoader = ({ type, count = 1, rows = 1, columns = 1 }) => {
  if (type === 'table') {
    return (
      <>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <td key={colIndex}>
                <Skeleton />
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  }

  return <Skeleton count={count} />;
};

SkeletonLoader.propTypes = {
  type: PropTypes.string,
  count: PropTypes.number,
  rows: PropTypes.number,
  columns: PropTypes.number,
};
export default SkeletonLoader;
