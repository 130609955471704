import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../Utils/axiosInstance';
import { RANGE_ENDPOINT } from '../../../Utils/Endpoints';
import Logger from '../../../Utils/Logger';
import { clearItem, getItem, saveItem } from '../../../Utils/LocalStorage';

const initialState = {
  rangeList: [],
  createRange: {},
  createAddressRange: {},
  bannerRange: {},
  associatedRange: [],
  trainerRange: [],
};

const rangeSlice = createSlice({
  name: 'ranges',
  initialState,
  reducers: {
    setData: (state, action) => {
      return {
        ...state,
        rangeList: action.payload,
      };
    },
    setCreateRangeData: (state, action) => {
      return {
        ...state,
        createRange: action.payload,
      };
    },
    setCreateAddressRangeData: (state, action) => {
      return {
        ...state,
        createAddressRange: action.payload,
      };
    },
    setBannerRangeData: (state, action) => {
      return {
        ...state,
        bannerRange: action.payload,
      };
    },
    setAssociatedRangeData: (state, action) => {
      return {
        ...state,
        associatedRange: action.payload,
      };
    },
    setTrainerRangeData: (state, action) => {
      return {
        ...state,
        trainerRange: action.payload,
      };
    },
    clearRangeData: (state) => {
      return {
        ...state,
        createAddressRange: {},
        createRange: {},
        bannerRange: {},
        associatedRange: [],
        trainerRange: [],
      };
    },
  },
});

export const {
  setData,
  setCreateRangeData,
  setCreateAddressRangeData,
  setBannerRangeData,
  clearRangeData,
  setAssociatedRangeData,
  setTrainerRangeData,
} = rangeSlice.actions;

export const fetchRange = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(RANGE_ENDPOINT);
    if (response.data.code === 200) {
      dispatch(setData(response.data.data.data));
      const idRange = getItem('rangeId');
      if (response.data.data?.data?.length > 0) {
        const responseIds = response.data.data?.data?.map((item) => item.id);
        if (idRange && responseIds.includes(idRange)) {
          saveItem('rangeId', idRange);
        } else {
          saveItem('rangeId', response.data.data.data[0].id);
        }
      } else {
        clearItem('rangeId');
      }
    }
  } catch (error) {
    Logger.error(error);
  }
};

export default rangeSlice.reducer;
